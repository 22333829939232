.owl-carousel {
  display: flex !important;
  overflow: scroll !important;
  scrollbar-width: none;
}
.owl-carousel .owl-item img {
  height: auto;
}
.owl-loaded .owl-stage::after {
  content: "";
}
.owl-carousel:not(.owl-loaded) {
  flex-wrap: nowrap;
  overflow: hidden;
}
.owl-carousel:not(.owl-loaded)[class*="cols-"]:not(.gutter-no) {
  margin-left: -10px;
  margin-right: -10px;
  width: auto;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 1.5rem;
}
.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
  margin: 0;
  transform: translateY(-50%);
  font-size: 20px;
  font-weight: 400;
  color: #00a567;
}
.owl-theme .owl-nav .owl-prev i::before,
.owl-theme .owl-nav .owl-next i::before {
  margin: 0;
}
.owl-theme .owl-nav .owl-prev:not(.disabled):hover,
.owl-theme
  .owl-nav
  .owl-prev:not(.disabled):active
  .owl-theme
  .owl-nav
  .owl-prev:not(.disabled):focus,
.owl-theme .owl-nav .owl-next:not(.disabled):hover,
.owl-theme
  .owl-nav
  .owl-next:not(.disabled):active
  .owl-theme
  .owl-nav
  .owl-prev:not(.disabled):focus,
.owl-theme
  .owl-nav
  .owl-prev:not(.disabled):active
  .owl-theme
  .owl-nav
  .owl-next:not(.disabled):focus,
.owl-theme
  .owl-nav
  .owl-next:not(.disabled):active
  .owl-theme
  .owl-nav
  .owl-next:not(.disabled):focus {
  background-color: #00a567;
  border-color: #00a567;
  color: #fff;
}
.owl-theme .owl-nav .owl-prev.disabled,
.owl-theme .owl-nav .owl-next.disabled {
  color: #ccc;
}
.owl-theme .owl-nav .owl-prev {
  left: -40px;
}
.owl-theme .owl-nav .owl-prev i {
  margin-right: 2px;
}
.owl-theme .owl-nav .owl-next {
  right: -40px;
}
.owl-theme .owl-nav .owl-next i {
  margin-left: 2px;
}
.owl-theme .owl-dots .owl-dot.active span {
  background-color: #00a567;
  border-color: #00a567;
}
.owl-theme .owl-dots .owl-dot span {
  margin: 5px;
}
@media (max-width: 1300px) {
  .owl-theme .owl-nav .owl-prev {
    left: 20px;
  }
  .owl-theme .owl-nav .owl-next {
    right: 20px;
  }
}

@media (min-width: 350px) {
  .slider-img-case {
    width: 330px;
    border-radius: 5px;
  }
}
@media (min-width: 740px) {
  .slider-img-case {
    width: 330px;
    border-radius: 5px;
  }
}
@media (min-width: 1024px) {
  .slider-img-case {
    width: 580px;
    border-radius: 5px;
  }
}
@media (min-width: 1024px) {
  .slider-img-case {
    width: 580px;
    border-radius: 5px;
  }
}
.list-img-container:hover {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.18);
  width: calc(25% - 8px);
  transform: scale(1.01);
  border-radius: 4px;
}
.list-img-container:focus {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
  width: calc(25% - 8px);
  transform: scale(1.01);
  border-radius: 4px;
}
.about-bg-img {
  border-radius: 500px;
}

.about-titile {
  overflow: hidden;
}
.home-layout {
  /* background-image: url("https://images.unsplash.com/photo-1530738472658-404f693bd4b2?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&dl=john-cameron-i8u5gz-ZeIc-unsplash.jpg"); */
  background-image: url("https://akveo.github.io/react-native-ui-kitten/images/pattern.svg"),
    radial-gradient(circle farthest-corner at 100% 50%, #038a57, #038a57);
}
.owl-nav-full .owl-nav {
  opacity: 1;
  transition: opacity 0.4s;
}
.owl-nav-full .owl-nav .owl-prev,
.owl-nav-full .owl-nav .owl-next {
  margin: 0;
  top: 0;
  height: 100%;
  transform: none;
  border: 0;
  font-size: 3rem;
  font-weight: 400;
  color: #333;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0.8);
  transition: 0.4s;
}
.owl-nav-full .owl-nav .owl-prev:not(.disabled):hover,
.owl-nav-full .owl-nav .owl-next:not(.disabled):hover {
  color: #00a567;
  background-color: rgba(255, 255, 255, 0.8);
}
.owl-nav-full .owl-nav .owl-prev.disabled,
.owl-nav-full .owl-nav .owl-next.disabled {
  visibility: hidden;
  opacity: 1;
}
.owl-nav-full .owl-nav .owl-prev {
  left: 0;
  box-shadow: 4px 0px 7px -5px rgba(0, 0, 0, 0.2);
}
.owl-nav-full .owl-nav .owl-next {
  right: 0;
  box-shadow: -4px 0px 7px -5px rgba(0, 0, 0, 0.2);
}
.owl-nav-full:hover .owl-nav {
  opacity: 1;
}
.owl-nav-full:hover .owl-prev {
  left: 0;
}
.owl-nav-full:hover .owl-next {
  right: 0;
}
.owl-nav-inner .owl-nav .owl-prev,
.owl-nav-inner .owl-nav .owl-next {
  opacity: 1;
  visibility: hidden;
}
.owl-nav-inner .owl-nav i {
  margin-bottom: 2px;
}
.owl-nav-inner .owl-nav .owl-prev {
  left: 0;
}
.owl-nav-inner .owl-nav .owl-next {
  right: 0;
}
.owl-nav-inner:hover .owl-prev,
.owl-nav-inner:hover .owl-next {
  opacity: 1;
  visibility: visible;
}
.owl-nav-inner:hover .owl-prev {
  left: 2rem;
}
.owl-nav-inner:hover .owl-next {
  right: 2rem;
}
.owl-nav-top .owl-nav {
  position: absolute;
  top: -5.3rem;
  right: -0.4rem;
  color: #777;
}
.owl-nav-top .owl-nav i {
  padding: 0.1rem 0.5rem;
  font-size: 1.8rem;
  font-weight: 700;
}
.owl-nav-top .owl-nav i::before {
  margin: 0;
}
.owl-nav-bottom .owl-nav {
  position: absolute;
  bottom: 1.7rem;
  right: -0.4rem;
  color: #999;
}
.owl-nav-bottom .owl-nav i {
  font-size: 2rem;
  font-weight: 700;
}
.owl-nav-bottom .owl-nav i::before {
  margin: 0;
}
.owl-nav-bottom .owl-prev {
  margin-right: 0.8rem;
}
.owl-dot-white .owl-dots .owl-dot span {
  background-color: #fff;
  border-color: #fff;
  opacity: 1.8;
}
.owl-dot-white .owl-dots .owl-dot.active span {
  background-color: #fff;
  border-color: #fff;
  opacity: 1;
}
.owl-dot-grey .owl-dots .owl-dot span {
  background-color: #efefef;
  border-color: #efefef;
}
.owl-dot-grey .owl-dots .owl-dot.active span {
  background-color: #999;
  border-color: #999;
}
.owl-dot-dark .owl-dots .owl-dot span {
  background-color: #a1a1a1;
  border-color: #a1a1a1;
}
.owl-dot-dark .owl-dots .owl-dot.active span {
  background-color: #333;
  border-color: #333;
}
.owl-dot-inner .owl-dots {
  position: absolute;
  bottom: 4.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.brand-carousel .owl-stage {
  display: flex;
  align-items: center;
}
.brand-carousel .owl-item img {
  width: auto;
  margin: auto;
}
.brand-carousel:not(.loaded) {
  text-align: center;
  align-items: center;
}
.owl-carousel .slide-animate {
  transform: translate3d(0, 0, 0) scale(1);
  will-change: filter, transform, opacity;
  visibility: hidden;
  opacity: 1;
}
.owl-carousel .slide-animate.show-content {
  visibility: visible;
  opacity: 1;
}
.owl-full-height,
.owl-full-height .owl-stage-outer,
.owl-full-height .owl-stage,
.owl-full-height .owl-item,
.owl-same-height .owl-item > * {
  height: 100%;
}
.owl-same-height .owl-stage {
  display: flex;
}
.owl-full-height .owl-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.owl-shadow-carousel .owl-stage-outer {
  margin: -20px;
  padding: 20px;
}
@media (max-width: 575px) {
  .owl-shadow-carousel .owl-stage-outer {
    margin: -15px;
    padding: 15px;
  }
}
.owl-nav-bg .owl-nav .owl-prev {
  left: 15.4%;
}
.owl-nav-bg .owl-nav .owl-next {
  right: 15.4%;
}
.owl-nav-bg .owl-nav [class*="owl-"] {
  width: 4.8rem;
  height: 4.8rem;
  border-width: 2px;
  font-size: 3.2rem;
  color: #666666;
  border-color: #666666;
}
.owl-nav-bg .owl-nav [class*="owl-"].disabled {
  border-color: #acabab;
}
.owl-nav-bg .owl-nav i {
  line-height: 1;
  margin-bottom: 2px;
}
.owl-nav-fade .owl-nav .owl-prev {
  opacity: 1;
  margin-left: 4rem;
}
.owl-nav-fade .owl-nav .owl-next {
  opacity: 1;
  margin-right: 4rem;
}
.owl-nav-fade:hover .owl-nav .owl-prev {
  opacity: 1;
  margin-left: 0;
}
.owl-nav-fade:hover .owl-nav .owl-next {
  opacity: 1;
  margin-right: 0;
}
.owl-split .owl-item:not(:last-child) {
  position: relative;
}
.owl-split .owl-item:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ebebeb;
}
.owl-middle .owl-stage {
  display: flex;
  align-items: center;
}
.noUi-target {
  background: #eee;
  margin: 4px 7px 2.7rem 9px;
}
.noUi-connects {
  padding: 11.5px 0;
  top: -10px;
}
.noUi-connect {
  background: #999;
  margin-top: 10px;
  height: 3px;
}
.noUi-horizontal .noUi-handle {
  width: 1.2rem;
  height: 1.2rem;
  background: #00a567;
}

.loginOpts {
  font-size: 120%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.loginIcon {
  margin-right: 20%;
}

*,
::after,
::before {
  box-sizing: inherit;
}
html {
  font-size: 62.5%;
  font-size-adjust: 100%;
  font-weight: 400;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  overflow-x: hidden;
  font-family: "proximaS";
  font-size: 1.4rem;
  line-height: 1.2;
  color: #666;
  /* background-color: #f6f7f9; */
}
main {
  display: block;
  position: relative;
}
body:not(.loaded) * {
  transition: all 0s;
}
.page-wrapper {
  position: relative;
  transition: margin 0.4s, opacity 0.5s;
  opacity: 1;
}
.loaded > .page-wrapper {
  opacity: 1;
}
table {
  width: 100%;
  border-collapse: collapse;
}
table td,
table th {
  padding: 0;
}
.section {
  padding: 7rem 0;
}
.grey-section {
  background: #f6f7f9;
}
.background-section {
  background-repeat: no-repeat;
}
.parallax {
  background-color: #3c3f41;
}
ul {
  padding-left: 1.5em;
}
.menu,
.menu ul,
.mobile-menu,
.mobile-menu ul,
.nav,
.nav ul,
.widget-body,
.widget-body ul,
.list,
.breadcrumb,
.filter-items,
.select-menu > ul,
.dropdown-box,
.pagination,
.nav-filters,
.category ul,
.comments ul,
.product-nav,
.product-tabs > div ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none !important;
  color: inherit !important;
  transition: color 0.3s;
}
a:hover {
  color: #00a567;
}
:focus {
  outline: 0;
}
figure {
  margin: 0;
}
img {
  max-width: 100%;
  height: auto;
}
hr {
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid #eee;
}
input {
  -webkit-appearance: none;
  border: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}
input:focus {
  outline: 0;
}
i {
  font-style: normal;
}
button:focus {
  outline: none;
}
.scrollable,
.sidebar-content {
  -webkit-overflow-scrolling: touch;
}
.scrollable::-webkit-scrollbar,
.sidebar-content::-webkit-scrollbar {
  height: 7px;
  width: 4px;
}
.scrollable::-webkit-scrollbar-thumb,
.sidebar-content::-webkit-scrollbar-thumb {
  margin-right: 2px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
}
.scrollable-light::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.2);
  }
}
@keyframes rotatedelay {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  background: #fff;
  opacity: 1;
  visibility: visible;
  z-index: 999999;
}
.loaded > .loading-overlay {
  opacity: 1;
  visibility: hidden;
}
@keyframes reveal-1 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(55%, 55%, 0);
    border-radius: 0;
  }
  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(55%, 55%, 0);
    border-radius: 0;
  }
  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}
@keyframes reveal-2 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(55%, -55%, 0);
    border-radius: 0;
  }
  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(55%, -55%, 0);
    border-radius: 0;
  }
  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}
@keyframes reveal-3 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(-55%, -55%, 0);
    border-radius: 0;
  }
  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(-55%, -55%, 0);
    border-radius: 0;
  }
  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}
@keyframes reveal-4 {
  0% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
  20% {
    transform: rotate3d(0, 0, 1, 135deg) translate3d(-55%, 55%, 0);
    border-radius: 0;
  }
  80% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(-55%, 55%, 0);
    border-radius: 0;
  }
  100% {
    transform: rotate3d(0, 0, 1, 495deg) translate3d(0, 0, 0);
    border-radius: 50%;
  }
}
.bounce-loader {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transition: all 0.2s;
  text-align: center;
  z-index: 10000;
}
.bounce-loader .bounce1,
.bounce-loader .bounce2,
.bounce-loader .bounce3,
.bounce-loader .bounce4 {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  background-color: #00a567;
}
.bounce-loader .bounce1 {
  animation: 2s infinite reveal-1;
}
.bounce-loader .bounce2 {
  animation: 2s infinite reveal-2;
}
.bounce-loader .bounce3 {
  animation: 2s infinite reveal-3;
}
.bounce-loader .bounce4 {
  animation: 2s infinite reveal-4;
}
.bounce-loader .bounce3 {
  border: 3px solid #00a567;
  background-color: transparent;
}
.appear-animate {
  transform: translate3d(0, 0, 0) scale(1);
  will-change: transform, filter, opacity;
}
.fade {
  opacity: 1;
  transition: opacity 0.5s;
}
.fade.in {
  opacity: 1;
}
.scroll-top {
  position: fixed;
  right: 15px;
  bottom: 0;
  width: 40px;
  height: 40px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  visibility: hidden;
  opacity: 1;
  transition: transform 0.3s, visibility 0.3s, opacity 0.3s;
  transform: translateY(40px);
  z-index: 9999;
  background-color: #43494e;
}
.scroll-top:hover {
  color: #fff;
}
.scroll-top i {
  line-height: 40px;
}
@media (min-width: 768px) {
  .scroll-top.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fixedTop {
  from {
    transform: translateY(-100%);
    transform-origin: center top 0px;
  }
  to {
    transform: translateY(0);
  }
}
@keyframes fixedBottom {
  from {
    transform: translateY(100%);
    transform-origin: center top 0px;
  }
  to {
    transform: translateY(0);
  }
}
.sticky-content.fix-top {
  top: 0;
}
.sticky-content.fix-bottom {
  bottom: 0;
}
.sticky-content.fixed {
  position: fixed;
  left: 0;
  right: 0;
  opacity: 1;
  transform: translateY(0);
  background: #fff;
  z-index: 1051;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}
.sticky-content.fixed.fix-top {
  animation: fixedTop 0.4s;
}
.sticky-content.fixed.fix-bottom {
  animation: fixedBottom 0.4s;
}
.loading:not(.load-more-overlay),
.load-more-overlay.loading::after {
  animation: spin 650ms infinite linear;
  border: 2px solid #fff;
  border-radius: 32px;
  border-top: 2px solid rgba(0, 0, 0, 0.4);
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  content: "";
  display: block;
  height: 20px;
  top: 50%;
  margin-top: -10px;
  left: 50%;
  margin-left: -10px;
  right: auto;
  position: absolute;
  width: 20px;
}
.load-more-overlay {
  position: relative;
}
.load-more-overlay.loading::after {
  content: "";
}
.load-more-overlay::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 1.8;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.p-sticky {
  position: sticky;
}
.p-relative {
  position: relative;
}
.x-50 {
  left: 50%;
  transform: translateX(-50%);
}
.x-50.y-50 {
  transform: translate(-50%, -50%);
}
.y-50 {
  top: 50%;
  transform: translateY(-50%);
}
.y-40 {
  top: 40%;
  transform: translateY(-50%);
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: flex-end;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-1 {
  flex: 1;
}
.overflow-hidden {
  overflow: hidden;
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-flex {
    display: flex;
  }
  .d-lg-inline-flex {
    display: inline-flex;
  }
}
@media (max-width: 1199px) {
  .d-xl-show {
    display: none;
  }
}
@media (max-width: 991px) {
  .d-lg-show {
    display: none;
  }
}
.font-primary {
  font-family: Poppins, sans-serif;
}
.font-secondary {
  font-family: "Open Sans", sans-serif;
}
.font-tertiary {
  font-family: "Open Sans", sans-serif;
}
.font-weight-bold {
  font-weight: 700;
}
.font-weight-semi-bold {
  font-weight: 600;
}
.font-weight-normal {
  font-weight: 400;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-normal {
  text-transform: none;
}
.font-italic {
  font-style: italic;
}
.font-normal {
  font-stretch: normal;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-white {
  color: #fff;
}
.text-light {
  color: #ccc;
}
.text-grey {
  color: #999;
}
.text-body {
  color: #666;
}
.text-dark {
  color: #222;
}
.text-black {
  color: #000;
}
.text-primary {
  color: #00a567;
}
.text-secondary {
  color: #d26e4b;
}
.ls-md {
  letter-spacing: -0.03em;
}
.ls-s {
  letter-spacing: -0.01em;
}
.ls-m {
  letter-spacing: -0.025em;
}
.ls-l {
  letter-spacing: -0.05em;
}
.ls-normal {
  letter-spacing: 0;
}
.lh-2 {
  line-height: 2;
}
.lh-1 {
  line-height: 1;
}
.lh-0 {
  line-height: 0;
}
.bg-white {
  background-color: #fff;
}
.bg-dark {
  background-color: #222;
}
.bg-grey {
  background-color: #999;
}
.bg-light {
  background-color: #ccc;
}
.bg-black {
  background-color: #000;
}
.bg-primary {
  background-color: #00a567;
}
.bg-secondary {
  background-color: #d26e4b;
}
.border-no {
  border: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
  margin: 0 0 2rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "proximaB";
  font-weight: 600;
  line-height: 1.4;
  color: #495057;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 3.4rem;
}
h3 {
  font-size: 3rem;
}
h4 {
  font-size: 2.4rem;
}
h5 {
  font-size: 1.8rem;
}
h6 {
  font-size: 1.5rem;
}
p {
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  line-height: 1.86;
}
blockquote {
  margin: 0;
}
.list {
  margin-left: 2rem;
  font-size: 1.4rem;
  line-height: 1.86;
  color: #666;
}
.list-style-none {
  list-style: none;
}
.list-type-number {
  list-style-type: decimal;
}
.list-type-check li {
  position: relative;
  padding-left: 2.4rem;
}
.list-type-check li::before {
  position: absolute;
  display: block;
  left: 0;
  top: 2px;
  content: "\e964";
  font-family: "Donald";
  font-size: 1.2rem;
}
.list-circle li {
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.list-circle i {
  margin-right: 1.2rem;
  width: 4rem;
  height: 4rem;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  text-align: center;
  line-height: 3.8rem;
  font-size: 1.1rem;
}
.list-arrow li {
  position: relative;
  transition: color 0.3s, padding 0.3s;
}
.list-arrow li::before {
  content: "\e97e";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  visibility: hidden;
  font-family: "Donald";
  font-size: 1.4rem;
  transition: opacity 0.3s, visibility 0.3s;
}
.list-arrow li:hover,
.list-arrow li.show {
  padding-left: 2.2rem;
  color: #00a567;
}
.list-arrow li:hover::before,
.list-arrow li.show::before {
  visibility: visible;
  opacity: 1;
}
.text-bd-left {
  position: relative;
  padding-left: 2rem;
}
.text-bd-left::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 2px;
  top: 5px;
  bottom: 5px;
  background-color: #dae1e5;
}
.container,
.container-fluid {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 479px) {
  .container,
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.container-fluid {
  max-width: 1820px;
}
.container {
  max-width: 1220px;
}
.divider {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.4rem;
  height: 1.9rem;
  width: 1px;
  background-color: #e1e1e1;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.row > * {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}
.gutter-lg {
  margin-left: -15px;
  margin-right: -15px;
}
.gutter-lg > * {
  padding-right: 15px;
  padding-left: 15px;
}
.gutter-sm {
  margin-left: -5px;
  margin-right: -5px;
}
.gutter-sm > * {
  padding-right: 5px;
  padding-left: 5px;
}
.gutter-xs {
  margin-left: -1px;
  margin-right: -1px;
}
.gutter-xs > * {
  padding-right: 1px;
  padding-left: 1px;
}
.gutter-no {
  margin-left: 0;
  margin-right: 0;
}
.gutter-no > * {
  padding-right: 0;
  padding-left: 0;
}
.cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}
.cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}
.cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}
.cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}
.cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}
.cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}
.cols-7 > * {
  max-width: 14.2857%;
  flex: 0 0 14.2857%;
}
.cols-8 > * {
  max-width: 12.5%;
  flex: 0 0 12.5%;
}
@media (min-width: 480px) {
  .cols-xs-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .cols-xs-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .cols-xs-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }
  .cols-xs-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .cols-xs-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }
  .cols-xs-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }
  .cols-xs-7 > * {
    max-width: 14.2857%;
    flex: 0 0 14.2857%;
  }
  .cols-xs-8 > * {
    max-width: 12.5%;
    flex: 0 0 12.5%;
  }
}
@media (min-width: 576px) {
  .cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }
  .cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }
  .cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }
  .cols-sm-7 > * {
    max-width: 14.2857%;
    flex: 0 0 14.2857%;
  }
  .cols-sm-8 > * {
    max-width: 12.5%;
    flex: 0 0 12.5%;
  }
}
@media (min-width: 768px) {
  .cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }
  .cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }
  .cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }
  .cols-md-7 > * {
    max-width: 14.2857%;
    flex: 0 0 14.2857%;
  }
  .cols-md-8 > * {
    max-width: 12.5%;
    flex: 0 0 12.5%;
  }
}
@media (min-width: 992px) {
  .cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }
  .cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }
  .cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }
  .cols-lg-7 > * {
    max-width: 14.2857%;
    flex: 0 0 14.2857%;
  }
  .cols-lg-8 > * {
    max-width: 12.5%;
    flex: 0 0 12.5%;
  }
}
@media (min-width: 1200px) {
  .cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }
  .cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }
  .cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }
  .cols-xl-7 > * {
    max-width: 14.2857%;
    flex: 0 0 14.2857%;
  }
  .cols-xl-8 > * {
    max-width: 12.5%;
    flex: 0 0 12.5%;
  }
}
.col-1 {
  max-width: 8.3333%;
  flex: 0 0 8.3333%;
}
.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}
.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}
.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}
.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}
.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}
.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}
.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}
.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}
.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}
.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}
.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}
@media (min-width: 480px) {
  .col-xs-1 {
    max-width: 8.3333%;
    flex: 0 0 8.3333%;
  }
  .col-xs-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }
  .col-xs-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .col-xs-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }
  .col-xs-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }
  .col-xs-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .col-xs-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }
  .col-xs-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }
  .col-xs-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }
  .col-xs-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }
  .col-xs-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }
  .col-xs-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 576px) {
  .col-sm-1 {
    max-width: 8.3333%;
    flex: 0 0 8.3333%;
  }
  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }
  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }
  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }
  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }
  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }
  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }
  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }
  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }
  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 768px) {
  .col-md-1 {
    max-width: 8.3333%;
    flex: 0 0 8.3333%;
  }
  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }
  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }
  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }
  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }
  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }
  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }
  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }
  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }
  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 992px) {
  .col-lg-1 {
    max-width: 8.3333%;
    flex: 0 0 8.3333%;
  }
  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }
  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }
  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }
  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }
  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }
  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }
  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }
  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }
  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl-1 {
    max-width: 8.3333%;
    flex: 0 0 8.3333%;
  }
  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }
  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }
  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }
  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }
  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }
  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }
  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }
  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }
  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }
  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 1600px) {
  .col-xxl-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.6666%;
    max-width: 16.6666%;
  }
}
@media (min-width: 1200px) {
  .col-xl-5col {
    position: relative;
    flex: 0 0 20%;
    max-width: 20%;
    padding-right: 10px;
    padding-left: 10px;
  }
  .col-xl-5col2 {
    position: relative;
    flex: 0 0 40%;
    max-width: 40%;
    padding-right: 10px;
    padding-left: 10px;
  }
  .col-xl-5col4 {
    position: relative;
    flex: 0 0 80%;
    max-width: 80%;
    padding-right: 10px;
    padding-left: 10px;
  }
}
.order-first {
  order: -1;
}
.order-last {
  order: 9;
}
@media (min-width: 576px) {
  .order-sm-auto {
    order: 0;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 9;
  }
}
@media (min-width: 768px) {
  .order-md-auto {
    order: 0;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 9;
  }
}
@media (min-width: 992px) {
  .order-lg-auto {
    order: 0;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 9;
  }
}
.grid {
  margin: -1rem;
}
.grid .banner,
.grid .category,
.grid .category > a,
.grid figure,
.grid .banner img,
.grid .category img {
  height: 100%;
}
.grid img {
  object-fit: cover;
}
.grid .grid-item {
  padding: 1rem;
  transform: translate3d(0, 0, 0);
}
.grid.gutter-sm {
  margin: -0.5rem;
}
.grid.gutter-sm .grid-item {
  padding: 0.5rem;
}
.grid.gutter-no {
  margin: 0;
}
.grid.gutter-no .grid-item {
  padding: 0;
}
.grid::after {
  content: "";
  display: block;
  clear: both;
}
.grid.grid-float {
  display: block;
}
.grid.grid-float .grid-item {
  float: left;
}
.split-line > * {
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #f4f4f4;
}
.split-line > *::after {
  content: "";
  display: block;
  width: 1px;
  height: calc(100% - 3rem);
  position: absolute;
  right: 0;
  top: 0;
  background-color: #e1e1e1;
}
.split-line > .col-1:nth-child(12n)::after,
.split-line > .col-2:nth-child(6n)::after,
.split-line > .col-3:nth-child(4n)::after,
.split-line > .col-4:nth-child(3n)::after,
.split-line > .col-5:nth-child(2n)::after,
.split-line > .col-6:nth-child(2n)::after,
.split-line > .col-7:nth-child(1n)::after,
.split-line > .col-8:nth-child(1n)::after,
.split-line > .col-9:nth-child(1n)::after,
.split-line > .col-10:nth-child(1n)::after,
.split-line > .col-11:nth-child(1n)::after,
.split-line.cols-1 > *:nth-child(1n)::after,
.split-line.cols-2 > *:nth-child(2n)::after,
.split-line.cols-3 > *:nth-child(3n)::after,
.split-line.cols-4 > *:nth-child(4n)::after,
.split-line.cols-5 > *:nth-child(5n)::after,
.split-line.cols-6 > *:nth-child(6n)::after,
.split-line.cols-7 > *:nth-child(7n)::after,
.split-line.cols-8 > *:nth-child(8n)::after {
  content: none;
}
@media (min-width: 576px) {
  .split-line > .col-sm-1:nth-child(n)::after,
  .split-line > .col-sm-2:nth-child(n)::after,
  .split-line > .col-sm-3:nth-child(n)::after,
  .split-line > .col-sm-4:nth-child(n)::after,
  .split-line > .col-sm-5:nth-child(n)::after,
  .split-line > .col-sm-6:nth-child(n)::after,
  .split-line > .col-sm-7:nth-child(n)::after,
  .split-line > .col-sm-8:nth-child(n)::after,
  .split-line > .col-sm-9:nth-child(n)::after,
  .split-line > .col-sm-10:nth-child(n)::after,
  .split-line > .col-sm-11:nth-child(n)::after,
  .split-line.cols-sm-1 > *:nth-child(n)::after,
  .split-line.cols-sm-2 > *:nth-child(n)::after,
  .split-line.cols-sm-3 > *:nth-child(n)::after,
  .split-line.cols-sm-4 > *:nth-child(n)::after,
  .split-line.cols-sm-5 > *:nth-child(n)::after,
  .split-line.cols-sm-6 > *:nth-child(n)::after,
  .split-line.cols-sm-7 > *:nth-child(n)::after,
  .split-line.cols-sm-8 > *:nth-child(n)::after {
    content: "";
  }
  .split-line > .col-sm-1:nth-child(12n)::after,
  .split-line > .col-sm-2:nth-child(6n)::after,
  .split-line > .col-sm-3:nth-child(4n)::after,
  .split-line > .col-sm-4:nth-child(3n)::after,
  .split-line > .col-sm-5:nth-child(2n)::after,
  .split-line > .col-sm-6:nth-child(2n)::after,
  .split-line > .col-sm-7:nth-child(1n)::after,
  .split-line > .col-sm-8:nth-child(1n)::after,
  .split-line > .col-sm-9:nth-child(1n)::after,
  .split-line > .col-sm-10:nth-child(1n)::after,
  .split-line > .col-sm-11:nth-child(1n)::after,
  .split-line.cols-sm-1 > *:nth-child(1n)::after,
  .split-line.cols-sm-2 > *:nth-child(2n)::after,
  .split-line.cols-sm-3 > *:nth-child(3n)::after,
  .split-line.cols-sm-4 > *:nth-child(4n)::after,
  .split-line.cols-sm-5 > *:nth-child(5n)::after,
  .split-line.cols-sm-6 > *:nth-child(6n)::after,
  .split-line.cols-sm-7 > *:nth-child(7n)::after,
  .split-line.cols-sm-8 > *:nth-child(8n)::after {
    content: none;
  }
}
@media (min-width: 768px) {
  .split-line > .col-md-1:nth-child(n)::after,
  .split-line > .col-md-2:nth-child(n)::after,
  .split-line > .col-md-3:nth-child(n)::after,
  .split-line > .col-md-4:nth-child(n)::after,
  .split-line > .col-md-5:nth-child(n)::after,
  .split-line > .col-md-6:nth-child(n)::after,
  .split-line > .col-md-7:nth-child(n)::after,
  .split-line > .col-md-8:nth-child(n)::after,
  .split-line > .col-md-9:nth-child(n)::after,
  .split-line > .col-md-10:nth-child(n)::after,
  .split-line > .col-md-11:nth-child(n)::after,
  .split-line.cols-md-1 > *:nth-child(n)::after,
  .split-line.cols-md-2 > *:nth-child(n)::after,
  .split-line.cols-md-3 > *:nth-child(n)::after,
  .split-line.cols-md-4 > *:nth-child(n)::after,
  .split-line.cols-md-5 > *:nth-child(n)::after,
  .split-line.cols-md-6 > *:nth-child(n)::after,
  .split-line.cols-md-7 > *:nth-child(n)::after,
  .split-line.cols-md-8 > *:nth-child(n)::after {
    content: "";
  }
  .split-line > .col-md-1:nth-child(12n)::after,
  .split-line > .col-md-2:nth-child(6n)::after,
  .split-line > .col-md-3:nth-child(4n)::after,
  .split-line > .col-md-4:nth-child(3n)::after,
  .split-line > .col-md-5:nth-child(2n)::after,
  .split-line > .col-md-6:nth-child(2n)::after,
  .split-line > .col-md-7:nth-child(1n)::after,
  .split-line > .col-md-8:nth-child(1n)::after,
  .split-line > .col-md-9:nth-child(1n)::after,
  .split-line > .col-md-10:nth-child(1n)::after,
  .split-line > .col-md-11:nth-child(1n)::after,
  .split-line.cols-md-1 > *:nth-child(1n)::after,
  .split-line.cols-md-2 > *:nth-child(2n)::after,
  .split-line.cols-md-3 > *:nth-child(3n)::after,
  .split-line.cols-md-4 > *:nth-child(4n)::after,
  .split-line.cols-md-5 > *:nth-child(5n)::after,
  .split-line.cols-md-6 > *:nth-child(6n)::after,
  .split-line.cols-md-7 > *:nth-child(7n)::after,
  .split-line.cols-md-8 > *:nth-child(8n)::after {
    content: none;
  }
}
@media (min-width: 992px) {
  .split-line > .col-lg-1:nth-child(n)::after,
  .split-line > .col-lg-2:nth-child(n)::after,
  .split-line > .col-lg-3:nth-child(n)::after,
  .split-line > .col-lg-4:nth-child(n)::after,
  .split-line > .col-lg-5:nth-child(n)::after,
  .split-line > .col-lg-6:nth-child(n)::after,
  .split-line > .col-lg-7:nth-child(n)::after,
  .split-line > .col-lg-8:nth-child(n)::after,
  .split-line > .col-lg-9:nth-child(n)::after,
  .split-line > .col-lg-10:nth-child(n)::after,
  .split-line > .col-lg-11:nth-child(n)::after,
  .split-line.cols-lg-1 > *:nth-child(n)::after,
  .split-line.cols-lg-2 > *:nth-child(n)::after,
  .split-line.cols-lg-3 > *:nth-child(n)::after,
  .split-line.cols-lg-4 > *:nth-child(n)::after,
  .split-line.cols-lg-5 > *:nth-child(n)::after,
  .split-line.cols-lg-6 > *:nth-child(n)::after,
  .split-line.cols-lg-7 > *:nth-child(n)::after,
  .split-line.cols-lg-8 > *:nth-child(n)::after {
    content: "";
  }
  .split-line > .col-lg-1:nth-child(12n)::after,
  .split-line > .col-lg-2:nth-child(6n)::after,
  .split-line > .col-lg-3:nth-child(4n)::after,
  .split-line > .col-lg-4:nth-child(3n)::after,
  .split-line > .col-lg-5:nth-child(2n)::after,
  .split-line > .col-lg-6:nth-child(2n)::after,
  .split-line > .col-lg-7:nth-child(1n)::after,
  .split-line > .col-lg-8:nth-child(1n)::after,
  .split-line > .col-lg-9:nth-child(1n)::after,
  .split-line > .col-lg-10:nth-child(1n)::after,
  .split-line > .col-lg-11:nth-child(1n)::after,
  .split-line.cols-lg-1 > *:nth-child(1n)::after,
  .split-line.cols-lg-2 > *:nth-child(2n)::after,
  .split-line.cols-lg-3 > *:nth-child(3n)::after,
  .split-line.cols-lg-4 > *:nth-child(4n)::after,
  .split-line.cols-lg-5 > *:nth-child(5n)::after,
  .split-line.cols-lg-6 > *:nth-child(6n)::after,
  .split-line.cols-lg-7 > *:nth-child(7n)::after,
  .split-line.cols-lg-8 > *:nth-child(8n)::after {
    content: none;
  }
}
@media (min-width: 1200px) {
  .split-line > .col-xl-1:nth-child(n)::after,
  .split-line > .col-xl-2:nth-child(n)::after,
  .split-line > .col-xl-3:nth-child(n)::after,
  .split-line > .col-xl-4:nth-child(n)::after,
  .split-line > .col-xl-5:nth-child(n)::after,
  .split-line > .col-xl-6:nth-child(n)::after,
  .split-line > .col-xl-7:nth-child(n)::after,
  .split-line > .col-xl-8:nth-child(n)::after,
  .split-line > .col-xl-9:nth-child(n)::after,
  .split-line > .col-xl-10:nth-child(n)::after,
  .split-line > .col-xl-11:nth-child(n)::after,
  .split-line.cols-xl-1 > *:nth-child(n)::after,
  .split-line.cols-xl-2 > *:nth-child(n)::after,
  .split-line.cols-xl-3 > *:nth-child(n)::after,
  .split-line.cols-xl-4 > *:nth-child(n)::after,
  .split-line.cols-xl-5 > *:nth-child(n)::after,
  .split-line.cols-xl-6 > *:nth-child(n)::after,
  .split-line.cols-xl-7 > *:nth-child(n)::after,
  .split-line.cols-xl-8 > *:nth-child(n)::after,
  .split-line > .col-xl-5col:nth-child(n)::after,
  .split-line > .col-xl-7col:nth-child(n)::after,
  .split-line > .col-xl-8col:nth-child(n)::after {
    content: "";
  }
  .split-line > .col-xl-1:nth-child(12n)::after,
  .split-line > .col-xl-2:nth-child(6n)::after,
  .split-line > .col-xl-3:nth-child(4n)::after,
  .split-line > .col-xl-4:nth-child(3n)::after,
  .split-line > .col-xl-5:nth-child(2n)::after,
  .split-line > .col-xl-6:nth-child(2n)::after,
  .split-line > .col-xl-7:nth-child(1n)::after,
  .split-line > .col-xl-8:nth-child(1n)::after,
  .split-line > .col-xl-9:nth-child(1n)::after,
  .split-line > .col-xl-10:nth-child(1n)::after,
  .split-line > .col-xl-11:nth-child(1n)::after,
  .split-line.cols-xl-1 > *:nth-child(1n)::after,
  .split-line.cols-xl-2 > *:nth-child(2n)::after,
  .split-line.cols-xl-3 > *:nth-child(3n)::after,
  .split-line.cols-xl-4 > *:nth-child(4n)::after,
  .split-line.cols-xl-5 > *:nth-child(5n)::after,
  .split-line.cols-xl-6 > *:nth-child(6n)::after,
  .split-line.cols-xl-7 > *:nth-child(7n)::after,
  .split-line.cols-xl-8 > *:nth-child(8n)::after,
  .split-line > .col-xl-5col:nth-child(5n)::after,
  .split-line > .col-xl-7col:nth-child(7n)::after,
  .split-line > .col-xl-8col:nth-child(8n)::after {
    content: none;
  }
}
.mt-0 {
  margin-top: 0rem;
}
.mb-0 {
  margin-bottom: 0rem;
}
.pt-0 {
  padding-top: 0rem;
}
.pb-0 {
  padding-bottom: 0rem;
}
.mt-1 {
  margin-top: 0.5rem;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.pt-1 {
  padding-top: 0.5rem;
}
.pb-1 {
  padding-bottom: 0.5rem;
}
.mt-2 {
  margin-top: 1rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.pt-2 {
  padding-top: 1rem;
}
.pb-2 {
  padding-bottom: 1rem;
}
.mt-3 {
  margin-top: 1.5rem;
}
.mb-3 {
  margin-bottom: 1.5rem;
}
.pt-3 {
  padding-top: 1.5rem;
}
.pb-3 {
  padding-bottom: 1.5rem;
}
.mt-4 {
  margin-top: 2rem;
}
.mb-4 {
  margin-bottom: 2rem;
}
.pt-4 {
  padding-top: 2rem;
}
.pb-4 {
  padding-bottom: 2rem;
}
.mt-5 {
  margin-top: 2.5rem;
}
.mb-5 {
  margin-bottom: 2.5rem;
}
.pt-5 {
  padding-top: 2.5rem;
}
.pb-5 {
  padding-bottom: 2.5rem;
}
.mt-6 {
  margin-top: 3rem;
}
.mb-6 {
  margin-bottom: 3rem;
}
.pt-6 {
  padding-top: 3rem;
}
.pb-6 {
  padding-bottom: 3rem;
}
.mt-7 {
  margin-top: 3.5rem;
}
.mb-7 {
  margin-bottom: 3.5rem;
}
.pt-7 {
  padding-top: 3.5rem;
}
.pb-7 {
  padding-bottom: 3.5rem;
}
.mt-8 {
  margin-top: 4rem;
}
.mb-8 {
  margin-bottom: 4rem;
}
.pt-8 {
  padding-top: 4rem;
}
.pb-8 {
  padding-bottom: 4rem;
}
.mt-9 {
  margin-top: 4.5rem;
}
.mb-9 {
  margin-bottom: 4.5rem;
}
.pt-9 {
  padding-top: 4.5rem;
}
.pb-9 {
  padding-bottom: 4.5rem;
}
.mt-10 {
  margin-top: 5rem;
}
.mb-10 {
  margin-bottom: 5rem;
}
.pt-10 {
  padding-top: 5rem;
}
.pb-10 {
  padding-bottom: 5rem;
}
.ml-0 {
  margin-left: 0rem;
}
.mr-0 {
  margin-right: 0rem;
}
.pl-0 {
  padding-left: 0rem;
}
.pr-0 {
  padding-right: 0rem;
}
.ml-1 {
  margin-left: 0.5rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
.pl-1 {
  padding-left: 0.5rem;
}
.pr-1 {
  padding-right: 0.5rem;
}
.ml-2 {
  margin-left: 1rem;
}
.mr-2 {
  margin-right: 1rem;
}
.pl-2 {
  padding-left: 1rem;
}
.pr-2 {
  padding-right: 1rem;
}
.ml-3 {
  margin-left: 1.5rem;
}
.mr-3 {
  margin-right: 1.5rem;
}
.pl-3 {
  padding-left: 1.5rem;
}
.pr-3 {
  padding-right: 1.5rem;
}
.ml-4 {
  margin-left: 2rem;
}
.mr-4 {
  margin-right: 2rem;
}
.pl-4 {
  padding-left: 2rem;
}
.pr-4 {
  padding-right: 2rem;
}
.ml-5 {
  margin-left: 2.5rem;
}
.mr-5 {
  margin-right: 2.5rem;
}
.pl-5 {
  padding-left: 2.5rem;
}
.pr-5 {
  padding-right: 2.5rem;
}
.ml-6 {
  margin-left: 3rem;
}
.mr-6 {
  margin-right: 3rem;
}
.pl-6 {
  padding-left: 3rem;
}
.pr-6 {
  padding-right: 3rem;
}
.ml-7 {
  margin-left: 3.5rem;
}
.mr-7 {
  margin-right: 3.5rem;
}
.pl-7 {
  padding-left: 3.5rem;
}
.pr-7 {
  padding-right: 3.5rem;
}
.ml-8 {
  margin-left: 4rem;
}
.mr-8 {
  margin-right: 4rem;
}
.pl-8 {
  padding-left: 4rem;
}
.pr-8 {
  padding-right: 4rem;
}
.ml-9 {
  margin-left: 4.5rem;
}
.mr-9 {
  margin-right: 4.5rem;
}
.pl-9 {
  padding-left: 4.5rem;
}
.pr-9 {
  padding-right: 4.5rem;
}
.ml-10 {
  margin-left: 5rem;
}
.mr-10 {
  margin-right: 5rem;
}
.pl-10 {
  padding-left: 5rem;
}
.pr-10 {
  padding-right: 5rem;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0rem;
  }
  .mt-sm-1 {
    margin-top: 0.5rem;
  }
  .mt-sm-2 {
    margin-top: 1rem;
  }
  .mt-sm-3 {
    margin-top: 1.5rem;
  }
  .mt-sm-4 {
    margin-top: 2rem;
  }
  .mt-sm-5 {
    margin-top: 2.5rem;
  }
  .mt-sm-6 {
    margin-top: 3rem;
  }
  .mt-sm-7 {
    margin-top: 3.5rem;
  }
  .mt-sm-8 {
    margin-top: 4rem;
  }
  .mt-sm-9 {
    margin-top: 4.5rem;
  }
  .mt-sm-10 {
    margin-top: 5rem;
  }
  .mb-sm-0 {
    margin-bottom: 0rem;
  }
  .mb-sm-1 {
    margin-bottom: 0.5rem;
  }
  .mb-sm-2 {
    margin-bottom: 1rem;
  }
  .mb-sm-3 {
    margin-bottom: 1.5rem;
  }
  .mb-sm-4 {
    margin-bottom: 2rem;
  }
  .mb-sm-5 {
    margin-bottom: 2.5rem;
  }
  .mb-sm-6 {
    margin-bottom: 3rem;
  }
  .mb-sm-7 {
    margin-bottom: 3.5rem;
  }
  .mb-sm-8 {
    margin-bottom: 4rem;
  }
  .mb-sm-9 {
    margin-bottom: 4.5rem;
  }
  .mb-sm-10 {
    margin-bottom: 5rem;
  }
  .pt-sm-0 {
    padding-top: 0rem;
  }
  .pt-sm-1 {
    padding-top: 0.5rem;
  }
  .pt-sm-2 {
    padding-top: 1rem;
  }
  .pt-sm-3 {
    padding-top: 1.5rem;
  }
  .pt-sm-4 {
    padding-top: 2rem;
  }
  .pt-sm-5 {
    padding-top: 2.5rem;
  }
  .pt-sm-6 {
    padding-top: 3rem;
  }
  .pt-sm-7 {
    padding-top: 3.5rem;
  }
  .pt-sm-8 {
    padding-top: 4rem;
  }
  .pt-sm-9 {
    padding-top: 4.5rem;
  }
  .pt-sm-10 {
    padding-top: 5rem;
  }
  .pb-sm-0 {
    padding-bottom: 0rem;
  }
  .pb-sm-1 {
    padding-bottom: 0.5rem;
  }
  .pb-sm-2 {
    padding-bottom: 1rem;
  }
  .pb-sm-3 {
    padding-bottom: 1.5rem;
  }
  .pb-sm-4 {
    padding-bottom: 2rem;
  }
  .pb-sm-5 {
    padding-bottom: 2.5rem;
  }
  .pb-sm-6 {
    padding-bottom: 3rem;
  }
  .pb-sm-7 {
    padding-bottom: 3.5rem;
  }
  .pb-sm-8 {
    padding-bottom: 4rem;
  }
  .pb-sm-9 {
    padding-bottom: 4.5rem;
  }
  .pb-sm-10 {
    padding-bottom: 5rem;
  }
}
@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0rem;
  }
  .mt-md-1 {
    margin-top: 0.5rem;
  }
  .mt-md-2 {
    margin-top: 1rem;
  }
  .mt-md-3 {
    margin-top: 1.5rem;
  }
  .mt-md-4 {
    margin-top: 2rem;
  }
  .mt-md-5 {
    margin-top: 2.5rem;
  }
  .mt-md-6 {
    margin-top: 3rem;
  }
  .mt-md-7 {
    margin-top: 3.5rem;
  }
  .mt-md-8 {
    margin-top: 4rem;
  }
  .mt-md-9 {
    margin-top: 4.5rem;
  }
  .mt-md-10 {
    margin-top: 5rem;
  }
  .mb-md-0 {
    margin-bottom: 0rem;
  }
  .mb-md-1 {
    margin-bottom: 0.5rem;
  }
  .mb-md-2 {
    margin-bottom: 1rem;
  }
  .mb-md-3 {
    margin-bottom: 1.5rem;
  }
  .mb-md-4 {
    margin-bottom: 2rem;
  }
  .mb-md-5 {
    margin-bottom: 2.5rem;
  }
  .mb-md-6 {
    margin-bottom: 3rem;
  }
  .mb-md-7 {
    margin-bottom: 3.5rem;
  }
  .mb-md-8 {
    margin-bottom: 4rem;
  }
  .mb-md-9 {
    margin-bottom: 4.5rem;
  }
  .mb-md-10 {
    margin-bottom: 5rem;
  }
  .pt-md-0 {
    padding-top: 0rem;
  }
  .pt-md-1 {
    padding-top: 0.5rem;
  }
  .pt-md-2 {
    padding-top: 1rem;
  }
  .pt-md-3 {
    padding-top: 1.5rem;
  }
  .pt-md-4 {
    padding-top: 2rem;
  }
  .pt-md-5 {
    padding-top: 2.5rem;
  }
  .pt-md-6 {
    padding-top: 3rem;
  }
  .pt-md-7 {
    padding-top: 3.5rem;
  }
  .pt-md-8 {
    padding-top: 4rem;
  }
  .pt-md-9 {
    padding-top: 4.5rem;
  }
  .pt-md-10 {
    padding-top: 5rem;
  }
  .pb-md-0 {
    padding-bottom: 0rem;
  }
  .pb-md-1 {
    padding-bottom: 0.5rem;
  }
  .pb-md-2 {
    padding-bottom: 1rem;
  }
  .pb-md-3 {
    padding-bottom: 1.5rem;
  }
  .pb-md-4 {
    padding-bottom: 2rem;
  }
  .pb-md-5 {
    padding-bottom: 2.5rem;
  }
  .pb-md-6 {
    padding-bottom: 3rem;
  }
  .pb-md-7 {
    padding-bottom: 3.5rem;
  }
  .pb-md-8 {
    padding-bottom: 4rem;
  }
  .pb-md-9 {
    padding-bottom: 4.5rem;
  }
  .pb-md-10 {
    padding-bottom: 5rem;
  }
}
@media (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0rem;
  }
  .mt-lg-1 {
    margin-top: 0.5rem;
  }
  .mt-lg-2 {
    margin-top: 1rem;
  }
  .mt-lg-3 {
    margin-top: 1.5rem;
  }
  .mt-lg-4 {
    margin-top: 2rem;
  }
  .mt-lg-5 {
    margin-top: 2.5rem;
  }
  .mt-lg-6 {
    margin-top: 3rem;
  }
  .mt-lg-7 {
    margin-top: 3.5rem;
  }
  .mt-lg-8 {
    margin-top: 4rem;
  }
  .mt-lg-9 {
    margin-top: 4.5rem;
  }
  .mt-lg-10 {
    margin-top: 5rem;
  }
  .mb-lg-0 {
    margin-bottom: 0rem;
  }
  .mb-lg-1 {
    margin-bottom: 0.5rem;
  }
  .mb-lg-2 {
    margin-bottom: 1rem;
  }
  .mb-lg-3 {
    margin-bottom: 1.5rem;
  }
  .mb-lg-4 {
    margin-bottom: 2rem;
  }
  .mb-lg-5 {
    margin-bottom: 2.5rem;
  }
  .mb-lg-6 {
    margin-bottom: 3rem;
  }
  .mb-lg-7 {
    margin-bottom: 3.5rem;
  }
  .mb-lg-8 {
    margin-bottom: 4rem;
  }
  .mb-lg-9 {
    margin-bottom: 4.5rem;
  }
  .mb-lg-10 {
    margin-bottom: 5rem;
  }
  .pt-lg-0 {
    padding-top: 0rem;
  }
  .pt-lg-1 {
    padding-top: 0.5rem;
  }
  .pt-lg-2 {
    padding-top: 1rem;
  }
  .pt-lg-3 {
    padding-top: 1.5rem;
  }
  .pt-lg-4 {
    padding-top: 2rem;
  }
  .pt-lg-5 {
    padding-top: 2.5rem;
  }
  .pt-lg-6 {
    padding-top: 3rem;
  }
  .pt-lg-7 {
    padding-top: 3.5rem;
  }
  .pt-lg-8 {
    padding-top: 4rem;
  }
  .pt-lg-9 {
    padding-top: 4.5rem;
  }
  .pt-lg-10 {
    padding-top: 5rem;
  }
  .pb-lg-0 {
    padding-bottom: 0rem;
  }
  .pb-lg-1 {
    padding-bottom: 0.5rem;
  }
  .pb-lg-2 {
    padding-bottom: 1rem;
  }
  .pb-lg-3 {
    padding-bottom: 1.5rem;
  }
  .pb-lg-4 {
    padding-bottom: 2rem;
  }
  .pb-lg-5 {
    padding-bottom: 2.5rem;
  }
  .pb-lg-6 {
    padding-bottom: 3rem;
  }
  .pb-lg-7 {
    padding-bottom: 3.5rem;
  }
  .pb-lg-8 {
    padding-bottom: 4rem;
  }
  .pb-lg-9 {
    padding-bottom: 4.5rem;
  }
  .pb-lg-10 {
    padding-bottom: 5rem;
  }
  .ml-lg-0 {
    margin-left: 0rem;
  }
  .mr-lg-0 {
    margin-right: 0rem;
  }
  .pl-lg-0 {
    padding-left: 0rem;
  }
  .pr-lg-0 {
    padding-right: 0rem;
  }
  .ml-lg-1 {
    margin-left: 0.5rem;
  }
  .mr-lg-1 {
    margin-right: 0.5rem;
  }
  .pl-lg-1 {
    padding-left: 0.5rem;
  }
  .pr-lg-1 {
    padding-right: 0.5rem;
  }
  .ml-lg-2 {
    margin-left: 1rem;
  }
  .mr-lg-2 {
    margin-right: 1rem;
  }
  .pl-lg-2 {
    padding-left: 1rem;
  }
  .pr-lg-2 {
    padding-right: 1rem;
  }
  .ml-lg-3 {
    margin-left: 1.5rem;
  }
  .mr-lg-3 {
    margin-right: 1.5rem;
  }
  .pl-lg-3 {
    padding-left: 1.5rem;
  }
  .pr-lg-3 {
    padding-right: 1.5rem;
  }
  .ml-lg-4 {
    margin-left: 2rem;
  }
  .mr-lg-4 {
    margin-right: 2rem;
  }
  .pl-lg-4 {
    padding-left: 2rem;
  }
  .pr-lg-4 {
    padding-right: 2rem;
  }
  .ml-lg-5 {
    margin-left: 2.5rem;
  }
  .mr-lg-5 {
    margin-right: 2.5rem;
  }
  .pl-lg-5 {
    padding-left: 2.5rem;
  }
  .pr-lg-5 {
    padding-right: 2.5rem;
  }
  .ml-lg-6 {
    margin-left: 3rem;
  }
  .mr-lg-6 {
    margin-right: 3rem;
  }
  .pl-lg-6 {
    padding-left: 3rem;
  }
  .pr-lg-6 {
    padding-right: 3rem;
  }
  .ml-lg-7 {
    margin-left: 3.5rem;
  }
  .mr-lg-7 {
    margin-right: 3.5rem;
  }
  .pl-lg-7 {
    padding-left: 3.5rem;
  }
  .pr-lg-7 {
    padding-right: 3.5rem;
  }
  .ml-lg-8 {
    margin-left: 4rem;
  }
  .mr-lg-8 {
    margin-right: 4rem;
  }
  .pl-lg-8 {
    padding-left: 4rem;
  }
  .pr-lg-8 {
    padding-right: 4rem;
  }
  .ml-lg-9 {
    margin-left: 4.5rem;
  }
  .mr-lg-9 {
    margin-right: 4.5rem;
  }
  .pl-lg-9 {
    padding-left: 4.5rem;
  }
  .pr-lg-9 {
    padding-right: 4.5rem;
  }
  .ml-lg-10 {
    margin-left: 5rem;
  }
  .mr-lg-10 {
    margin-right: 5rem;
  }
  .pl-lg-10 {
    padding-left: 5rem;
  }
  .pr-lg-10 {
    padding-right: 5rem;
  }
}
@media (min-width: 1200px) {
  .mt-xl-0 {
    margin-top: 0rem;
  }
  .mt-xl-1 {
    margin-top: 0.5rem;
  }
  .mt-xl-2 {
    margin-top: 1rem;
  }
  .mt-xl-3 {
    margin-top: 1.5rem;
  }
  .mt-xl-4 {
    margin-top: 2rem;
  }
  .mt-xl-5 {
    margin-top: 2.5rem;
  }
  .mt-xl-6 {
    margin-top: 3rem;
  }
  .mt-xl-7 {
    margin-top: 3.5rem;
  }
  .mt-xl-8 {
    margin-top: 4rem;
  }
  .mt-xl-9 {
    margin-top: 4.5rem;
  }
  .mt-xl-10 {
    margin-top: 5rem;
  }
  .mb-xl-0 {
    margin-bottom: 0rem;
  }
  .mb-xl-1 {
    margin-bottom: 0.5rem;
  }
  .mb-xl-2 {
    margin-bottom: 1rem;
  }
  .mb-xl-3 {
    margin-bottom: 1.5rem;
  }
  .mb-xl-4 {
    margin-bottom: 2rem;
  }
  .mb-xl-5 {
    margin-bottom: 2.5rem;
  }
  .mb-xl-6 {
    margin-bottom: 3rem;
  }
  .mb-xl-7 {
    margin-bottom: 3.5rem;
  }
  .mb-xl-8 {
    margin-bottom: 4rem;
  }
  .mb-xl-9 {
    margin-bottom: 4.5rem;
  }
  .mb-xl-10 {
    margin-bottom: 5rem;
  }
}
@media (min-width: 992px) {
  .ml-lg-auto {
    margin-left: auto;
  }
  .mr-lg-auto {
    margin-right: auto;
  }
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
.accordion {
  overflow: hidden;
}
.accordion .collapsed,
.accordion .expanding {
  display: none;
}
.card-header {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1;
  color: #222;
}
.card-header a {
  display: flex;
  align-items: center;
  position: relative;
  padding: 1.8rem 0.5rem;
}
.card-header a:hover {
  color: #00a567;
}
.card-header a::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.4rem;
  font-family: "Donald";
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.01em;
  color: #222;
}
.expand::after {
  content: "\e968";
}
.collapse::after {
  content: "\e967";
}
.card-body {
  padding: 1.3rem 0.5rem;
}
.card {
  background-color: #fff;
}
.accordion-simple .card {
  border-top: 1px solid #ebebeb;
}
.accordion-simple .card:last-child {
  border-bottom: 1px solid #ebebeb;
}
.accordion-gutter-md .card:not(:last-child) {
  margin-bottom: 10px;
}
.accordion-gutter-sm .card:not(:last-child) {
  margin-bottom: 2px;
}
.accordion-plus .card-header a::after {
  font-size: 1.3rem;
  font-weight: 400;
  color: #666;
}
.accordion-plus .expand::after {
  content: "\e96d";
}
.accordion-plus .collapse::after {
  content: "\e96c";
}
.accordion-boxed .card-header a,
.accordion-boxed .card-body {
  padding-left: 2rem;
  padding-right: 2rem;
}
.accordion-boxed .card-header a::after {
  right: 2rem;
}
.accordion-border .card {
  border-top: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}
.accordion-border .card:last-child {
  border-bottom: 1px solid #ebebeb;
}
.accordion-background .card {
  background-color: #fff;
  border-color: #fff;
}
.accordion-dropshadow {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.12);
}
.accordion-dropshadow .card {
  background-color: #fff;
  border-color: #fff;
}
.accordion-icon .card-header i {
  margin-right: 1.5rem;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 0;
}
.accordion-card-bg .card {
  border: 1px solid #f2f3f5;
}
.accordion-card-bg .card-header a {
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  background-color: #f2f3f5;
}
.accordion-card-bg.accordion-primary .card {
  border: 0;
  background-color: #f2f3f5;
}
.accordion-card-bg.accordion-primary .card-header a {
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  background-color: #00a567;
  color: #fff;
}
.accordion-card-bg.accordion-primary .card-header a::after {
  color: #fff;
}
.accordion-color .collapse {
  color: #00a567;
}
.accordion-card-border .card {
  border: 1px solid #dae1e5;
}
.alert-round {
  border-radius: 3px;
}
.alert {
  position: relative;
  padding: 1.6rem 3.8rem 1.6rem 1.8rem;
  border: 1px solid #222;
  font-size: 1.4rem;
  font-family: "Open Sans", sans-serif;
  line-height: 1.75;
}
.alert .btn-close {
  font-size: 1.2rem;
}
.alert .btn-close {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  line-height: 0;
}
.alert-title {
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: inherit;
  color: inherit;
}
.btn-close {
  transition: color 0.3s;
}
.btn-close:hover {
  color: #000;
}
.alert-primary {
  background-color: #00a567;
  border-color: #00a567;
  color: #00a567;
}
.alert-primary .btn-close {
  color: #00a567;
}
.alert-success {
  background-color: #a8c26e;
  border-color: #a8c26e;
  color: #a8c26e;
}
.alert-success .btn-close {
  color: #a8c26e;
}
.alert-warning {
  background-color: #d26e4b;
  border-color: #d26e4b;
  color: #d26e4b;
}
.alert-warning .btn-close {
  color: #d26e4b;
}
.alert-danger {
  background-color: #b10001;
  border-color: #b10001;
  color: #b10001;
}
.alert-danger .btn-close {
  color: #b10001;
}
.alert-black {
  background-color: #222;
  border-color: #222;
}
.alert-black .btn-close:hover {
  color: #666;
}
.alert-simple {
  background-color: transparent;
  border-style: dashed;
  border-color: #cacbcc;
}
.alert-dark {
  color: #fff;
}
.alert-dark .btn-close {
  color: #fff;
}
.alert-icon {
  padding: 1.5rem 3.9rem 1.5rem 1.9rem;
}
.alert-icon > i {
  vertical-align: middle;
  margin-right: 1.2rem;
  line-height: 1;
  font-size: 2.4rem;
  font-weight: 600;
}
.alert-light.alert-primary {
  background-color: #edf4f9;
  border-color: #edf4f9;
}
.alert-light.alert-danger {
  background-color: #f6d6d5;
  border-color: #f6d6d5;
  color: #6d1a17;
}
.alert-light.alert-danger .btn-close {
  color: #6d1a17;
}
.alert-light.alert-warning {
  background-color: #fff5eb;
  border-color: #fff5eb;
}
.alert-message {
  display: block;
  padding: 1.3rem 3.9rem 1.9rem 1.9rem;
}
.alert-message .btn-close {
  position: absolute;
  transform: translateY(0);
  top: 2rem;
  right: 2rem;
}
.alert-message .alert-title {
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.7;
}
.alert-message p {
  margin: 0;
  padding-right: 2rem;
  line-height: 1.7;
}
.alert-message.alert-link p {
  color: #666;
}
.alert-message.alert-link a {
  color: #00a567;
}
.alert-message.alert-link a:hover {
  text-decoration: underline;
}
.alert.alert-inline {
  padding-right: 4rem;
}
.alert.alert-inline .alert-title {
  display: inline-block;
  margin: 0 0.8rem 0 0;
}
.alert.alert-inline p {
  display: inline;
}
.alert-btn:not(.alert-message) {
  padding: 0.9rem 3.9rem 0.9rem 0.9rem;
}
.alert-btn .btn:not(.btn-close) {
  margin-right: 1rem;
  padding: 0.73em 1.61em;
}
.alert-btn.alert-message p {
  margin-bottom: 1rem;
}
.alert-btn.alert-dark.alert-warning .btn:not(.btn-close) {
  background-color: transparent;
  padding: 0.7em 1.43em;
  border-width: 3px;
  color: #fff;
}
.alert-btn.alert-dark.alert-warning .btn:not(.btn-close):hover {
  border-color: #df7f50;
  background: #df7f50;
}
.alert-btn.alert-light.alert-warning .btn:not(.btn-close) {
  color: #d26e4b;
  background-color: #fff;
  border-color: #fff;
}
.alert-btn.alert-light.alert-warning .btn:not(.btn-close):hover {
  color: #fff;
  background-color: #d26e4b;
  border-color: #d26e4b;
}
.alert-summary > i {
  margin-right: 0.9rem;
  font-size: 1.6rem;
  line-height: 1.25;
}
.alert-summary ul {
  margin: 1.7rem 0 0 2.7rem;
  list-style-type: disc;
}
@keyframes maskUp {
  from {
    transform: translate(0, 100%);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes maskRight {
  from {
    transform: translate(-100%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes maskDown {
  from {
    transform: translate(0, -100%);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes maskLeft {
  from {
    transform: translate(100%, 0);
  }
  to {
    transform: translate(0, 0);
  }
}
.maskUp {
  animation-name: maskUp;
}
.maskRight {
  animation-name: maskRight;
}
.maskDown {
  animation-name: maskDown;
}
.maskLeft {
  animation-name: maskLeft;
}
@keyframes fadeInUpShorter {
  from {
    opacity: 1;
    transform: translate(0, 50px);
    transform-origin: 0 0;
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInUpShorter {
  animation-name: fadeInUpShorter;
}
@keyframes fadeInLeftShorter {
  from {
    opacity: 1;
    transform: translate(50px, 0);
    transform-origin: 0 0;
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInLeftShorter {
  animation-name: fadeInLeftShorter;
}
@keyframes fadeInRightShorter {
  from {
    opacity: 1;
    transform: translate(-50px, 0);
    transform-origin: 0 0;
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInRightShorter {
  animation-name: fadeInRightShorter;
}
@keyframes fadeInDownShorter {
  from {
    opacity: 1;
    transform: translate(0, -50px);
    transform-origin: 0 0;
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.fadeInDownShorter {
  animation-name: fadeInDownShorter;
}
@keyframes blurIn {
  from {
    opacity: 1;
    filter: blur(20px);
    transform: scale(1.2);
  }
  to {
    opacity: 1;
    filter: blur(0);
    transform: none;
  }
}
.blurIn {
  animation-name: blurIn;
}
@keyframes grayOut {
  from {
    opacity: 1;
    filter: grayscale(0);
  }
  15% {
    filter: grayscale(100%);
  }
  to {
    opacity: 1;
    filter: grayscale(100%);
  }
}
.grayOut {
  animation-name: grayOut;
}
@keyframes dotPulse {
  from {
    opacity: 1;
    transform: scale(0.2);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.dotPulse {
  animation-name: dotPulse;
  animation-iteration-count: infinite;
  animation-duration: 4s;
}
@keyframes slideInUp {
  0% {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    transform: translateZ(0);
  }
}
@keyframes slideInDown {
  0% {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    transform: translateZ(0);
  }
}
@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translateZ(0);
  }
}
@keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translateZ(0);
  }
}
@keyframes flipInX {
  0% {
    animation-timing-function: ease-in;
    opacity: 1;
    transform: perspective(400px) rotateX(90deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes flipInY {
  0% {
    animation-timing-function: ease-in;
    opacity: 1;
    transform: perspective(400px) rotateY(90deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes flipOutY {
  0% {
    animation-timing-function: ease-out;
    transform: perspective(400px);
  }
  to {
    opacity: 1;
    transform: perspective(400px) rotateY(90deg);
  }
}
@keyframes brightIn {
  0% {
    animation-timing-function: ease-in;
    filter: brightness(0%);
  }
  to {
    filter: brightness(100%);
  }
}
.brightIn {
  animation-name: brightIn;
}
.appear-animate {
  visibility: hidden;
  opacity: 1;
}
.appear-animation-visible {
  visibility: visible;
  opacity: 1;
}
@-webkit-keyframes zoomInShorter {
  0% {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    opacity: 1;
    transform: scale3d(0.8, 0.8, 0.8);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomInShorter {
  0% {
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    opacity: 1;
    transform: scale3d(0.8, 0.8, 0.8);
  }
  50% {
    opacity: 1;
  }
}
.zoomInShorter {
  -webkit-animation-name: zoomInShorter;
  animation-name: zoomInShorter;
}
@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 1;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideZoomIn {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
  100% {
    transform: scale3d(1.1, 1.1, 1);
    opacity: 1;
  }
}
.slideZoomIn {
  -webkit-animation-name: slideZoomIn;
  animation-name: slideZoomIn;
}
.banner {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 1rem;
}
.banner figure img {
  display: block;
  width: 100%;
  height: auto;
}
.banner.inner-banner::before {
  content: "";
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  bottom: 1rem;
  border: 2px solid rgba(225, 225, 225, 0.3);
  z-index: 1;
}
.banner.inner-banner .banner-content {
  z-index: 2;
}
.banner .banner-content {
  position: relative;
}
.banner-subtitle {
  letter-spacing: -0.025em;
  line-height: 1;
}
.banner-title,
.banner-price-info {
  line-height: 1;
}
.banner-fixed > .container,
.banner-fixed > .container-fluid,
.banner-fixed > .banner-content {
  position: absolute;
  z-index: 1;
}
.banner-fixed > .container,
.banner-fixed > .container-fluid {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.banner-fixed.content-middle .banner-content {
  top: 50%;
  transform: translateY(-50%);
}
.banner-fixed.content-center .banner-content {
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}
.banner-fixed.content-center.content-middle .banner-content {
  transform: translate(-50%, -50%);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .intro-slider .banner img {
    height: 100%;
    width: 100%;
  }
}
.video-banner {
  position: relative;
}
.video-banner .btn-play {
  display: inline-block;
  line-height: 49px;
  width: 51px;
  height: 51px;
  font-size: 22px;
  border-radius: 50%;
  border: 1px solid;
  transition: background-color 0.3s, border-color 0.3s;
}
.video-banner .btn-play i {
  margin-left: 4px;
}
.video-banner .btn-play:hover {
  background-color: #00a567;
  border-color: #00a567;
}
.banner-button-hide .banner-content {
  transition: padding 0.3s;
}
.banner-button-hide .btn {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
  transition: 0.3s;
}
.banner-button-hide:not(:hover) .btn {
  margin: 0;
  visibility: hidden;
  opacity: 1;
}
.banner-button-hide:hover .banner-content {
  padding-bottom: 6.2rem;
}
.post .btn i {
  margin-left: 5px;
}
.post .btn i::before {
  margin: 0;
}
.post .post-details > *:last-child {
  margin-bottom: 0;
}
.post-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 4.5rem;
  height: 4.7rem;
  background: rgba(255, 255, 255, 0.8);
  color: #00a567;
  border: 2px solid;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  line-height: 1;
}
.post-calendar .post-day {
  display: block;
  margin-bottom: 1px;
  font-size: 1.6rem;
}
.post-calendar .post-month {
  display: block;
  margin-left: 2px;
  font-size: 1rem;
}
.post-media {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  overflow: hidden;
}
.post-media img {
  display: block;
  width: 100%;
  height: auto;
  transition: transform 0.3s;
}
.post-media .post-calendar {
  position: absolute;
  left: 2rem;
  top: 2rem;
}
.post-media .owl-dots {
  bottom: 2.5rem;
}
.post-media .owl-dots .owl-dot span {
  border-color: #fff;
  background-color: transparent;
}
.post-media .owl-dots .owl-dot:hover span {
  border-color: #fff;
  background-color: #fff;
}
.post-details {
  padding: 2.7rem 0 2.5rem;
}
.post-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.015em;
}
.post-content {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 1.6rem;
  line-height: 1.72;
  letter-spacing: 0;
  color: #666;
}
.post-meta,
.post-cats {
  margin-bottom: 0.9rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
  line-height: 1;
  color: #999;
}
.post-sm .post-details {
  padding: 2.2rem 0.3rem 2rem;
}
.post-video .video-play {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: color 0.3s, opacity 0.3s;
  font-size: 6rem;
  color: #fff;
  cursor: pointer;
  z-index: 10;
}
.post-video .video-play::before {
  content: "\f144";
}
.post-video .post-media {
  position: relative;
}
.post-video video {
  display: none;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.post-video.playing video,
.post-video.paused video {
  display: block;
}
.post-video.playing .video-play,
.post-video.paused .video-play {
  opacity: 1;
}
.post-video.playing .video-play:before,
.post-video.paused .video-play:before {
  content: "\f28b";
}
.post-video.playing .post-media,
.post-video.paused .post-media {
  background-color: #000;
}
.post-video.playing .post-media:hover .video-play,
.post-video.paused .post-media:hover .video-play {
  opacity: 1;
}
.post-video.playing img,
.post-video.paused img {
  visibility: hidden;
}
.post-video.paused .video-play {
  opacity: 1;
}
.post-video.paused .video-play:before {
  content: "\f144";
}
.post-list {
  margin-bottom: 2rem;
}
.post-list img {
  min-height: 20rem;
  object-fit: cover;
}
.post-list .post-details {
  padding: 2rem 0;
}
@media (min-width: 576px) {
  .post-list {
    display: flex;
    align-items: center;
  }
  .post-list .post-media {
    margin-right: 2rem;
  }
  .post-list .post-details,
  .post-list .post-media {
    width: calc(50% - 1rem);
  }
}
.post-list-xs {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-left: 2px;
}
.post-list-xs .post-calendar {
  color: #ccc;
  border-color: #666;
  background-color: transparent;
}
.post-list-xs .post-details {
  flex: 1;
  margin: 0 0 0 2rem;
  padding: 0;
}
.post-list-xs .post-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
  text-transform: none;
  font-size: inherit;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.69;
  color: inherit;
  white-space: normal;
}
.post-list-xs .post-title:hover a {
  color: #fff;
}
.post-list-sm {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.post-list-sm .post-media {
  max-width: 10rem;
  flex: 0 0 10rem;
  height: 10rem;
}
.post-list-sm .post-media img {
  height: 100%;
  object-fit: cover;
}
.post-list-sm .post-details {
  padding: 0;
  margin-left: 1.6rem;
}
.post-list-sm .post-meta {
  margin-bottom: 0.9rem;
  font-size: 1.3rem;
  text-transform: uppercase;
}
.post-list-sm .post-title {
  margin-bottom: 0;
  white-space: normal;
  text-transform: none;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.43;
}
.post-image-gap {
  padding: 2rem;
  background-color: #fff;
  transition: box-shadow 0.3s;
}
.post-image-gap .post-details {
  padding-bottom: 1rem;
}
.post-image-gap:hover {
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
}
.post-mask {
  position: relative;
}
.post-mask::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1.75;
  background: rgba(51, 51, 51, 0.5);
  transition: all 0.3s;
  pointer-events: none;
  z-index: 1;
}
.major-one-case {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.major-one-img-case {
  height: 570;
  width: 250;
  margin-left: 5px;
  margin-right: 5px;
}
.major-one-img:hover {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.22);
  transform: scale(1.02);
}
.major-one-img {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}
.parallax-bg {
  background-image: url(https://images.unsplash.com/photo-1596149615678-8488f200b301?ixid=MXwxMjA3fDF8MHxzZWFyY2h8MTZ8fGZhc2hpb258ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60);
}
.post-mask.gradient::before {
  height: 80%;
  background: linear-gradient(to bottom, rgba(125, 185, 232, 0) 0%, #000 100%);
}
.post-mask .post-details {
  position: absolute;
  padding: 0;
  left: 3rem;
  right: 3rem;
  bottom: 2.8rem;
  z-index: 2;
}
.post-mask .post-meta,
.post-mask .post-cats {
  color: #ebebeb;
}
.post-mask .post-meta a:hover,
.post-mask .post-cats a:hover {
  color: #fff;
}
.shadow1:hover {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
}

.post-mask .post-title {
  margin: 0;
  white-space: normal;
  text-transform: none;
  font-size: 1.8rem;
  line-height: 1.32;
  color: #fff;
}
.post-mask .post-title a:hover {
  color: #fff;
}
.post-mask .post-cats {
  color: #fff;
}
.post-mask .post-cats a:hover {
  color: #fff;
}
.post-mask:hover .post-media a::before {
  opacity: 1.1;
}
ul.blog-filters {
  text-transform: uppercase;
  font-weight: 600;
  color: #333;
}
ul.blog-filters li:not(:last-child) {
  margin-right: 4rem;
}
.blog-filters span {
  margin-left: 1rem;
  color: #ccc;
}
.blog-filters li {
  padding: 0;
  margin-right: 0.8rem;
  border-bottom: 2px solid transparent;
  line-height: 2.143;
}
.post-col .post {
  margin-bottom: 2rem;
}
/* .btn {
  display: inline-block;
  border: 2px solid #e4eaec;
  background-color: #e4eaec;
  color: #222;
  outline: 0;
  border-radius: 0;
  padding: 1em 2em;
  font-weight: 700;
  font-size: 1.4rem;
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.025em;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  transition: color 0.3s, border-color 0.3s, background-color 0.3s,
    box-shadow 0.3s;
  white-space: nowrap;
  cursor: pointer;
} */
/* .btn:hover,
.btn:active,
.btn:focus {
  color: #222;
  border-color: #cfdadd;
  background-color: #cfdadd;
} */
.btn i {
  display: inline-block;
  margin-left: 0.4rem;
  line-height: 0;
}
.btn i::before {
  margin: 0;
}
.btn-solid {
  background-color: #fff;
  border-color: #fff;
  color: #222;
}
.btn-solid:hover,
.btn-solid:active,
.btn-solid:focus {
  border-color: #222;
  background-color: #222;
  color: #fff;
}
.btn-outline {
  border: 2px solid #e4eaec;
  color: #666;
  background-color: transparent;
}
.btn-outline:hover,
.btn-outline:active,
.btn-outline:focus {
  border-color: #666;
  background-color: #666;
  color: #fff;
}
.btn-outline.btn-outline-light {
  padding: 1.07em 2.21em;
  border-width: 1px;
}
.btn-outline.btn-bg {
  color: #fff;
}
.btn-icon-left i,
.btn-icon-right i,
.btn-reveal-left i,
.btn-reveal-right i {
  line-height: 0;
}
.btn-icon-right {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-icon-right i {
  margin-left: 0.7rem;
}
.btn-icon-left {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn-icon-left i {
  margin-right: 0.7rem;
}
.btn-reveal-left i {
  opacity: 1;
  margin-left: -1em;
  transform: translateX(0.5em);
  margin-right: 0;
  transition: transform 0.3s, opacity 0.3s, margin 0.3s;
}
.btn-reveal-left:hover i,
.btn-reveal-left:active i,
.btn-reveal-left:focus i {
  opacity: 1;
  margin-left: 0;
  transform: translateX(0);
  margin-right: 0.7rem;
}
.btn-reveal-right i {
  opacity: 1;
  margin-right: -1em;
  transform: translateX(-0.5em);
  margin-left: 0;
  transition: transform 0.3s, opacity 0.3s, margin 0.3s;
}
.btn-reveal-right:hover i,
.btn-reveal-right:active i,
.btn-reveal-right:focus i {
  opacity: 1;
  margin-left: 0.7rem;
  transform: translateX(0);
  margin-right: 0;
}
.btn-slide-left:hover i,
.btn-slide-left:active i,
.btn-slide-left:focus i {
  animation: 0.5s ease slideLeft;
}
.btn-slide-right:hover i,
.btn-slide-right:active i,
.btn-slide-right:focus i {
  animation: 0.5s ease slideRight;
}
.btn-slide-up:hover i,
.btn-slide-up:active i,
.btn-slide-up:focus i {
  animation: 0.5s ease slideUp;
}
.btn-slide-down:hover i,
.btn-slide-down:active i,
.btn-slide-down:focus i {
  animation: 0.5s ease slideDown;
}
.btn-infinite:hover i {
  animation-iteration-count: infinite;
}
@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.5em);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.5em);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.5em);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.5em);
  }
  100% {
    transform: translateY(0);
  }
}
.btn-shadow-lg {
  box-shadow: 0 13px 20px -10px rgba(0, 0, 0, 0.15);
}
.btn-shadow-lg:hover,
.btn-shadow-lg:active,
.btn-shadow-lg:focus {
  box-shadow: 0 13px 20px -10px rgba(0, 0, 0, 0.2);
}
.btn-shadow {
  box-shadow: 0 15px 20px -15px rgba(0, 0, 0, 0.15);
}
.btn-shadow:hover,
.btn-shadow:active,
.btn-shadow:focus {
  box-shadow: 0 15px 20px -15px rgba(0, 0, 0, 0.2);
}
.btn-shadow-sm {
  box-shadow: 0 17px 20px -20px rgba(0, 0, 0, 0.15);
}
.btn-shadow-sm:hover,
.btn-shadow-sm:active,
.btn-shadow-sm:focus {
  box-shadow: 0 17px 20px -20px rgba(0, 0, 0, 0.2);
}
.btn-underline:hover::after,
.btn-underline:active::after,
.btn-underline:focus::after {
  transform: scaleX(1);
}
.btn-underline::after {
  display: block;
  margin-top: 1px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 2px;
  border: 1px solid;
  transform: scaleX(0);
  transition: transform 0.3s;
  content: "";
}
.btn-underline i {
  margin-bottom: 2px;
}
.btn-underline.btn-underline-visible::after {
  transform: scaleX(1);
}
.btn-underline.btn-underline-width-sm::after {
  height: 1px;
  border-bottom: 0;
}
.btn-underline.sm::after {
  width: 46%;
}
.btn-underline.lg::after {
  margin-left: -16.5%;
  margin-right: -16.5%;
  width: 133%;
}
.btn-link {
  padding: 0;
  color: #222;
  border: 0;
  background-color: transparent;
}
.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  background-color: transparent;
}
.btn-disabled {
  pointer-events: none;
  background-color: #e4eaec;
  color: #999;
  border-color: #e4eaec;
  box-shadow: none;
}
.btn-disabled::before,
.btn-disabled::after {
  content: none;
}
.btn.btn-rounded {
  border-radius: 2px;
}
.btn.btn-rounded::before,
.btn.btn-rounded::after {
  border-radius: 2px;
}
.btn.btn-ellipse {
  border-radius: 24px;
}
.btn.btn-ellipse::before,
.btn.btn-ellipse::after {
  border-radius: 24px;
}
.btn.btn-block {
  display: block;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.btn.btn-lg {
  padding: 1.3em 2.54em;
}
.btn.btn-md {
  padding: 0.84em 1.33em;
  font-size: 1.3rem;
  border-radius: 20px;
}
.btn.btn-sm {
  padding: 0.62em 1.22em;
  font-size: 1.2rem;
}
.btn.btn-link {
  padding: 0;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .btn.btn-link {
    overflow: visible;
  }
}
.btn-primary {
  color: #fff;
  border-color: #00a567;
  background-color: #00a567;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: #fff;
  border-color: #00a567;
  background-color: #00a567a3;
}
.btn-primary.btn-solid {
  color: #00a567;
  border-color: #fff;
  background-color: #fff;
}
.btn-primary.btn-solid:hover,
.btn-primary.btn-solid:active,
.btn-primary.btn-solid:focus {
  border-color: #00a567;
  background-color: #00a567;
  color: #fff;
}
.btn-primary.btn-outline {
  color: #00a567;
  border-color: #00a567;
  background-color: transparent;
}
.btn-primary.btn-outline:hover,
.btn-primary.btn-outline:active,
.btn-primary.btn-outline:focus {
  background-color: #00a567;
  color: #fff;
}
.btn-primary.btn-link {
  background-color: transparent;
  color: #00a567;
}
.btn-primary.btn-link:hover,
.btn-primary.btn-link:active,
.btn-primary.btn-link:focus {
  color: #222;
}
.btn-primary.btn-underline:hover,
.btn-primary.btn-underline:active,
.btn-primary.btn-underline:focus {
  color: #00a567;
}
.btn-secondary {
  color: #fff;
  border-color: #d26e4b;
  background-color: #d26e4b;
}
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
  color: #fff;
  border-color: #d98568;
  background-color: #d98568;
}
.btn-secondary.btn-solid {
  color: #d26e4b;
  border-color: #fff;
  background-color: #fff;
}
.btn-secondary.btn-solid:hover,
.btn-secondary.btn-solid:active,
.btn-secondary.btn-solid:focus {
  border-color: #d26e4b;
  background-color: #d26e4b;
  color: #fff;
}
.btn-secondary.btn-outline {
  color: #d26e4b;
  border-color: #d26e4b;
  background-color: transparent;
}
.btn-secondary.btn-outline:hover,
.btn-secondary.btn-outline:active,
.btn-secondary.btn-outline:focus {
  background-color: #d26e4b;
  color: #fff;
}
.btn-secondary.btn-link {
  background-color: transparent;
  color: #d26e4b;
}
.btn-secondary.btn-link:hover,
.btn-secondary.btn-link:active,
.btn-secondary.btn-link:focus {
  color: #222;
}
.btn-secondary.btn-underline:hover,
.btn-secondary.btn-underline:active,
.btn-secondary.btn-underline:focus {
  color: #d26e4b;
}
.btn-alert {
  color: #fff;
  border-color: #b10001;
  background-color: #b10001;
}
.btn-alert:hover,
.btn-alert:active,
.btn-alert:focus {
  color: #fff;
  border-color: #d50001;
  background-color: #d50001;
}
.btn-alert.btn-solid {
  color: #b10001;
  border-color: #fff;
  background-color: #fff;
}
.btn-alert.btn-solid:hover,
.btn-alert.btn-solid:active,
.btn-alert.btn-solid:focus {
  border-color: #b10001;
  background-color: #b10001;
  color: #fff;
}
.btn-alert.btn-outline {
  color: #b10001;
  border-color: #b10001;
  background-color: transparent;
}
.btn-alert.btn-outline:hover,
.btn-alert.btn-outline:active,
.btn-alert.btn-outline:focus {
  background-color: #b10001;
  color: #fff;
}
.btn-alert.btn-link {
  background-color: transparent;
  color: #b10001;
}
.btn-alert.btn-link:hover,
.btn-alert.btn-link:active,
.btn-alert.btn-link:focus {
  color: #222;
}
.btn-alert.btn-underline:hover,
.btn-alert.btn-underline:active,
.btn-alert.btn-underline:focus {
  color: #b10001;
}
.btn-success {
  color: #fff;
  border-color: #14af64;
  background-color: #14af64;
}
.btn-success:hover,
.btn-success:active,
.btn-success:focus {
  color: #fff;
  border-color: #b7cd87;
  background-color: #b7cd87;
}
.btn-success.btn-solid {
  color: #a8c26e;
  border-color: #fff;
  background-color: #fff;
}
.btn-success.btn-solid:hover,
.btn-success.btn-solid:active,
.btn-success.btn-solid:focus {
  border-color: #a8c26e;
  background-color: #a8c26e;
  color: #fff;
}
.btn-success.btn-outline {
  color: #a8c26e;
  border-color: #a8c26e;
  background-color: transparent;
}
.btn-success.btn-outline:hover,
.btn-success.btn-outline:active,
.btn-success.btn-outline:focus {
  background-color: #a8c26e;
  color: #fff;
}
.btn-success.btn-link {
  background-color: transparent;
  color: #a8c26e;
}
.btn-success.btn-link:hover,
.btn-success.btn-link:active,
.btn-success.btn-link:focus {
  color: #222;
}
.btn-success.btn-underline:hover,
.btn-success.btn-underline:active,
.btn-success.btn-underline:focus {
  color: #a8c26e;
}
.btn-dark {
  color: #fff;
  border-color: #222;
  background-color: #222;
}
.btn-dark:hover,
.btn-dark:active,
.btn-dark:focus {
  color: #fff;
  border-color: #343434;
  background-color: #343434;
}
.btn-dark.btn-solid {
  color: #222;
  border-color: #fff;
  background-color: #fff;
}
.btn-dark.btn-solid:hover,
.btn-dark.btn-solid:active,
.btn-dark.btn-solid:focus {
  border-color: #343a40;
  background-color: #343a40;
  color: #fff;
}
.btn-dark.btn-outline {
  color: #343a40;
  border-color: #343a40;
  background-color: transparent;
  border-radius: 50px;
}
.btn-dark.btn-outline:hover,
.btn-dark.btn-outline:active,
.btn-dark.btn-outline:focus {
  background-color: #222;
  color: #fff;
}
.btn-dark.btn-link {
  background-color: transparent;
  color: #222;
}
.btn-dark.btn-link:hover,
.btn-dark.btn-link:active,
.btn-dark.btn-link:focus {
  color: #222;
}
.btn-dark.btn-underline:hover,
.btn-dark.btn-underline:active,
.btn-dark.btn-underline:focus {
  color: #222;
}
.btn-white {
  color: #666;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover,
.btn-white:active,
.btn-white:focus {
  color: #666;
  border-color: #fff;
  background-color: #fff;
}
.btn-white.btn-outline {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}
.btn-white.btn-outline:hover,
.btn-white.btn-outline:active,
.btn-white.btn-outline:focus {
  color: #00a567;
  background-color: #fff;
}
.btn-white.btn-link {
  color: #fff;
  background-color: transparent;
}
.btn-white.btn-link:hover,
.btn-white.btn-link:active,
.btn-white.btn-link:focus {
  color: #fff;
  background-color: transparent;
}
.category {
  position: relative;
  font-size: 1rem;
}
.category img {
  display: block;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.category .category-name {
  margin-bottom: 0.3rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
  text-transform: uppercase;
}
.category .category-name a {
  color: inherit;
}
.category .category-name a:hover {
  text-decoration: underline;
}
.category .category-count {
  font-size: 1.2rem;
  line-height: 1.2;
}
.category-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: background 0.3s;
}
.category-absolute .category-content {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  min-height: 5rem;
}
.category-default {
  color: #fff;
}
.category-default:hover .category-content {
  background-color: rgba(34, 102, 187, 0.8);
}
.category-default .category-count {
  display: block;
  opacity: 1;
  line-height: 0;
  visibility: hidden;
  transition: opacity 0.3s, line-height 0.3s, visibility 0.3s;
}
.category-default:hover .category-name {
  margin-bottom: 0.3rem;
}
.category-default:hover .category-count {
  visibility: visible;
  line-height: 1.2;
  opacity: 1;
}
.category-default .category-content {
  background-color: rgba(38, 38, 38, 0.8);
}
.category-light {
  color: #999;
}
.category-light:hover .category-content {
  background-color: rgba(34, 102, 187, 0.8);
}
.category-light .btn {
  position: absolute;
  opacity: 1;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.3rem;
  font-weight: 600;
  transition: opacity 0.3s, bottom 0.3s, transform 0.3s;
}
.category-light .category-name {
  color: #222;
  transition: color 0.3s;
}
.category-light .category-count {
  text-transform: uppercase;
  transition: color 0.3s, opacity 0.3s;
}
.category-light .category-content {
  bottom: 2rem;
  height: 27.7%;
  background-color: rgba(255, 255, 255, 0.8);
}
.category-light .category-content:hover .btn {
  bottom: calc(50% - 5px);
  transform: translate(-50%, 100%);
  opacity: 1;
}
.category-light .category-content:hover .category-count {
  opacity: 1;
}
.category-light:hover {
  color: #fff;
}
.category-light:hover .category-name {
  color: #fff;
}
.category-icon {
  padding: 2.4rem 0.5rem;
  border: 2px solid #e1e1e1;
  color: #222;
  text-align: center;
  transition: border 0.3s;
}
.category-icon i {
  margin-bottom: 1.5rem;
  font-size: 3.6rem;
  color: #666;
  transition: transform 0.3s;
}
.category-icon .category-name {
  margin: 0;
  color: #222;
}
.category-icon:hover {
  border-color: #00a567;
}
.category-icon:hover i {
  color: #00a567;
  transform: translateY(-4px);
}
.category-icon:hover .category-content {
  background: transparent;
}
.category-icon-inline {
  display: inline-flex;
  align-items: center;
}
.category-icon-inline .category-media {
  font-size: 3.2rem;
  line-height: 1;
  color: #444;
  transition: color 0.3s;
}
.category-icon-inline .category-media i {
  margin-right: 0.8rem;
}
.category-icon-inline .category-media svg {
  display: block;
  margin-right: 1rem;
  width: 3.5rem;
  height: 3.9rem;
  stroke: #444;
  fill: #444;
  transition: stroke 0.3s;
}
.category-icon-inline .category-name {
  margin: 0;
  text-transform: none;
  text-align: left;
  font-size: 1.3rem;
  font-weight: inherit;
  font-family: inherit;
  line-height: 1.08;
  color: inherit;
  transition: color 0.3s;
}
.category-icon-inline:hover .category-media,
.category-icon-inline:hover .category-name {
  color: #00a567;
}
.category-icon-inline:hover svg {
  stroke: #00a567;
  fill: #00a567;
}
.category-icon-inline:hover i,
.category-icon-inline:hover svg {
  animation: slideUpShorter 0.6s;
}
@keyframes slideUpShorter {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0);
  }
}
.category-ellipse .category-media {
  border-radius: 50%;
  overflow: hidden;
}
.category-ellipse .category-content {
  background-color: #fff;
}
.category-ellipse .category-name {
  letter-spacing: -0.025em;
  color: #222;
}
.category-ellipse .category-name:hover {
  color: #00a567;
}
.category-ellipse .category-count {
  opacity: 1;
  color: #999;
  transition: opacity 0.3s;
}
.category-ellipse:hover .category-count {
  opacity: 1;
}
.category-group-image,
.category-group-icon {
  display: flex;
  align-items: center;
  border: 1px solid #e1e1e1;
}
.category-group-image > *,
.category-group-icon > * {
  flex: 1;
}
.category-group-image {
  color: #666;
}
.category-group-image .category-content {
  position: static;
  padding: 2rem 0;
  background: transparent;
  text-align: left;
  align-items: flex-start;
}
.category-group-image .category-name {
  margin-bottom: 1.5rem;
  text-transform: none;
  font-size: 1.6rem;
  font-weight: 400;
  color: #222;
}
.category-group-image .category-list {
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
  line-height: 1.2;
}
.category-group-image .category-list li:not(:last-child) {
  margin-bottom: 0.5rem;
}
.category-group-image .category-list a {
  position: relative;
  display: inline-block;
  transition: text-decoration 0.3s;
}
.category-group-image .category-list a:hover {
  color: #222;
  text-decoration: underline;
}
.category-group-image .category-list a:active {
  color: #00a567;
}
.category-group-image:hover .category-name {
  margin-bottom: 1.5rem;
}
.category-group-image:hover .category-content {
  background: transparent;
}
.category-group-icon {
  display: flex;
  align-items: center;
  border: 0;
  color: #fff;
}
.category-group-icon > * {
  flex: 1;
}
.category-group-icon .category-media {
  text-align: center;
}
.category-group-icon .category-media i {
  display: inline-block;
  font-size: 5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.8rem;
  line-height: 1;
  transition: transform 0.3s;
  color: #fff;
}
.category-group-icon > a {
  padding-right: 1rem;
}
.category-group-icon > a:hover i {
  transform: translateY(-0.5rem);
}
.category-group-icon .category-content {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background: transparent;
  text-align: left;
  align-items: flex-start;
}
.category-group-icon .category-name {
  margin-bottom: 0;
  text-align: center;
  letter-spacing: -0.025em;
  color: #fff;
}
.category-group-icon .category-list {
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
  line-height: 1.2;
}
.category-group-icon .category-list li {
  white-space: nowrap;
}
.category-group-icon .category-list li::before {
  content: "\f105";
  padding-right: 0.8rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: #999;
}
.category-group-icon .category-list li:not(:last-child) {
  margin-bottom: 0.5rem;
}
.category-group-icon .category-list a {
  position: relative;
  display: inline-block;
  transition: text-decoration 0.3s;
}
.category-group-icon .category-list a:hover {
  color: inherit;
  text-decoration: underline;
}
.category-group-icon:hover .category-name {
  margin-bottom: 0;
}
.category-group-icon:hover .category-content {
  background: transparent;
}
.category-banner {
  overflow: hidden;
  color: #222;
}
.category-banner.text-white {
  color: #fff;
}
.category-banner.text-white .btn {
  color: #fff;
}
.category-banner .category-content {
  top: 4.5rem;
  left: 5rem;
  bottom: auto;
  width: auto;
  height: auto;
  align-items: flex-start;
  z-index: 1;
  background-color: transparent;
  transition: top 0.3s, padding 0.3s;
}
.category-banner .category-name {
  text-transform: none;
  text-align: left;
  font-size: 2rem;
  font-family: inherit;
  letter-spacing: -0.025em;
}
.category-banner .category-count {
  visibility: visible;
  opacity: 1;
  font-size: 1.4rem;
  line-height: 1.2;
  transition: color 0.3s;
  z-index: 1;
}
.category-banner .btn {
  position: absolute;
  opacity: 1;
  bottom: 0;
  left: 0;
  transition: transform 0.3s, opacity 0.3s;
}
.category-banner:hover .category-content {
  top: 3rem;
  padding-bottom: 3rem;
  background-color: transparent;
}
.category-banner:hover .btn {
  opacity: 1;
}
.category-badge .category-name {
  margin: 0;
  padding: 1.8rem 0;
  color: #222;
}
.category-badge .btn {
  position: absolute;
  padding: 1.28em 0;
  top: 100%;
  left: 0;
  opacity: 1;
  height: 100%;
  transition: top 0.3s, opacity 0.3s;
}
.category-badge .category-content {
  left: 2rem;
  top: 2rem;
  bottom: auto;
  width: 19.6rem;
  height: auto;
  z-index: 1;
  overflow: hidden;
  background-color: #fff;
}
.category-badge .category-content:hover .btn {
  top: 0;
  opacity: 1;
}
.category-overlay .category-content {
  align-items: center;
  width: 100%;
}
.category-overlay .category-count {
  padding: 0;
  line-height: 0;
  opacity: 1;
  transform: translateY(-1rem);
  transition: transform 0.3s, opacity 0.3s, line-height 0.3s, padding 0.3s;
}
.category-overlay .category-name {
  margin: 0;
}
.category-overlay:hover .category-content {
  padding: 0;
  top: 50%;
}
.category-overlay:hover .category-count {
  padding-top: 1rem;
  transform: translateY(0);
  opacity: 1;
}
.category-block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10.7rem;
  padding: 1.5rem;
  color: #fff;
  background-color: #333;
  transition: background-color 0.3s;
}
.category-block .category-name {
  margin: 0;
  text-align: center;
  font-weight: 400;
  line-height: 1.7rem;
  text-transform: none;
}
.category-block:hover {
  background-color: #00a567;
}
.category-block:hover .category-name {
  color: #fff;
}
.comments {
  padding-top: 4.3rem;
  border-top: 1px solid #ebebeb;
}
.comments ul ul {
  margin-top: 4rem;
  margin-left: 8rem;
  padding-top: 4.5rem;
  border-top: 0.1rem solid #ebebeb;
}
.comments li {
  border-bottom: 0.1rem solid #ebebeb;
  padding-bottom: 3.5rem;
  margin-bottom: 4rem;
}
.comments li:last-child {
  border-bottom-width: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (max-width: 575px) {
  .comments ul ul {
    margin-left: 3rem;
  }
}
.comment {
  position: relative;
  display: flex;
  align-items: flex-start;
}
.comment p:last-child {
  margin-bottom: 0;
}
.comment-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.9rem 0 0 3rem;
}
.comment-reply {
  color: #777;
  position: absolute;
  right: 0;
  top: 1.7rem;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.01em;
  padding-bottom: 0.5rem;
  transition: color 0.3s, box-shadow 0.3s;
}
.comment-reply:hover,
.comment-reply:focus {
  color: #00a567;
  box-shadow: 0 2px 0 #00a567;
}
.comment-media {
  max-width: 8rem;
  flex: 0 0 8rem;
  height: 8rem;
  border-radius: 50%;
  overflow: hidden;
}
.comment-media img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.comment-user {
  margin-bottom: 1rem;
}
.comment-user h4 {
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: -0.01em;
  margin-bottom: 0;
}
.comment-user h4 a:hover,
.comment-user h4 a:focus {
  color: #00a567;
}
.comment-user h4 span {
  display: block;
}
.comment-date {
  color: #ccc;
  font-size: 1.3rem;
  line-height: 1;
}
.reply {
  margin-bottom: 7rem;
  background-color: #f2f3f5;
  padding: 4.3rem 3rem 5.4rem;
}
.reply .form-control {
  max-width: 100%;
  color: #999;
  border-color: #ebebeb;
  background: #fff;
}
.reply p {
  font-size: 1.3rem;
}
.count-to {
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1;
}
.count-to::after {
  content: "+";
}
.count-title {
  color: inherit;
  font-weight: 400;
  letter-spacing: -0.025em;
}
.elements > div {
  margin-bottom: 2rem;
}
.elements > div > a {
  display: block;
}
.elements > div .element-accordian .element-img {
  background-position: calc(-180px * (1 - 1)) 0;
}
.elements > div .element-accordian .element-hover-img {
  background-position: calc(-180px * 1 + 90px) 0;
}
.elements > div .element-banner .element-img {
  background-position: calc(-180px * (2 - 1)) 0;
}
.elements > div .element-banner .element-hover-img {
  background-position: calc(-180px * 2 + 90px) 0;
}
.elements > div .element-blog .element-img {
  background-position: calc(-180px * (3 - 1)) 0;
}
.elements > div .element-blog .element-hover-img {
  background-position: calc(-180px * 3 + 90px) 0;
}
.elements > div .element-button .element-img {
  background-position: calc(-180px * (4 - 1)) 0;
}
.elements > div .element-button .element-hover-img {
  background-position: calc(-180px * 4 + 90px) 0;
}
.elements > div .element-cta .element-img {
  background-position: calc(-180px * (5 - 1)) 0;
}
.elements > div .element-cta .element-hover-img {
  background-position: calc(-180px * 5 + 90px) 0;
}
.elements > div .element-icon-box .element-img {
  background-position: calc(-180px * (6 - 1)) 0;
}
.elements > div .element-icon-box .element-hover-img {
  background-position: calc(-180px * 6 + 90px) 0;
}
.elements > div .element-portfolio .element-img {
  background-position: calc(-180px * (7 - 1)) 0;
}
.elements > div .element-portfolio .element-hover-img {
  background-position: calc(-180px * 7 + 90px) 0;
}
.elements > div .element-category .element-img {
  background-position: calc(-180px * (8 - 1)) 0;
}
.elements > div .element-category .element-hover-img {
  background-position: calc(-180px * 8 + 90px) 0;
}
.elements > div .element-product .element-img {
  background-position: calc(-180px * (9 - 1)) 0;
}
.elements > div .element-product .element-hover-img {
  background-position: calc(-180px * 9 + 90px) 0;
}
.elements > div .element-tab .element-img {
  background-position: calc(-180px * (10 - 1)) 0;
}
.elements > div .element-tab .element-hover-img {
  background-position: calc(-180px * 10 + 90px) 0;
}
.elements > div .element-testimonial .element-img {
  background-position: calc(-180px * (11 - 1)) 0;
}
.elements > div .element-testimonial .element-hover-img {
  background-position: calc(-180px * 11 + 90px) 0;
}
.elements > div .element-title .element-img {
  background-position: calc(-180px * (12 - 1)) 0;
}
.elements > div .element-title .element-hover-img {
  background-position: calc(-180px * 12 + 90px) 0;
}
.elements > div .element-typography .element-img {
  background-position: calc(-180px * (13 - 1)) 0;
}
.elements > div .element-typography .element-hover-img {
  background-position: calc(-180px * 13 + 90px) 0;
}
.elements > div .element-video .element-img {
  background-position: calc(-180px * (14 - 1)) 0;
}
.elements > div .element-video .element-hover-img {
  background-position: calc(-180px * 14 + 90px) 0;
}
.elements > div .element-icon .element-img {
  background-position: calc(-180px * (15 - 1)) 0;
}
.elements > div .element-icon .element-hover-img {
  background-position: calc(-180px * 15 + 90px) 0;
}
.element {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0 2.4rem;
  border: 2px solid #e4eaec;
}
.element .element-img {
  width: 90px;
  height: 70px;
  background-image: url(../images/elements.png);
  background-repeat: no-repeat;
  transition: opacity 0.3s, visibility 0.3s;
}
.element .element-hover-img {
  position: absolute;
  width: 90px;
  height: 70px;
  left: 50%;
  top: 1.2rem;
  background-image: url(../images/elements.png);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 1;
  transition: opacity 0.3s, visibility 0.3s;
}
.element p {
  margin: 0;
  text-align: center;
  color: #222;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
}
.element:hover .element-img,
.element.active .element-img {
  opacity: 1;
  visibility: hidden;
}
.element:hover .element-hover-img,
.element.active .element-hover-img {
  opacity: 1;
  visibility: visible;
}

@font-face {
  font-family: "proxima";
  src: url("../fonts/proxima-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "proximaS";
  src: url("../fonts/proxima-SemiBold.ttf") format("opentype");
}
@font-face {
  font-family: "proximaB";
  src: url("../fonts/proxima-Bold.otf") format("opentype");
}
@font-face {
  font-family: "sofia";
  src: url("../fonts/SofiaPro-Medium.ttf") format("opentype");
}
@font-face {
  font-family: "sanfrancisco";
  src: url("../fonts/SanFrancisco.ttf") format("opentype");
}
[class^="d-icon-"],
[class*=" d-icon-"] {
  display: inline-block;
  line-height: 1;
}
[class^="d-icon-"]::before,
[class*=" d-icon-"]::before {
  font-family: "donald";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.d-icon-truck:before {
  content: "\e959";
}
.d-icon-service:before {
  content: "\e95a";
}
.d-icon-secure:before {
  content: "\e95b";
}
.d-icon-lock:before {
  content: "\e95c";
}
.d-icon-percent:before {
  content: "\e95d";
}
.d-icon-layer:before {
  content: "\e95e";
}
.d-icon-alert:before {
  content: "\e95f";
}
.d-icon-database:before {
  content: "\e960";
}
.d-icon-money:before {
  content: "\e961";
}
.d-icon-shoppingbag:before {
  content: "\e962";
}
.d-icon-t-shirt1:before {
  content: "\e900";
}
.d-icon-t-shirt2:before {
  content: "\e901";
}
.d-icon-hat:before {
  content: "\e902";
}
.d-icon-officebag:before {
  content: "\e903";
}
.d-icon-hadbag:before {
  content: "\e904";
}
.d-icon-backpack:before {
  content: "\e905";
}
.d-icon-shoes:before {
  content: "\e906";
}
.d-icon-pillow:before {
  content: "\e907";
}
.d-icon-dress:before {
  content: "\e908";
}
.d-icon-loungewear:before {
  content: "\e909";
}
.d-icon-desktop:before {
  content: "\e90a";
}
.d-icon-laptop:before {
  content: "\e90b";
}
.d-icon-gamepad1:before {
  content: "\e90c";
}
.d-icon-wireless:before {
  content: "\e90d";
}
.d-icon-projector:before {
  content: "\e90e";
}
.d-icon-drone1:before {
  content: "\e90f";
}
.d-icon-drone2:before {
  content: "\e910";
}
.d-icon-radio:before {
  content: "\e911";
}
.d-icon-battery:before {
  content: "\e912";
}
.d-icon-plugin:before {
  content: "\e913";
}
.d-icon-memory:before {
  content: "\e914";
}
.d-icon-ball:before {
  content: "\e915";
}
.d-icon-basketball1:before {
  content: "\e916";
}
.d-icon-babycare:before {
  content: "\e917";
}
.d-icon-card:before {
  content: "\e918";
}
.d-icon-gamepad2:before {
  content: "\e919";
}
.d-icon-camera1:before {
  content: "\e91a";
}
.d-icon-camera2:before {
  content: "\e91b";
}
.d-icon-babywear:before {
  content: "\e91c";
}
.d-icon-abacus:before {
  content: "\e91d";
}
.d-icon-pot:before {
  content: "\e91e";
}
.d-icon-freezer:before {
  content: "\e91f";
}
.d-icon-cook:before {
  content: "\e920";
}
.d-icon-student:before {
  content: "\e921";
}
.d-icon-category:before {
  content: "\e922";
}
.d-icon-basketball2:before {
  content: "\e923";
}
.d-icon-watch:before {
  content: "\e924";
}
.d-icon-tcard:before {
  content: "\e925";
}
.d-icon-heartbeat:before {
  content: "\e926";
}
.d-icon-watch-round:before {
  content: "\e927";
}
.d-icon-washbowl:before {
  content: "\e928";
}
.d-icon-bridge-lamp:before {
  content: "\e929";
}
.d-icon-lamp:before {
  content: "\e92a";
}
.d-icon-sofa:before {
  content: "\e92b";
}
.d-icon-sofa2:before {
  content: "\e92c";
}
.d-icon-bed:before {
  content: "\e92d";
}
.d-icon-table:before {
  content: "\e92e";
}
.d-icon-table-lamp:before {
  content: "\e92f";
}
.d-icon-table-tv:before {
  content: "\e930";
}
.d-icon-mirror:before {
  content: "\e931";
}
.d-icon-volume:before {
  content: "\e932";
}
.d-icon-bars2:before {
  content: "\e933";
}
.d-icon-bars:before {
  content: "\e934";
}
.d-icon-phone:before {
  content: "\e935";
}
.d-icon-user:before {
  content: "\e936";
}
.d-icon-search:before {
  content: "\e937";
}
.d-icon-bag:before {
  content: "\e938";
}
.d-icon-map:before {
  content: "\e939";
}
.d-icon-info:before {
  content: "\e93a";
}
.d-icon-refresh:before {
  content: "\e93b";
}
.d-icon-ruler:before {
  content: "\e940";
}
.d-icon-zoom:before {
  content: "\e941";
}
.d-icon-star:before {
  content: "\e94a";
}
.d-icon-star-full:before {
  content: "\e94b";
}
.d-icon-heart:before {
  content: "\e94c";
}
.d-icon-heart-full:before {
  content: "\e94d";
}
.d-icon-close:before {
  content: "\e94e";
}
.d-icon-play-circle:before {
  content: "\e94f";
}
.d-icon-home:before {
  content: "\e950";
}
.d-icon-filter-2:before {
  content: "\e951";
}
.d-icon-switch:before {
  content: "\e952";
}
.d-icon-switch-left-on:before {
  content: "\e953";
}
.d-icon-switch-right-on:before {
  content: "\e954";
}
.d-icon-filter-3:before {
  content: "\e955";
}
.d-icon-alert-solid:before {
  content: "\e957";
}
.d-icon-headphone:before {
  content: "\e958";
}
.d-icon-mode-grid:before {
  content: "\e80e";
}
.d-icon-mode-list:before {
  content: "\e80f";
}
.d-icon-angle-left:before {
  content: "\e819";
}
.d-icon-angle-right:before {
  content: "\e81a";
}
.d-icon-cancel:before {
  content: "\e82c";
}
.d-icon-instagram:before {
  content: "\e963";
}
.d-icon-check:before {
  content: "\e964";
}
.d-icon-play-solid:before {
  content: "\e965";
}
.d-icon-angle-up:before {
  content: "\e967";
}
.d-icon-angle-down:before {
  content: "\e968";
}
.d-icon-lightbulb:before {
  content: "\e96a";
}
.d-icon-mobile:before {
  content: "\e96b";
}
.d-icon-minus:before {
  content: "\e96c";
}
.d-icon-plus:before {
  content: "\e96d";
}
.d-icon-wifi:before {
  content: "\e96e";
}
.d-icon-times:before {
  content: "\e96f";
}
.d-icon-times-circle:before {
  content: "\e970";
}
.d-icon-random:before {
  content: "\e971";
}
.d-icon-th-list:before {
  content: "\e972";
}
.d-icon-rocket:before {
  content: "\e973";
}
.d-icon-map-marker:before {
  content: "\e974";
}
.d-icon-birthday-cake:before {
  content: "\e975";
}
.d-icon-gift:before {
  content: "\e976";
}
.d-icon-female:before {
  content: "\e977";
}
.d-icon-clock:before {
  content: "\e978";
}
.d-icon-comments:before {
  content: "\e97b";
}
.d-icon-arrow-down:before {
  content: "\e97c";
}
.d-icon-arrow-left:before {
  content: "\e97d";
}
.d-icon-arrow-right:before {
  content: "\e97e";
}
.d-icon-arrow-up:before {
  content: "\e97f";
}
.d-icon-info-circle:before {
  content: "\e980";
}
.d-icon-rotate-left:before {
  content: "\f2e0";
}
.d-icon-rotate-right:before {
  content: "\f2e1";
}
.d-d-icon-mode-grid::before,
.d-d-icon-mode-list::before,
.d-icon-cancel::before,
.d-icon-instagram::before,
.d-icon-check::before,
.d-icon-play-solid::before,
.d-icon-angle-right::before,
.d-icon-angle-up::before,
.d-icon-angle-down::before,
.d-icon-angle-left::before,
.d-icon-lightbulb::before,
.d-icon-mobile::before,
.d-icon-minus::before,
.d-icon-plus::before,
.d-icon-wifi::before,
.d-icon-times::before,
.d-icon-times-circle::before,
.d-icon-random::before,
.d-icon-th-list::before,
.d-icon-rocket::before,
.d-icon-map-marker::before,
.d-icon-birthday-cake::before,
.d-icon-gift::before,
.d-icon-female::before,
.d-icon-clock::before,
.d-icon-comments::before,
.d-icon-arrow-down::before,
.d-icon-arrow-left::before,
.d-icon-arrow-right::before,
.d-icon-arrow-up::before,
.d-icon-info-circle::before,
.d-icon-rotate-left::before,
.d-icon-rotate-right::before {
  font-size: 0.8em;
}
.d-icon-arrow-down::before,
.d-icon-arrow-left::before,
.d-icon-arrow-right::before,
.d-icon-arrow-up::before {
  position: relative;
  top: -1px;
}
.form .form-control {
  margin-bottom: 2rem;
}
.form-control {
  display: block;
  width: 100%;
  min-height: 4rem;
  padding: 0.85rem 2rem;
  border: 1px solid #e3e3e3;
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 400;
  color: #666;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  outline: 0;
}
.form-control::placeholder {
  color: inherit;
  transition: color 0.3s;
}
.form-control::-webkit-input-placeholder {
  color: inherit;
  transition: color 0.3s;
}
.form-control::-moz-placeholder {
  color: inherit;
  transition: color 0.3s;
}
.form-control:focus::placeholder {
  color: transparent;
}
.form-control:focus::-webkit-input-placeholder {
  color: transparent;
}
.form-control:focus::-moz-placeholder {
  color: transparent;
}
.form-control.form-solid {
  background-color: #fff;
  border: 0;
  color: #8d8d8d;
}
textarea {
  font-family: inherit;
  min-width: 100%;
  max-width: 100%;
}
.custom-checkbox {
  position: absolute;
  opacity: 1;
}
.custom-checkbox + label {
  position: relative;
  padding-left: 2.4rem;
  cursor: pointer;
}
.custom-checkbox + label::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 18px;
  height: 18px;
  line-height: 17px;
  border: 1px solid #ccc;
  border-radius: 2px;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .custom-checkbox + label::before {
    line-height: 14px;
  }
}
.custom-checkbox:checked + label::before {
  content: "\f00c";
  border-color: #222;
  background: #222;
  color: #fff;
  font-size: 9px;
  font-weight: 600;
  text-align: center;
}
.select-box,
.select-menu {
  position: relative;
}
.select-box select,
.select-menu select {
  position: relative;
  max-width: 14rem;
  min-height: auto;
  width: auto;
  height: 100%;
  padding-left: 9px;
  padding-right: 27px;
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  color: inherit;
  background-color: transparent;
  font-size: 1.2rem;
  font-family: inherit;
  letter-spacing: inherit;
  z-index: 1;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.select-box select:focus,
.select-menu select:focus {
  outline: none;
}
.select-box::before,
.select-menu::before {
  content: "\f078";
  position: absolute;
  font-weight: 900;
  font-size: 9px;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.select-box option {
  font-size: 1.4rem;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  select::-ms-expand {
    display: none;
  }
  select:focus::-ms-value {
    background: transparent;
    color: currentColor;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.quantity {
  -moz-appearance: textfield;
  max-width: 48px;
}
.input-group {
  display: inline-flex;
  width: 10.4rem;
  height: 4.5rem;
}
.input-group .form-control {
  flex: 1;
  padding: 0;
  height: inherit;
  font-size: 1.4rem;
  border: solid #ccc;
  border-width: 1px 0;
  color: #222;
  text-align: center;
}
.input-group button {
  padding: 0;
  width: 2.8rem;
  border: 1px solid #ccc;
  font-size: 1.2rem;
  color: #222;
  background-color: transparent;
  cursor: pointer;
  -webkit-appearance: none;
}
.custom-radio {
  position: relative;
}
.custom-radio input {
  display: none;
}
input[type="radio"] ~ label {
  padding-left: 2.5rem;
}
input[type="radio"] ~ label::before,
input[type="radio"] ~ label::after {
  position: absolute;
  content: "";
  display: inline-block;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
input[type="radio"] ~ label::before {
  left: 0;
  width: 1.8rem;
  height: 1.8rem;
  background: #fff;
  border: 1px solid #cdcdcd;
}
input[type="radio"] ~ label::after {
  width: 0.8rem;
  height: 0.8rem;
  left: 0.5rem;
  background: #666;
  opacity: 1;
}
input[type="radio"]:checked ~ label::after {
  opacity: 1;
}
.input-wrapper input.form-control {
  flex: 1;
  min-width: 40px;
  font-size: 1.2rem;
}
.input-wrapper-inline {
  display: flex;
  position: relative;
  max-width: 61.3rem;
  width: 100%;
  height: 40px;
}
.input-wrapper-inline .btn {
  line-height: 1;
}
.input-wrapper-round .form-control {
  position: relative;
  border-radius: 2.4rem;
}
.input-wrapper-round .btn {
  border-radius: 2.4rem;
  line-height: 1;
}
.input-wrapper-round.input-wrapper-inline .form-control {
  border-radius: 2.4rem 0 0 2.4rem;
}
.input-wrapper-round.input-wrapper-inline .btn {
  border-radius: 0 2.4rem 2.4rem 0;
}
.form-wrapper.form-wrapper-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-wrapper.form-wrapper-inline form {
  flex: 1 1 0;
}
@media (max-width: 991px) {
  .form-wrapper.form-wrapper-inline {
    display: block;
    text-align: center;
  }
  .form-wrapper.form-wrapper-inline form {
    margin-left: auto;
    margin-right: auto;
  }
}
.btn-absolute input.form-control {
  width: 100%;
  padding-left: 1.2rem;
  color: #999;
  font-size: 1.3rem;
}
.btn-absolute .btn {
  position: absolute;
  width: auto;
  height: 100%;
  min-width: 4.4rem;
  right: 0;
  font-size: 1.3rem;
}
.btn-absolute .btn:hover {
  color: #00a567;
}
.icon-box .icon-box-title {
  margin: 0 0 0.7rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: -0.025em;
  line-height: 1.6;
}
.icon-box p {
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.72;
  letter-spacing: 0;
  color: #666;
}
.icon-box i::before {
  margin: 0;
}
.icon-box .icon-box-content {
  text-align: center;
}
.icon-box-icon {
  display: inline-block;
  margin-bottom: 2.4rem;
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 0;
  color: #222;
}
.icon-box-icon svg {
  height: 3.4rem;
  width: 4.8rem;
}
.icon-box-side {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-box-side .icon-box-content {
  text-align: left;
}
.icon-box-side .icon-box-icon {
  margin-right: 1.5rem;
  margin-bottom: 0;
}
.icon-box-side .icon-box-title {
  margin: 0;
}
.icon-border .icon-box-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 5.1rem;
  height: 5.1rem;
  border-radius: 50%;
  border: 1px solid #9e9e9e;
  font-size: 2.4rem;
}
.icon-border svg {
  height: 2.4rem;
}
.icon-inversed .icon-box-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 5.1rem;
  height: 5.1rem;
  border-radius: 50%;
  background-color: #00a567;
  color: #fff;
  font-size: 2.4rem;
}
.icon-inversed svg {
  height: 2.4rem;
  fill: white;
}
.icon-solid .icon-box-icon {
  font-size: 4.8rem;
  color: #00a567;
}
.icon-solid svg {
  height: 6rem;
  fill: #00a567;
}
.icon-solid.icon-box-side {
  align-items: flex-start;
}
.icon-box-tiny .icon-box-icon {
  margin: 0 1rem 0 0;
  font-size: 2.4rem;
}
.icon-box-tiny svg {
  height: 2.8rem;
  width: 2.8rem;
}
.icon-box-tiny .icon-box-title {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-icon {
  display: inline-block;
  color: #fff;
  width: 1.7rem;
}
.menu-icon::before,
.menu-icon::after {
  display: block;
  content: "";
  width: 100%;
}
.menu-icon::before {
  height: 6px;
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.menu-icon::after {
  height: 4px;
  border-bottom: 2px solid;
}
.minicart-icon {
  display: inline-block;
  position: relative;
  width: 2.5em;
  height: 2.5em;
  margin-top: 1px;
  border: solid 2px;
  border-radius: 2px;
  text-align: center;
  transition: background 0.4s, border-color 0.4s;
}
.minicart-icon::before {
  content: "";
  position: absolute;
  top: -0.75em;
  left: 0.585em;
  width: 1em;
  height: 0.5em;
  border-radius: 10px 10px 0 0;
  border: inherit;
  border-bottom: none;
  transition: transform 0.4s;
}
.instagram {
  position: relative;
  overflow: hidden;
}
.instagram a {
  display: block;
  position: relative;
  height: 100%;
}
.instagram a::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  opacity: 1;
  transition: opacity 0.3s;
  z-index: 1;
}
.instagram a::after {
  content: "\e963";
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-family: "Donald";
  color: #fff;
  opacity: 1;
  transition: opacity 0.3s;
  z-index: 1;
}
.instagram img {
  display: block;
  transition: transform 0.3s;
  width: 100%;
  height: auto;
}
.instagram:hover a::before {
  opacity: 1.5;
}
.instagram:hover a::after {
  opacity: 1;
}
.instagram:hover img {
  transform: scale(1.15);
}
.instagram-info .instagram-content {
  position: absolute;
  left: 3rem;
  bottom: 3rem;
  font-size: 1.4rem;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0;
  color: #fff;
  opacity: 1;
  z-index: 2;
  transition: opacity 0.3s;
}
.instagram-info .instagram-content i {
  display: inline-block;
  margin-right: 0.8rem;
  vertical-align: middle;
  font-size: 1.6rem;
}
.instagram-info .instagram-content .d-icon-comments::before {
  font-size: 1em;
}
.instagram-info .instagram-content a {
  display: inline-block;
  color: #fff;
}
.instagram-info .instagram-content a:not(:last-child) {
  margin-right: 2rem;
}
.instagram-info .instagram-content a::before {
  content: none;
}
.instagram-info a::after {
  content: none;
}
.instagram-info:hover .instagram-content {
  opacity: 1;
}
.member {
  text-align: center;
  margin-bottom: 2rem;
}
.member img {
  display: block;
  width: 100%;
  border-radius: 8px;
}
.member figure {
  position: relative;
  margin-bottom: 1.5rem;
}
.member-name,
.member-job {
  text-transform: uppercase;
  font-size: 1.4rem;
  margin-bottom: 0;
}
.member-job {
  font-size: 1.2rem;
  font-weight: 400;
  color: inherit;
}
.minipopup-area {
  position: fixed;
  left: 30px;
  bottom: 30px;
  z-index: 2000;
}
.minipopup-box {
  position: absolute;
  left: -8rem;
  width: 273px;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  transform: translateY(-100%);
  opacity: 0;
  transition: opacity 0.3s, left 0.3s ease-out, transform 0.3s;
}
.minipopup-box.show {
  opacity: 1;
  left: 0;
}
.minipopup-box.focus {
  transform: scale(0.95) translateY(-100%);
}
.minipopup-box .product-name {
  display: block;
  font-size: 1.3rem;
  margin-bottom: 3px;
}
.minipopup-box .product-name:hover {
  color: #00a567;
}
.minipopup-box .product-list-sm {
  margin-bottom: 2rem;
}
.minipopup-box img {
  max-width: 9rem;
  max-height: 9rem;
}
.minipopup-box .ratings-container {
  margin-bottom: 0;
}
.minipopup-box .product-price {
  color: #00a567;
  margin-bottom: 3px;
}
.minipopup-title {
  border: 1px dashed;
  padding: 0.6rem 0.4rem 0.6rem 1.5rem;
  color: #999;
  font-size: 1.3rem;
}
.minipopup-title .btn {
  margin-left: 1rem;
}
.overlay,
.overlay-visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  transition: padding 0.3s ease-out, background-color 0.3s, opacity 0.3s;
}
.overlay {
  padding-top: 10rem;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
}
a > .overlay {
  cursor: pointer;
}
figure:hover .overlay {
  padding-top: 0;
  opacity: 1;
  border-radius: 8px;
}
.overlay.social-links {
  flex-direction: row;
}
.overlay .social-links {
  justify-content: center;
}
.overlay a:hover {
  color: #fff;
  text-decoration: underline;
}
.overlay a.social-link {
  border-color: #fff;
}
.overlay .social-link:hover {
  color: #fff;
  text-decoration: none !important;
}
figure:hover .overlay-visible {
  background: #087f5b9e;
  padding-bottom: 9rem;
}
.overlay-transparent {
  background: transparent;
}
.overlay-dark figure,
.overlay-dark .banner,
.overlay-light figure,
.overlay-light .banner {
  position: relative;
}
.overlay-dark figure::after,
.overlay-dark figure > a::after,
.overlay-dark.banner::after,
.overlay-light figure::after,
.overlay-light figure > a::after,
.overlay-light.banner::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #222;
  opacity: 1;
  transition: opacity 0.3s, background 0.3s, transform 0.3s;
}
.overlay-dark:hover figure::after,
.overlay-dark:hover figure > a::after,
.overlay-dark.banner:hover::after,
.overlay-light:hover figure::after,
.overlay-light:hover figure > a::after,
.overlay-light.banner:hover::after {
  opacity: 1.1;
}
.overlay-dark.banner-fixed::after,
.overlay-dark.post > figure::after,
.overlay-light.banner-fixed::after,
.overlay-light.post > figure::after {
  content: none;
}
.overlay-dark.post > figure > a::after,
.overlay-light.post > figure > a::after {
  z-index: 1;
}
.overlay-light figure::after,
.banner.overlay-light::after {
  background: #ccc;
}
.overlay-zoom {
  overflow: hidden;
}
.overlay-zoom img {
  transition: transform 0.3s;
}
.overlay-zoom:hover img {
  transform: scale(1.08);
}
.page-subtitle {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1.8;
}
.page-title {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 4rem;
  line-height: 1.125;
  letter-spacing: -0.025em;
  color: #222;
}
.about-title {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 3rem;
  line-height: 1.125;
  letter-spacing: -0.025em;
  color: #fff;
}
.page-title + .breadcrumb {
  margin-top: 0.8rem;
}
.breadcrumb-nav {
  border-bottom: 1px solid #ebebeb;
}
.breadcrumb-nav .breadcrumb {
  padding: 1.4rem 0.5rem;
}
.breadcrumb-nav .breadcrumb li:not(:last-child)::after {
  margin-left: 1.2rem;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  color: #999;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
}
.breadcrumb a {
  color: inherit;
}
.breadcrumb a:hover {
  color: #222;
}
.breadcrumb li:not(:last-child) {
  padding-right: 0.8rem;
}
.breadcrumb li:not(:last-child)::after {
  content: "\f105";
  position: relative;
  margin-left: 0.7rem;
  font-size: 1.1rem;
  font-weight: 600;
}
.breadcrumb li:last-child {
  color: #222;
}
.breadcrumb-sm {
  padding: 1.1rem 0;
  color: inherit;
}
.breadcrumb-sm li:last-child {
  color: inherit;
}
.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  background-color: #f2f3f5;
  background-position: center;
  background-size: cover;
  text-align: center;
  color: #222;
}
.page-header .breadcrumb {
  justify-content: center;
}
.bg-dark > .page-subtitle,
.bg-dark > .page-title,
.bg-dark > .breadcrumb li,
.bg-dark > .breadcrumb a {
  color: #fff;
}
.bg-dark > .breadcrumb li:not(:last-child) {
  opacity: 1.5;
  transition: opacity 0.3s;
}
.bg-dark > .breadcrumb li:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  .page-title {
    font-size: 3.6rem;
  }
  .about-title {
    font-size: 2rem;
  }
}
.pagination {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1.4rem;
  letter-spacing: 0.01em;
  justify-content: center;
}
.page-link-prev,
.page-link-next {
  font-weight: 600;
}
.page-link-prev i {
  margin-right: 0.5rem;
}
.page-link-next i {
  margin-left: 0.5rem;
}
.page-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 3rem;
  height: 4rem;
  padding: 0 0.3rem;
  line-height: 1;
  background-color: transparent;
  border: 0.1rem solid transparent;
  transition: border 0.3s;
}
:hover > .page-link,
.active > .page-link {
  border-color: #dadada !important;
  color: #222 !important;
}
.disabled > .page-link {
  color: #ccc;
}
.page-link-next,
.page-link-prev {
  min-width: 8rem;
  padding: 1.2rem;
  text-transform: uppercase;
}
.page-item:not(:last-child) {
  margin-right: 0.5rem;
}
.page-item-dots {
  white-space: nowrap;
}
.page-item.active .page-link {
  background-color: #00a567 !important;
  color: white;
}
.page-item-dots::before {
  content: "...";
  margin-top: -0.9em;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
}
.show-info {
  margin: 0;
  color: #ccc;
}
.show-info span {
  margin: 0 0.5rem;
  color: #222;
}
.page-item-total {
  margin-right: 0.5rem;
}
@media (min-width: 576px) {
  .page-item-dots,
  .page-item-total {
    margin-right: 2rem;
  }
}
.mfp-wrap {
  z-index: 9999;
}
.mfp-bg {
  z-index: 9999;
  background: #000;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}
.mfp-bg.mfp-ready {
  opacity: 1.7;
}
.mfp-bg.mfp-removing {
  opacity: 1;
}
.mfp-container {
  padding: 4rem 2rem;
}
.mfp-close {
  transform: rotateZ(45deg);
  transition: opacity 0.3s;
  width: 18px;
  height: 18px;
}
.mfp-wrap .mfp-close {
  top: 20px;
  right: 20px;
}
.mfp-content .mfp-close {
  top: -25px;
  right: 0;
}
.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  top: 15px;
  right: 0;
}
.mfp-close span {
  display: none;
}
.mfp-close::before,
.mfp-close::after {
  content: "";
  display: block;
  position: absolute;
  background: #ccc;
  width: 2px;
  height: 100%;
  top: 0;
  left: calc(50% - 1px);
}
.mfp-close::before {
  width: 100%;
  height: 2px;
  top: calc(50% - 1px);
  left: 0;
}
.pswp__button--arrow--left,
.pswp__button--arrow--right,
.mfp-arrow {
  width: 4.8rem;
  height: 4.8rem;
  color: #fff;
  border-radius: 50%;
  font-size: 2.4rem;
  font-weight: 900;
  font-family: "Donald";
  text-align: center;
  opacity: 1.5;
  right: 10%;
  transition: opacity 0.3s;
  border: 2px solid;
}
.pswp__ui > button::before {
  background: transparent;
  position: static;
  line-height: 44px;
}
.pswp__ui > button:hover {
  opacity: 1;
}
.pswp__button--arrow--right::before {
  content: "\e81a";
}
.mfp-arrow::before,
.mfp-arrow::after {
  content: "\f054";
  position: static;
  display: inline;
  margin: 0;
  border: 0;
}
.mfp-arrow::after {
  content: none;
}
button.mfp-arrow {
  border: 2px solid;
}
.pswp__button--arrow--left,
.mfp-arrow-left {
  left: 10%;
}
.pswp__button--arrow--left::before,
.mfp-arrow-left::before {
  content: "\e819";
}
.mfp-content > * {
  position: relative;
  margin: auto;
  background: #fff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  opacity: 1;
}
.mfp-ready .mfp-content > * {
  opacity: 1;
}
.mfp-removing .mfp-content > * {
  opacity: 1;
}
.login-popup {
  max-width: 500px;
  padding: 3.5rem 5rem 4.8rem;
}
.login-popup .nav-link {
  padding: 0 0 1px;
  color: #222;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 2.43;
}
.login-popup .nav-item {
  margin-bottom: -1px;
}
.login-popup .nav-item:not(:last-child) {
  margin-right: 1px;
}
.login-popup .nav-item.show .nav-link,
.login-popup .nav-item .nav-link.active {
  color: #222;
  border-color: #00a567;
}
.login-popup .tab-pane {
  padding-top: 2.8rem;
  padding-bottom: 0;
}
.login-popup form {
  margin-bottom: 2.3rem;
}
.login-popup .form-group {
  margin-bottom: 1.8rem;
}
.login-popup .form-group label {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 1.3rem;
  font-family: "Open Sans", sans-serif;
  line-height: 1;
}
.login-popup .form-group .form-control {
  height: 3.7rem;
}
.login-popup .form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  font-size: 1.2rem;
}
.login-popup .btn {
  height: 3.9rem;
  padding: 0;
  font-size: 1.3rem;
}
.login-popup .lost-link {
  text-align: right;
  color: #d26e4b;
}
.login-popup .lost-link:hover {
  text-decoration: underline;
}
.login-popup .form-choice label {
  display: block;
  margin-bottom: 0.3rem;
  font-size: 1.2rem;
  color: #999;
}
.newsletter-popup {
  display: flex;
  max-width: 70rem;
  text-align: center;
  background-position: 60% center;
  background-size: cover;
}
.newsletter-popup h4 {
  margin-bottom: 0.9rem;
  font-size: 1.6rem;
}
.newsletter-popup h2 {
  margin-bottom: 1.2rem;
  font-size: 2.8rem;
  line-height: 1.07;
  letter-spacing: -0.025em;
}
.newsletter-popup b {
  display: block;
  margin-top: 0.3rem;
  color: #222;
  font-size: 3rem;
  letter-spacing: 0.025em;
}
.newsletter-popup .btn {
  min-width: auto;
  padding: 0.5em 1.7em;
  font-size: 1.3rem;
}
.newsletter-popup p {
  max-width: 100%;
  margin-bottom: 1.8rem;
  padding: 0 0.5rem;
  font-size: 1.3rem;
  line-height: 2rem;
}
.newsletter-popup label {
  color: #999;
  font-size: 1.2rem;
}
.newsletter-popup .email {
  border: 0;
  color: #999;
  background: #f4f4f4;
}
.newsletter-popup .form-control {
  border: 1px solid #ccc;
  background: #fff;
  border-right: none;
}
.newsletter-content {
  margin-left: auto;
  max-width: 38.8rem;
  padding: 6.6rem 4.2rem 6.4rem;
}
.mfp-product .mfp-container {
  padding: 4rem 3rem 2rem;
}
.mfp-product .mfp-content {
  max-width: 92.3rem;
  margin: auto;
}
.mfp-product .product {
  background-color: #fff;
  padding: 3rem 2rem;
}
.mfp-product .mfp-close {
  position: absolute;
  top: -2.7rem;
  right: 0;
}
.pswp__bg {
  background-color: rgba(0, 0, 0, 0.7);
}
.pswp__img--placeholder--blank {
  background-color: #f2f3f5;
}
.pswp__ui--fit .pswp__caption,
.pswp__ui--fit .pswp__top-bar {
  background-color: transparent;
}
.pswp__caption__center {
  text-align: center;
}
.mfp-ready.mfp-zoom-popup .mfp-content {
  transform: scale(1);
}
.mfp-zoom-popup .mfp-content,
.mfp-removing.mfp-zoom-popup .mfp-content {
  transition: 0.2s ease-in-out;
  transform: scale(0.8);
}
.mfp-ready.mfp-flip-popup .mfp-content {
  transform: translateY(0) perspective(600px) rotateX(0);
}
.mfp-flip-popup .mfp-content,
.mfp-removing.mfp-flip-popup .mfp-content {
  transition: 0.3s ease-out;
  transform: translateY(-20px) perspective(600px) rotateX(20deg);
}
@media (max-width: 767px) {
  .mfp-product .product {
    padding: 2rem 1rem;
  }
  .mfp-arrow {
    color: #444;
  }
}
@media (min-width: 768px) {
  .mfp-product .product-gallery {
    margin-bottom: 0;
  }
  .mfp-product div.product-details {
    overflow-y: auto;
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .mfp-product div.product-details {
    margin-top: 2rem;
  }
  .newsletter-content {
    max-width: 36rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .login-popup {
    max-width: 500px;
    padding: 3.5rem 2rem;
  }
}
.equal-height .product {
  height: 100%;
}
.product-shadow {
  transition: box-shadow 0.3s;
}
.product-shadow:hover {
  box-shadow: 0 15px 25px -20px rgba(0, 0, 0, 0.5);
}
.product-label-round .product-label {
  padding: 0.5rem 1.1rem;
  border-radius: 1rem;
}
.text-center .product-details {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.product-wrap {
  margin-bottom: 1rem;
}
.product {
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  color: #999;
  transition: 0.3s;
}
.product-box:hover {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.product:hover .product-action,
.product:hover .product-action-vertical {
  visibility: visible;
  opacity: 1;
}
.product-media:hover img:first-child {
  opacity: 1;
}
.product-media:hover img:last-child {
  opacity: 1;
}
.product-media {
  position: relative;
  margin-bottom: 0;
}
.product-media img {
  display: block;
  width: 100%;
  height: auto;
  transition: all 0.5s;
}
.product-media img:last-child {
  position: absolute;
  opacity: 1;
  left: 0;
  right: 0;
  top: 0;
  left: 0;
  object-fit: cover;
}
.product-media img:first-child {
  position: relative;
  opacity: 1;
}
.product-label-group {
  position: absolute;
  left: 0.5rem;
  top: 1rem;
  max-width: 10rem;
}
.product-label {
  display: inline-block;
  text-align: center;
  margin-bottom: 0.5rem;
  padding: 0.7rem 1.1rem;
  font-size: 1.1rem;
  border-radius: 15px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
}
.product-label.label-new {
  background: #00a567;
}
.product-label.label-sale {
  background: #00a567;
}
.product-label.label-top {
  background: #00a567;
}
.product-label.label-stock {
  background: #999;
}

.btn-product-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  margin-bottom: 0.5rem;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  background-color: white;
  color: #999;
  font-size: 1.6rem;
  font-weight: 700;
  transition: border-color 0.3s, color 0.3s, background-color 0.3s;
}
.btn-product-icon.btn-cart i {
  margin-bottom: 0.2rem;
}
.btn-product-icon:hover {
  border-color: #00a567;
  color: #fff;
  background-color: #00a567;
}
.btn-product {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 1.14em 0;
  background-color: #00a567;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  transition: opacity 0.3s;
  border-radius: 4px;
}
.btn-product:hover {
  color: #fff;
  background-color: #098253;
}
.btn-quickview {
  opacity: 1.8;
  transition: opacity 0.3s;
}
.btn-quickview:hover {
  opacity: 1;
}
.btn-quickview i {
  font-size: 1.4rem;
  font-weight: 700;
}
.btn-wishlist {
  line-height: 1;
}
.btn-wishlist.loading::before,
.btn-wishlist.loading::after {
  z-index: 1;
}
.product-action-vertical {
  position: absolute;
  top: 15px;
  right: 15px;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 1;
  visibility: hidden;
}
.product-action-vertical .btn-wishlist.loading {
  overflow: hidden;
}
.product-action {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  z-index: 10;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  transition: box-shadow 0.3s;
}
.product-details {
  position: relative;
  padding-top: 1.8rem;
  padding-bottom: 2rem;
}
.product-details > .btn-wishlist {
  position: absolute;
  top: 1.7rem;
  right: 1px;
  color: #999;
  z-index: 1;
  font-size: 1.6rem;
}
.product-details > .btn-wishlist i {
  display: inline-block;
  margin: 0;
  transition: transform 0.3s;
}
.product-details > .btn-wishlist:hover {
  color: #00a567;
}
.product-details > .btn-wishlist:hover i {
  transform: rotateY(180deg);
}
.product-details > :last-child {
  margin-bottom: 0;
}
.product-cat {
  margin-bottom: 0.4rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}
.product-cat a {
  display: inline-block;
}
.product-cat a:hover {
  color: #00a567;
}
.product-name {
  margin-bottom: 0.6rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 400;
  color: #222;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-name a:hover {
  color: #00a567;
}
.product-price {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.6rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #222;
}
.product-price .old-price {
  color: #ccc;
}
.product-price .new-price {
  margin-right: 1rem;
  text-decoration: none !important;
}
.ratings-container {
  display: flex;
  align-items: center;
  margin-left: 1px;
  line-height: 1;
  margin-bottom: 1.5rem;
  font-size: 11px;
  letter-spacing: 0.2em;
}
.ratings-full {
  cursor: pointer;
}
.ratings-full,
.ratings {
  position: relative;
  font-family: "Donald";
}
.ratings-full + .rating-reviews {
  margin-left: 1rem;
}
.ratings-full::before {
  content: "\e94b ""\e94b ""\e94b ""\e94b ""\e94b";
  color: rgba(0, 0, 0, 0.16);
}
.ratings-full:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.ratings {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}
.ratings::before {
  content: "\e94b ""\e94b ""\e94b ""\e94b ""\e94b";
  color: #d26e4b;
}
.rating-reviews {
  margin: 2px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.1rem;
  color: #ccc;
  letter-spacing: 0;
  text-transform: uppercase;
}
.rating-reviews:hover {
  color: #00a567;
}
.product-variations {
  display: flex;
}
.ratings-container + .product-variations {
  margin-top: -5px;
}
.product-variations > a:not(.size-guide) {
  display: inline-block;
  position: relative;
  margin: 3px 5px 3px 0;
  padding: 0 5px;
  border-radius: 2px;
  background-color: transparent;
  background-size: cover;
  font-size: 1.2rem;
  font-family: inherit;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  width: 2.6rem;
  height: 2.6rem;
  color: #222;
  transition: 0.3s;
  border: 1px solid #e1e1e1;
}
.product-variations > a:not(.size-guide):last-child {
  margin-right: 0;
}
.product-variations .color:not(.active) {
  border: none;
}
.product-variations > a.active,
.product-variations > a:not(.size-guide):hover {
  border: 1px solid;
  box-shadow: inset 0 0 0 2px #fff;
}
.product-details .product-action,
.product-hide-details .product-action {
  position: relative;
  opacity: 1;
}
.product-details .product-action .btn-wishlist,
.product-hide-details .product-action .btn-wishlist {
  position: static;
}
.product-details .btn-cart,
.product-hide-details .btn-cart {
  flex: 1;
  min-width: 9.5rem;
  max-width: 12.3rem;
  height: 4rem;
  padding: 0.97em 0;
  font-size: 1.3rem;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  z-index: 1;
}
.product-details .btn-quickview,
.product-hide-details .btn-quickview {
  margin: 0 0 0 0.8rem;
}
.product-details .btn-product-icon,
.product-hide-details .btn-product-icon {
  width: 4rem;
  height: 4rem;
  border: 0;
  border-radius: 0;
  background-color: #eee;
  color: #222;
  transition: color 0.3s, background-color 0.3s, visibility 0.3s;
}
.product-details .btn-product-icon i,
.product-hide-details .btn-product-icon i {
  font-size: 1.6rem;
}
.product-details .btn-product-icon:hover,
.product-hide-details .btn-product-icon:hover {
  color: #fff;
  background-color: #00a567;
}
.product-details .btn-quickview i,
.product-hide-details .btn-quickview i {
  font-size: 1.5rem;
}
.product-details .btn-cart,
.product-hide-details .btn-cart {
  color: #fff;
  background-color: #444;
}
.product-details .btn-cart:hover,
.product-hide-details .btn-cart:hover {
  color: #fff;
  background-color: #02794d !important;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .product-hide-details .btn-cart,
  .product-details .btn-cart {
    flex: 0 0 12.3rem;
  }
}
@media screen and (-ms-high-contrast: active) and (max-width: 575px),
  (-ms-high-contrast: none) and (max-width: 575px) {
  .product-hide-details .btn-cart,
  .product-details .btn-cart {
    flex: 0 0 9.5rem;
  }
}
.product-classic .btn-quickview {
  opacity: 1;
}
.product-classic .btn-wishlist {
  margin: 0 0 0 0.8rem;
}
.product-classic .btn-wishlist,
.product-classic .btn-quickview {
  transform: translateX(-200%);
  opacity: 1;
  visibility: hidden;
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s, color 0.3s,
    background-color 0.3s, border-color 0.3s;
}
.product-classic .btn-quickview {
  transition: transform 0.5s, opacity 0.5s, visibility 0.5s, color 0.3s,
    background-color 0.3s, border-color 0.3s;
}
.product-classic .btn-cart {
  color: #222;
  background-color: #eee;
}
.product-classic:hover .btn-wishlist,
.product-classic:hover .btn-quickview {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.product-classic:hover .btn-cart {
  color: #fff;
  background-color: #444;
}
.product-classic:hover .btn-cart:hover {
  color: #fff;
  background-color: #00a567;
}
.product-classic .product-details {
  padding-left: 0;
}
.product-slideup-content {
  overflow: hidden;
}
.product-slideup-content .product-hide-details {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  transition: transform 0.3s;
  background-color: #fff;
}
.product-slideup-content .btn-wishlist {
  position: static;
  margin: 0 0.8rem 0 0;
}
.product-slideup-content .product-details {
  padding-bottom: 1px;
  background-color: #fff;
  transition: transform 0.3s;
}
.product-slideup-content .btn-wishlist,
.product-slideup-content .btn-quickview {
  opacity: 1;
  visibility: hidden;
  transition: transform 0.4s, opacity 0.3s, visibility 0.3s, color 0.3s,
    background-color 0.3s;
}
.product-slideup-content .btn-quickview {
  transform: translateX(-200%);
}
.product-slideup-content .btn-wishlist {
  transform: translateX(200%);
}
.product-slideup-content:hover .btn-wishlist,
.product-slideup-content:hover .btn-quickview {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.product-slideup .btn-wishlist {
  position: static;
  margin: 0 0.8rem 0 0;
}
.product-slideup .product-details {
  padding-bottom: 0;
  background-color: #fff;
  transition: transform 0.3s;
}
.product-slideup .ratings-container {
  margin-bottom: 0;
}
.product-slideup .btn-wishlist,
.product-slideup .btn-quickview {
  opacity: 1;
  visibility: hidden;
  transition: transform 0.4s, opacity 0.3s, visibility 0.3s, color 0.3s,
    background-color 0.3s;
}
.product-slideup .btn-quickview {
  transform: translateX(-200%);
}
.product-slideup .btn-wishlist {
  transform: translateX(200%);
}
.product-slideup .product-action {
  display: flex;
  position: absolute;
  padding-top: 1.5rem;
  top: 100%;
  bottom: auto;
  visibility: hidden;
  opacity: 1;
}
.product-slideup:hover {
  transform: translateY(-58px);
}
.product-slideup:hover .btn-wishlist,
.product-slideup:hover .btn-quickview {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.product-slideup:hover .product-action {
  visibility: visible;
  opacity: 1;
}
.product-image-gap {
  padding: 0.9rem;
  border: 1px solid #e1e1e1;
  background-color: #fff;
}
.product-image-gap .product-details {
  padding: 1.8rem 1rem 1rem;
}
.product-image-gap .ratings-container {
  margin-bottom: 0;
}
.product-image-gap:hover {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
}
@media (max-width: 479px) {
  .product-image-gap .product-details {
    padding: 1.8rem 0 1rem;
  }
}
.product-list {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.product-list .product-media {
  flex: 0 0 26rem;
  max-width: 26rem;
  margin: 0 2rem 0 0;
}
.product-list .product-details {
  padding: 0;
  flex: 1;
}
.product-list .product-label {
  font-size: 1.1rem;
}
.product-list .btn-quickview {
  opacity: 1;
}
.product-list .btn-wishlist {
  margin: 0 0 0 0.8rem;
}
.product-list .btn-wishlist,
.product-list .btn-quickview {
  transform: translateX(-200%);
  opacity: 1;
  visibility: hidden;
  transition: transform 0.3s, opacity 0.3s, visibility 0.3s, color 0.3s,
    background-color 0.3s;
}
.product-list .btn-quickview {
  transition: transform 0.5s, opacity 0.5s, visibility 0.5s, color 0.3s,
    background-color 0.3s;
}
.product-list .btn-cart {
  color: #222;
  background-color: #eee;
}
.product-list .ratings-container {
  margin-bottom: 0.8rem;
}
.product-list .product-name {
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: -0.025em;
}
.product-list .product-price {
  font-size: 1.8rem;
  font-weight: 600;
}
.product-list .product-short-desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 1.3rem;
  text-transform: none;
  font-size: 1.4rem;
  line-height: 1.78;
  color: #666;
}
.product-list:hover .btn-wishlist,
.product-list:hover .btn-quickview {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.product-list:hover .btn-cart {
  color: #fff;
  background-color: #444;
}
.product-list:hover .btn-cart:hover {
  color: #fff;
  background-color: #00a567;
}
.product-list-sm {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.product-list-sm .product-media {
  flex: 0 0 10rem;
  max-width: 10rem;
  margin: 0 1rem 0 0;
}
.product-list-sm .product-details {
  padding: 0;
}
.product-list-sm .product-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0.7rem;
  white-space: normal;
  color: #666;
}
.product-list-sm .product-name:hover {
  color: #00a567;
}
.product-list-sm .ratings-container {
  margin: 0;
}
.product-list-sm .product-price {
  font-size: 1.4rem;
}
.product-list-sm + .product-list {
  margin-top: 2rem;
}
.product-list-sm .btn-cart {
  margin-top: 2rem;
  height: 3.3rem;
  max-width: 11.7rem;
  border: 1px solid #d7d7d7;
  color: #d26e4b;
  background: transparent;
  font-size: 1.2rem;
}
.product-list-sm .btn-cart:hover {
  border-color: #d26e4b;
  background: #d26e4b;
}
.product.text-center .product-cat {
  padding: 0;
}
.product.text-center .product-price,
.product.text-center .product-variations,
.product.text-center .ratings-container,
.product.text-center .product-action {
  justify-content: center;
}
.product.shadow-media:hover .product-media {
  box-shadow: 0px 20px 20px -16px rgba(0, 0, 0, 0.5);
}
@media (max-width: 575px) {
  .product-list {
    display: block;
  }
  .product-list .product-media {
    margin: 0;
    max-width: 100%;
  }
  .product-list .product-details {
    padding: 1.8rem 0 2rem;
  }
  .product-action-vertical {
    top: 10px;
    right: 10px;
  }
}
@media (max-width: 479px) {
  .product-details .btn-cart,
  .product-hide-details .btn-cart {
    height: 3.6rem;
    padding: 0.85em 0;
  }
  .product-action .btn-wishlist,
  .product-action .btn-quickview {
    max-height: 3.6rem;
    padding: 1rem 0;
  }
}
@media (max-width: 375px) {
  .product-details .btn-quickview,
  .product-hide-details .btn-quickview {
    margin-left: 2px;
  }
  .push {
    margin-top: 20%;
  }
  .product-details .btn-wishlist,
  .product-hide-details .btn-wishlist {
    margin-left: 2px;
  }
  .product-slideup-content .btn-wishlist,
  .product-slideup .btn-wishlist {
    margin-right: 2px;
  }
  .hiddenSM {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}
.product-filters {
  margin-right: 1.5rem;
}
.product-thumbs-wrap,
.product-single-carousel {
  flex-basis: 100%;
  max-width: 100%;
}
.product-single-carousel .owl-nav .owl-prev,
.product-single-carousel .owl-nav .owl-next {
  color: #222;
  border: 0;
}
.product-image {
  position: relative;
}
.product-thumbs-wrap {
  position: relative;
  margin-top: 1rem;
  overflow: hidden;
}
.product-thumbs-wrap img {
  display: block;
}
.product-thumbs-wrap button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  border: 0;
  transition: opacity 0.3s, transform 0.3s;
  z-index: 1;
  opacity: 1;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  cursor: pointer;
}
.product-thumbs-wrap:hover button:not(.disabled) {
  opacity: 1.9;
  transform: none;
}
.product-thumbs-wrap button:not(.disabled):hover {
  opacity: 1;
}
.product-thumbs-wrap > button {
  display: none;
}
.product-thumbs-wrap button,
.product-thumbs .owl-nav .owl-prev,
.product-thumbs .owl-nav .owl-next {
  background-color: #fff;
  color: #666;
  font-size: 1.6rem;
}
.product-thumbs-wrap button,
.product-thumb::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.product-thumbs {
  transition: top 0.3s ease-out;
  display: flex;
}
.product-thumbs.owl-carousel {
  margin: 0 -0.5rem;
  width: calc(100% + 1rem);
}
.product-thumbs .owl-stage {
  display: flex;
}
.product-thumbs .owl-prev {
  transform: translateX(-100%);
  left: 0.5rem;
}
.product-thumbs .owl-next {
  left: auto;
  right: 0.5rem;
  transform: translateX(100%);
}
.product-thumb {
  position: relative;
  margin: 0 0.5rem;
  cursor: pointer;
}
.product-thumb::before {
  content: "";
  transition: border-color 0.3s;
  border: 2px solid transparent;
}
.product-thumb.active::before {
  border-color: #00a567;
}
.product-single {
  color: inherit;
}
.product-single .product-details {
  padding: 0 0 3rem;
}
.container-fluid .product-single .product-details {
  padding-top: 4px;
}
aside + div .product-single .product-details {
  padding-top: 2px;
}
.product-single .product-cat {
  margin-bottom: 1.3rem;
  color: #999;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: -0.025em;
}
.product-single .product-cat span {
  margin-right: 0.7rem;
}
.product-single .product-name {
  font-size: 2.6rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  white-space: normal;
}
.product-single .product-price {
  display: block;
  margin-bottom: 1.3rem;
  color: #d26e4b;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.2;
}
.product-single .old-price {
  font-size: 2.4rem;
  font-weight: 600;
}
.product-single .rating-reviews {
  font-size: 1.2rem;
}
.product-single .rating-reviews:not(:hover) {
  color: #999;
}
.product-single .ratings-container {
  margin-bottom: 1.9rem;
  font-size: 16px;
}
.product-single .ratings-full {
  margin-top: -2px;
}
.product-single .ratings::before {
  color: #666;
}
.product-single label {
  color: #222;
  font-weight: 600;
  text-transform: uppercase;
}
.product-single .product-action {
  display: inline-block;
}
.product-single .divider {
  margin-left: 1.9rem;
  margin-right: 1.8rem;
}
.product-single .nav-link {
  font-size: 1.4rem;
}
.product-single .social-links {
  margin-right: 3rem;
}
.product-single .product-footer > * {
  margin-bottom: 1rem;
}
.product-single .btn-wishlist,
.product-single .btn-compare {
  display: inline-block;
  padding: 0;
  color: #999;
  background-color: transparent;
  font-size: 1.2rem;
  font-weight: 600;
  transition: color 0.3s;
}
.product-single .btn-wishlist i,
.product-single .btn-compare i {
  display: inline-block;
  margin: 0 0.5rem 0.3rem 0;
  vertical-align: middle;
  font-size: 1.8rem;
  line-height: 0;
}
.product-single .btn-wishlist:hover,
.product-single .btn-compare:hover {
  color: #00a567;
}
.product-single .btn-compare i {
  font-size: 2.1rem;
}
.product-single .quantity {
  font-weight: 700;
}
.product-single .product-meta + .product-countdown-container {
  margin-top: 0.4rem;
}
.product-single .product-countdown-container {
  display: inline-flex;
  align-items: center;
  margin-bottom: 2.5rem;
  padding: 1rem 1.6rem;
  border: 1px solid #444;
  background-color: #444;
  text-transform: none;
  line-height: 1;
  color: #fff;
}
.product-single .product-countdown-container label {
  margin-right: 0.5rem;
  text-transform: none;
  font-weight: 400;
  color: #fff;
}
.product-single .product-countdown-container .product-sale-info {
  position: relative;
  padding-right: 1.5rem;
}
.product-single .product-countdown-container .product-sale-info i {
  margin-right: 0.8rem;
}
.product-single .product-countdown-container .product-sale-info::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 2.4rem;
  background: rgba(255, 255, 255, 0.2);
}
.product-single .product-countdown-container .product-sale-info + label {
  margin-left: 1.5rem;
}
.product-single .product-label-group {
  top: 2rem;
  left: 2.5rem;
  z-index: 2;
}
.product-single .product-label {
  color: #fff;
}
.product-single .product-variations > a:not(.size-guide) {
  width: 3rem;
  height: 3rem;
}
.product-single .color {
  border: 0;
}
.product-single .size {
  width: 3.6rem;
}
.product-meta {
  margin-bottom: 1.5rem;
  color: #999;
  font-size: 1.2rem;
  letter-spacing: -0.025em;
}
.product-meta span {
  margin: 0 1.9rem 0 3px;
}
.product-form {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  line-height: 3rem;
  margin: 0 0 1rem;
}
.product-form > label {
  min-width: 7rem;
  margin-bottom: 1rem;
}
.product-form.product-variations {
  line-height: 37px;
}
.product-form .select-box::before {
  right: 1.4rem;
  font-size: 1.2rem;
  color: #222;
}
.product-form select {
  max-width: none;
  padding: 0.8rem 3rem 0.8rem 1.4rem;
  color: #222;
  border-color: #ccc;
  font-size: 1.3rem;
}
.product-form .product-variations {
  display: block;
  margin-top: -3px;
  margin-bottom: -3px;
}
.product-form.product-qty {
  line-height: 4.5rem;
}
.product-form .input-group {
  margin-right: 1rem;
}
.product-form .btn-cart {
  border: 0;
  flex: 1;
  min-width: 13rem;
  font-size: 1.4rem;
  background-color: #00a567;
  cursor: pointer;
  max-width: 20.7rem;
  height: 4.5rem;
}
.product-form .btn-cart:disabled {
  background-color: #619ae2;
  cursor: not-allowed;
}
.product-form .btn-cart i {
  margin-right: 0.8rem;
  margin-top: -1px;
  font-size: 1.8rem;
  line-height: 0;
  vertical-align: middle;
}
.product-form .btn-cart i::before {
  margin: 0;
}
.product-form .btn-cart:hover:not(:disabled) {
  background-color: #1d569d;
}
.product-form-group {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;
}
.product-form-group > * {
  margin-bottom: 1rem;
}
.product-form-group > :not(:last-child) {
  margin-right: 2rem;
}
.product-form-group .product-variations {
  margin-bottom: 7px;
}
.size-guide {
  display: inline-flex;
  align-items: center;
  font-weight: 300;
}
.size-guide i {
  margin-right: 0.5rem;
  font-size: 1.5rem;
  line-height: 0;
}
.product-variation-price {
  display: none;
  padding-top: 25px;
}
.product-variation-price span {
  margin-bottom: 1rem;
  color: #222;
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: -0.025em;
}
.product-variation-clean {
  display: block;
  position: absolute;
  margin-top: 1rem;
  padding: 0.3em 1em;
  left: 0;
  top: calc(100% - 10px);
  font-size: 1.2rem;
  line-height: 1.6;
  background: #e1e1e1;
}
@media (min-width: 992px) {
  .pg-vertical .product-thumbs-wrap {
    order: -1;
    max-width: 109px;
    margin: 0 1rem 0 0;
  }
  .pg-vertical .product-single-carousel {
    max-width: calc(100% - 119px);
  }
  .pg-vertical .product-thumbs {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .pg-vertical .product-thumb {
    margin: 0 0 1rem;
  }
  .pg-vertical .thumb-up,
  .pg-vertical .thumb-down {
    display: flex;
    width: 100%;
    height: 2.4rem;
  }
  .pg-vertical .thumb-up {
    transform: translateY(-100%);
  }
  .pg-vertical .thumb-up i::before {
    content: "\f077";
  }
  .pg-vertical .thumb-down {
    top: auto;
    transform: translateY(100%);
  }
  .pg-vertical .thumb-down i::before {
    content: "\f078";
  }
  .pg-vertical .product-label-group {
    left: 14rem;
  }
}
.product-single.product-sticky-both .p-sticky {
  top: 88px;
}
.product-single.product-sticky-both .product-details {
  padding: 0;
}
.product-popup .divider {
  margin-left: 1.5rem;
  margin-right: 1.4rem;
}
.social-links {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
}
.social-link {
  margin: 2px 8px 2px 0;
  width: 30px;
  height: 30px;
  line-height: 26px;
  font-size: 1.4rem;
  border-radius: 50%;
  border: 2px solid #ccc;
  color: inherit;
  transition: color 0.4s, border 0.4s, background 0.4s;
  text-align: center;
}
.social-link:last-child {
  margin-right: 0;
}
.social-link i {
  letter-spacing: -0.001em;
  line-height: 1;
}
.social-link:hover {
  background: #00a567;
  border-color: #00a567;
  color: #fff;
}
.social-link.social-facebook:hover {
  background: #3b5998;
  border-color: #3b5998;
}
.social-link.social-twitter:hover {
  background: #1da1f2;
  border-color: #1da1f2;
}
.social-link.social-linkedin:hover {
  background: #0073b2;
  border-color: #0073b2;
}
.social-link.social-email:hover {
  background: #dd4b39;
  border-color: #dd4b39;
}
.social-link.social-google:hover {
  background: #dd4b39;
  border-color: #dd4b39;
}
.social-link.social-pinterest:hover {
  background: #bd081c;
  border-color: #bd081c;
}
.social-link.social-reddit:hover {
  background: #ff4107;
  border-color: #ff4107;
}
.social-link.social-tumblr:hover {
  background: #304e6c;
  border-color: #304e6c;
}
.social-link.social-vk:hover {
  background: #6383a8;
  border-color: #6383a8;
}
.social-link.social-whatsapp:hover {
  background: #3c8a38;
  border-color: #3c8a38;
}
.social-link.social-xing:hover {
  background: #1a7576;
  border-color: #1a7576;
}
.social-link.social-instagram:hover {
  background: #3b6994;
  border-color: #3b6994;
}
.no-border .social-link {
  border: 0;
  line-height: 30px;
}
.no-border .social-link:not(:hover) {
  background: transparent;
}
.inline-links .social-link {
  display: inline-block;
  margin-right: 2rem;
  width: auto;
  height: auto;
  border: 0;
  color: inherit;
}
.inline-links .social-link:not(:last-child) {
  margin-right: 2rem;
}
.inline-links .social-link:hover {
  background: transparent;
}
.inline-links .social-link.social-facebook:hover {
  color: #3b5998;
}
.inline-links .social-link.social-twitter:hover {
  color: #1da1f2;
}
.inline-links .social-link.social-linkedin:hover {
  color: #0073b2;
}
.inline-links .social-link.social-email:hover {
  color: #dd4b39;
}
.inline-links .social-link.social-google:hover {
  color: #dd4b39;
}
.inline-links .social-link.social-pinterest:hover {
  color: #bd081c;
}
.inline-links .social-link.social-reddit:hover {
  color: #ff4107;
}
.inline-links .social-link.social-tumblr:hover {
  color: #304e6c;
}
.inline-links .social-link.social-vk:hover {
  color: #6383a8;
}
.inline-links .social-link.social-whatsapp:hover {
  color: #3c8a38;
}
.inline-links .social-link.social-xing:hover {
  color: #1a7576;
}
.inline-links .social-link.social-instagram:hover {
  color: #3b6994;
}
.sidebar-active .sidebar .sidebar-overlay,
.right-sidebar-active .right-sidebar .sidebar-overlay {
  visibility: visible;
  opacity: 1;
}
.sidebar-active .sidebar .sidebar-content,
.right-sidebar-active .right-sidebar .sidebar-content {
  transform: translateX(0);
  opacity: 1;
}
.sidebar-active .page-wrapper {
  margin-left: 250px;
  margin-right: -250px;
}
.right-sidebar-active .page-wrapper {
  margin-left: -250px;
  margin-right: 250px;
}
.sidebar-overlay,
.sidebar-toggle,
.sidebar-content,
.sidebar-close {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
}
.sidebar-overlay {
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
  visibility: hidden;
  transition: visibility 0.4s, opacity 0.4s;
}
.sidebar-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 160px;
  width: 40px;
  height: 40px;
  font-size: 1.4rem;
  color: #fff;
  background-color: rgba(28, 26, 26, 0.8);
}
.sidebar-close {
  margin: 0;
  left: calc(100vw - 50px);
  top: 25px;
  transition: opacity 0.3s;
}
.sidebar-content {
  bottom: 0;
  width: 30rem;
  padding: 2rem;
  transform: translateX(-100%);
  overflow: auto;
  background-color: #fff;
  opacity: 1;
  line-height: 1.3;
  transition: transform 0.4s, opacity 0.4s;
}
.sidebar-content .sidebar-close {
  display: flex;
  position: static;
  align-items: center;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: -0.025em;
  line-height: 1;
}
.sidebar-content .d-icon-times {
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.6rem;
}
.right-sidebar {
  order: 2;
}
.right-sidebar .sidebar-toggle {
  left: auto;
  right: 0;
}
.right-sidebar .sidebar-close {
  left: 50px;
}
.right-sidebar .sidebar-content {
  transform: translateX(100%);
  left: auto;
  right: 0;
}
@media (max-width: 991px) {
  .sidebar-content {
    width: 30rem;
  }
  .right-sidebar .sidebar-close {
    left: 20px;
  }
}
@media (min-width: 992px) {
  .sidebar-fixed .sidebar-toggle,
  .sidebar-fixed .sidebar-overlay {
    display: none;
  }
  .sidebar-fixed .sidebar-content {
    position: relative;
    overflow: visible;
    padding: 0;
    opacity: 1;
    z-index: 1;
  }
  .sidebar-fixed .sidebar-content {
    width: auto;
    transform: none;
  }
}
.right-sidebar .sidebar-content::-webkit-scrollbar {
  width: 0;
}
.tag {
  display: inline-block;
  padding: 1px 0.9rem 0;
  margin-bottom: 1rem;
  margin-left: 0.14rem;
  border: 1px solid #e1e1e1;
  font-size: 1.2rem;
  line-height: 1.58;
  letter-spacing: -0.01em;
}
.tag:hover {
  color: #00a567;
}
.blog-sidebar .form-control {
  border-color: #e1e1e1;
}
.blog-sidebar .widget-about .widget-title {
  margin-bottom: 1.3rem;
}
.blog-sidebar .widget-categories {
  margin-bottom: 1.2rem;
}
.blog-sidebar .widget-categories .widget-title {
  margin-bottom: 0.8rem;
}
.blog-sidebar .filter-items li:not(:last-child) {
  border-bottom: 0;
}
.blog-sidebar .filter-items a {
  padding: 0.9rem 0;
}
.blog-sidebar .tag {
  padding: 1.5px 1rem;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
}
.blog-sidebar .tag:hover {
  background-color: #00a567;
  border-color: #00a567;
  color: #fff;
}
.widget-collapsible > .widget-title {
  position: relative;
  cursor: pointer;
}
.widget-collapsible .toggle-btn {
  display: block;
  position: absolute;
  top: 30px;
  right: 2px;
  padding: 0;
  width: 10px;
  opacity: 1;
}
.widget-collapsible .toggle-btn::before,
.widget-collapsible .toggle-btn::after {
  content: "";
  position: absolute;
  border-top: 2px solid #666;
  width: 10px;
  transition: transform 0.3s;
}
.widget-collapsible > .collapsed .toggle-btn::before {
  transform: rotate(90deg);
}
.widget-collapsible > .collapsed .toggle-btn::after {
  transform: rotate(180deg);
}
.collapsed + .widget-body {
  display: none;
}
.filter-actions {
  padding-bottom: 2rem;
}
.filter-price-slider {
  font-size: 1.3rem;
}
.filter-price-slider + .filter-actions {
  padding-bottom: 1.5rem;
  padding-left: 2px;
}
.toolbox .btn-link {
  padding: 0;
}
.filter-clean {
  font-size: 1.4rem;
  margin-left: auto;
  letter-spacing: -0.025em;
}
.filter-items a {
  position: relative;
  display: block;
  padding: 1rem 0 1rem 28px;
}
.filter-items a:hover {
  color: #00a567;
}
.filter-items a::before {
  content: "";
  position: absolute;
  border: 1px solid #ccc;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  top: 50%;
  transform: translateY(-50%);
  left: 2px;
  font-size: 0.7em;
  font-weight: 900;
  text-align: center;
  text-indent: 1px;
  line-height: 1.7em;
}
@media (-webkit-min-device-pixel-ratio: 1.5),
  (min--moz-device-pixel-ratio: 1.5),
  (min-device-pixel-ratio: 1.5) {
  .filter-items a::before {
    text-indent: 0;
  }
}
.filter-items .active > a::before {
  content: "\f00c";
  color: #fff;
  background-color: #222;
  border-color: #222;
}
.filter-items.search-ul a {
  padding-left: 2px;
}
.filter-items.search-ul a::before {
  content: none;
}
.filter-items > li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.filter-items li span {
  position: absolute;
  right: 2px;
  color: #ccc;
  font-family: "Open Sans", sans-serif;
}
.filter-items .with-ul > a > i {
  content: "\f068";
  position: absolute;
  top: 50%;
  right: 0;
  line-height: 0;
  margin: -1rem -3px 0 0;
  padding: 1rem 5px;
  font-size: 1rem;
  font-weight: 600;
  transition: transform 0.3s;
}
.filter-items .show > a {
  color: #00a567;
}
.filter-items .show > a i {
  transform: rotate(-180deg);
}
.filter-items ul {
  display: none;
  position: relative;
  padding-left: 1.3rem;
  margin-bottom: 1rem;
}
.filter-items ul::before {
  content: "";
  position: absolute;
  left: 2px;
  top: 4px;
  bottom: 3px;
  border-left: 1px solid #eee;
}
.filter-items ul a {
  padding: 5.5px 0;
}
.filter-items .color {
  top: 50%;
  display: inline-block;
  margin-right: 0.8rem;
  margin-top: -4.5px;
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
}
.shop-sidebar {
  position: relative;
}
.shop-sidebar .sidebar-content {
  padding: 3rem;
}
.shop-sidebar .filter-actions {
  padding-top: 0;
}
.shop-sidebar .widget-title {
  padding: 2.2rem 0 1.4rem 2px;
  margin: 0;
  border: none;
  border-top: 3px solid #eee;
  font-weight: 600;
  transition: padding-bottom 0.4s;
}
.shop-sidebar .collapsed {
  padding-bottom: 2.2rem;
}
.shop-sidebar .collapsed ~ .widget-body {
  opacity: 1.1;
}
.shop-sidebar .widget-body {
  margin-bottom: 1.1rem;
  transition: opacity 0.3s;
}
.shop-sidebar .btn-outline:not(:hover) {
  color: #00a567;
  background: #fff;
}
.shop-sidebar .sidebar-toggle-btn,
.shop-sidebar .left-sidebar-toggle {
  padding: 0.8em 1.04em;
}
.widget-products .widget-title {
  margin-bottom: 2rem;
  border-top: 0;
  border-bottom: 1px solid #e1e1e1;
}
.widget-products .product-price {
  color: #00a567;
}
.widget-products .owl-nav i {
  font-size: 1.7rem;
}
.widget-products .owl-nav {
  top: -5.5rem;
}
@media (min-width: 992px) {
  .shop-sidebar .sidebar-content {
    padding-top: 3rem;
  }
  .sidebar:not(.closed) .sticky-sidebar-fixed > .filter-actions {
    visibility: hidden;
  }
  .sidebar-fixed {
    transition: margin 0.4s;
  }
  .sidebar-fixed .filter-actions:first-child {
    padding-top: 3rem;
  }
  .sidebar-fixed.shop-sidebar .sidebar-content {
    padding: 0 0 2rem;
  }
  .sidebar-fixed + .main-content {
    transition: flex-basis 0.4s, max-width 0.4s;
  }
  .sticky-sidebar-wrapper.closed {
    height: 0;
  }
  .closed.sidebar {
    margin-left: -25%;
  }
  .closed.right-sidebar {
    margin-right: -25%;
  }
  .closed + .main-content {
    max-width: 100%;
    flex-basis: 100%;
  }
  .main-content-wrap {
    overflow: hidden;
  }
  .sidebar-toggle-remain .toggle-remain {
    position: absolute;
    transition: 0.4s;
    left: 0;
  }
}
@media (min-width: 992px) and (-ms-high-contrast: active),
  (min-width: 992px) and (-ms-high-contrast: none) {
  .sidebar-toggle-remain .toggle-remain {
    top: 2.7rem;
  }
}
@media (min-width: 992px) {
  .sidebar-toggle-remain .filter-clean {
    line-height: 3.7rem;
  }
  .sidebar-toggle-remain.sidebar.closed .toggle-remain {
    left: calc(100% + 30px);
  }
  .sidebar-toggle-remain.sidebar.closed .toggle-remain i::before {
    content: "\e97e";
  }
  .sidebar-toggle-remain.sidebar.closed + * > .toolbox:first-child {
    padding-left: 10rem;
  }
  .sidebar-toggle-remain.sidebar .toggle-remain::before {
    content: "";
    position: absolute;
    background-color: #fff;
    right: calc(100% + 2px);
    width: 20px;
    bottom: -2px;
    top: -2px;
  }
  .sidebar-toggle-remain.right-sidebar.closed .toggle-remain {
    left: -3rem;
    transform: translateX(-100%);
  }
  .sidebar-toggle-remain.right-sidebar.closed .toggle-remain i::before {
    content: "\f273";
  }
  .sidebar-toggle-remain.right-sidebar.closed + * > .toolbox:first-child {
    padding-right: 10rem;
  }
}
.store {
  font-size: 1.3rem;
  line-height: 1.85;
  text-align: center;
  margin-bottom: 2rem;
}
.store a.social-link {
  border-color: transparent;
}
.store h4 {
  margin-bottom: 0;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  text-transform: uppercase;
}
.store figure {
  position: relative;
}
.store img {
  width: 100%;
  display: block;
}
.tab-content {
  background-color: #fff;
}
.nav-tabs {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ebebeb;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item .nav-link.active,
.nav-tabs .nav-item:hover .nav-link {
  color: #222;
}
.nav-link {
  display: block;
  margin-bottom: -0.1rem;
  padding: 1.6rem 0.2rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.01em;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0;
  border: 0;
  transition: border 0.35s, color 0.35s, background-color 0.35s;
  cursor: pointer;
}
.nav-item:not(:last-child) {
  margin-right: 2.5rem;
}
.nav-fill .nav-item {
  flex: 1;
}
.tab-content {
  position: relative;
}
.tab-content > .tab-pane {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 0;
  opacity: 1;
  z-index: -1;
  transition: visibility 0.3s, opacity 0.3s;
}
.tab-content > .tab-pane:not(.active) {
  overflow: hidden;
  visibility: hidden;
}
.tab-content > .active {
  position: relative;
  height: auto;
  opacity: 1;
  z-index: auto;
}
.tab-pane {
  padding: 2.3rem 0;
  line-height: 1.72;
  color: #666;
}
.tab-nav-simple .nav-link {
  border-bottom: 2px solid transparent;
  background-color: transparent;
  color: #666;
}
.tab-nav-simple .nav-item.show .nav-link,
.tab-nav-simple .nav-item .nav-link.active,
.tab-nav-simple .nav-item:hover .nav-link {
  border-bottom-color: #00a567 !important;
  border-color: transparent;
}
.tab-nav-center .nav {
  justify-content: center;
}
.tab-nav-boxed .nav-item {
  margin-right: 0;
}
.tab-nav-boxed .nav-link {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.tab-nav-round .nav-item:not(:last-child) {
  margin-right: 2px;
}
.tab-nav-round .nav-link {
  margin-bottom: 0;
  border-radius: 2.3rem;
  background-color: #f2f3f5;
}
.tab-nav-round .nav-tabs {
  border-bottom: 0;
}
.tab-boxed .tab-pane {
  padding-left: 2rem;
  padding-right: 2rem;
}
.tab-outline .tab-content {
  border: 1px solid #ebebeb;
}
.tab-outline .nav-tabs {
  border-bottom: 0;
}
.tab-outline .nav-link {
  padding-top: 1.4rem;
  padding-bottom: 1.5rem;
  border: 1px solid transparent;
  border-top-width: 2px;
}
.tab-outline .nav-item.show .nav-link,
.tab-outline .nav-item .nav-link.active,
.tab-outline .nav-item:hover .nav-link {
  border-color: #ebebeb;
  border-top-color: #00a567;
}
.tab-outline2 .nav {
  position: relative;
  z-index: 1;
}
.tab-outline2 .tab-content {
  border: 1px solid #ebebeb;
}
.tab-outline2 .nav-tabs {
  border-bottom: 0;
}
.tab-outline2 .nav-link {
  padding: 1.5rem 2.4rem;
  border: 1px solid transparent;
}
.tab-outline2 .nav-item.show .nav-link,
.tab-outline2 .nav-item .nav-link.active,
.tab-outline2 .nav-item:hover .nav-link {
  border-color: #ebebeb;
  border-bottom-color: #fff;
}
.tab-vertical {
  display: flex;
}
.tab-vertical .nav-tabs {
  flex-flow: column nowrap;
  width: 27.8%;
  border: 0;
  border-right: 2px solid #ebebeb;
}
.tab-vertical .tab-content {
  flex: 1;
  padding-left: 2rem;
}
.tab-vertical .nav-item {
  margin-right: 0;
}
.tab-vertical .nav-link {
  position: relative;
  margin-right: -2px;
  padding-left: 2rem;
  width: calc(100% + 2px);
  border: 0;
  text-align: left;
}
.tab-vertical .nav-link::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 2px;
  height: calc(100% - 6px);
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  z-index: 1;
}
.tab-vertical .tab-pane {
  padding: 1.7rem 0;
}
.tab-vertical.tab-simple .nav-link {
  margin-right: -2px;
}
.tab-vertical.tab-simple .nav-link::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 2px;
  height: calc(100% - 6px);
  top: 50%;
  transform: translateY(-50%) scale(0);
  background-color: transparent;
  transition: transform 0.3s;
  z-index: 1;
}
.tab-vertical.tab-simple .nav-item.show .nav-link,
.tab-vertical.tab-simple .nav-item .nav-link.active,
.tab-vertical.tab-simple .nav-item:hover .nav-link {
  color: #00a567;
}
.tab-vertical.tab-simple .nav-item.show .nav-link::after,
.tab-vertical.tab-simple .nav-item .nav-link.active::after,
.tab-vertical.tab-simple .nav-item:hover .nav-link::after {
  background-color: #00a567;
  transform: translateY(-50%) scale(1);
}

/* SIDE BAR FOR ACCOUNTS */
.sideCol {
  margin-top: 2%;
}
.sideCol2 {
  margin-top: 2%;
}
.sideCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #343a40;
  padding-top: 12px;
  padding-bottom: 5%;
  padding-left: 8%;
  padding-right: 8%;
  font-family: proxima;
  cursor: pointer;
  margin: 0px;
}
.c-btns {
  background-color: rgba(40, 40, 40, 0.5);
  padding: 8px;
  border-radius: 99px !important;
  cursor: pointer;
  font-size: 0;
  transform: translate3d(0, -50%, 0);
  top: 50%;
  position: absolute;
  outline: 0;
  border: 0px !important;
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
}
.c-btns:active {
  background-color: rgba(40, 40, 40, 0.5) !important;
}
.homeCat {
  padding: 2%;
  overflow: hidden;
}

.homeCat:hover .c-btns {
  visibility: visible;
  opacity: 1;
}

.activeS {
  background-color: #ededed;
}
.sideIcon {
  margin-left: 5px;
  margin-right: 15px;
  height: 7%;
  width: 7% !important;
}
.sideSub {
  font-family: proximaS;
  font-size: 115%;
  margin-top: 2%;
  margin-bottom: 3%;
}
.accountCard {
  max-width: 400px;
  margin-bottom: 4%;
  padding: 0px;
  border: 1px solid rgb(215, 214, 214);
  box-shadow: none;
}
.orderCard {
  margin-bottom: 4%;
  padding: 0px;
}
.orderDtlsCard {
  margin-bottom: 4%;
  max-width: 390px;
  padding: 0px;
  box-shadow: none;
  border: 1px solid rgb(215, 214, 214);
}
.accountCardHeader {
  background-color: transparent;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: center;
}
.homeCardHeader {
  background-color: transparent;
  border-bottom: 1px solid #ededed;
}
.accountCardBody {
  padding: 4%;
  margin-bottom: 8%;
}
.orderCardBody {
  padding: 4%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.accountCardFooter {
  background-color: transparent;
}
.sideCardFooter {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sumRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.accontButton {
  border: 0px;
  border-radius: 5px !important;
}
.orderImage {
  width: 25%;
}

.orderButton {
  border: 0px;
  border-radius: 5px !important;
  width: 50% !important;
}
.deliveredButton {
  background-color: #00a567;
  border: 0px;
  border-radius: 5px !important;
  width: 40% !important;
  margin-top: 2%;
}
.statusButton {
  border: 0px;
  border-radius: 5px !important;
  width: 40% !important;
  margin-top: 2%;
  text-transform: uppercase;
}
.deliveredButton:hover,
.deliveredButton:focus {
  background-color: #00a567;
}

.savedButton {
  background-color: #00a567;
  border: 0px;
  border-radius: 5px !important;
  width: 100% !important;
  margin-top: 2%;
}
.savedButton:hover,
.savedButton:focus {
  background-color: #00a567;
}
.mainMargin {
  margin-top: 8%;
}

@media (max-width: 767px) {
  .mainMargin {
    margin-top: 15%;
  }
  .orderImage {
    width: 100%;
    margin-bottom: 4%;
  }
  .orderCardBody {
    flex-direction: column;
  }
  .deliveredButton {
    width: 100% !important;
    margin-top: 4%;
  }
  .statusButton {
    width: 100% !important;
  }
  .tab-vertical {
    flex-flow: column nowrap;
  }
  .tab-vertical .tab-content,
  .tab-vertical .nav-tabs {
    width: 100%;
  }
  .tab-vertical .nav-tabs {
    border: 0;
  }
  .tab-vertical .tab-content {
    padding-left: 0;
  }
  .tab-vertical.tab-simple .nav-link::after {
    height: 2px;
    width: 100%;
    background-color: transparent;
    top: calc(100% - 2px);
    transform: scale(0);
  }
  .tab-vertical.tab-simple .nav-item.show .nav-link::after,
  .tab-vertical.tab-simple .nav-item .nav-link.active::after,
  .tab-vertical.tab-simple .nav-item:hover .nav-link::after {
    transform: scale(1);
  }
}
.tab-inverse .tab-content {
  border-color: #fff;
}
.tab-inverse .nav-tabs {
  border: 0;
}
.tab-inverse .nav-link {
  margin: 0;
  width: 100%;
}
.tab-inverse .nav-item.show .nav-link,
.tab-inverse .nav-item .nav-link.active,
.tab-inverse .nav-item:hover .nav-link {
  background-color: #fff;
  border-color: #fff;
}
.tab-inverse.tab-simple .nav-link {
  padding-top: 1.4rem;
  border-top: 2px solid transparent;
}
.tab-inverse.tab-simple .nav-item.show .nav-link,
.tab-inverse.tab-simple .nav-item .nav-link.active,
.tab-inverse.tab-simple .nav-item:hover .nav-link {
  border-top-color: #00a567;
}
.tab-nav-solid .nav-link {
  padding-bottom: 1.6rem;
  border: 0;
}
.tab-nav-solid .nav-item.show .nav-link,
.tab-nav-solid .nav-item .nav-link.active,
.tab-nav-solid .nav-item:hover .nav-link {
  color: #fff;
  background-color: #00a567;
  border-color: #00a567;
}

@media (max-width: 767px) {
  .nav-link {
    font-size: 1.4rem;
  }
  .tab-nav-boxed .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 479px) {
  .tab-nav-round .nav-link {
    margin-bottom: 0.5rem;
  }
}
.nav-filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #666;
}
.nav-filters li:not(:last-child) {
  margin-right: 1.8rem;
}
.nav-filter {
  display: inline-block;
  position: relative;
  padding: 0.5rem 0;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: 1;
  transition: color 0.3s;
}
.nav-filter.active {
  color: #00a567;
}
.filter-underline .nav-filter {
  border-bottom: 1px solid transparent;
  transition: color 0.3s, border-color 0.3s;
}
.filter-underline .nav-filter.active {
  border-color: #00a567;
}
.testimonial {
  font-family: "Open Sans", sans-serif;
}
.testimonial blockquote {
  position: relative;
  margin-bottom: 2rem;
  padding: 1.5rem 2rem;
  font-size: 1.4rem;
  line-height: 1.75;
  color: #666;
  background-color: #f6f7f9;
  overflow: visible;
}
.testimonial blockquote::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 30px;
  display: block;
  width: 12px;
  height: 12px;
  background-color: #f6f7f9;
  transform: rotate(-45deg) translateY(60%);
}
.testimonial-info {
  display: flex;
  align-items: center;
  padding: 0 2rem;
}
.testimonial-info .testimonial-author-thumbnail {
  margin-right: 1.5rem;
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: 50%;
}
.testimonial-info img {
  width: 100%;
  height: auto;
}
.testimonial-info cite {
  font-size: 1.4rem;
  font-weight: 400;
  font-style: normal;
  color: #222;
  line-height: 1;
}
.testimonial-info cite span {
  display: block;
  margin-top: 0.7rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: none;
  color: #999;
  line-height: 1;
}
.testimonial-inversed blockquote::after {
  right: 45px;
  left: auto;
}
.testimonial-inversed .testimonial-author-thumbnail {
  order: 2;
  margin-left: 1.5rem;
  margin-right: 0;
}
.testimonial-inversed .testimonial-info {
  justify-content: flex-end;
}
.testimonial-inversed cite {
  text-align: right;
}
.testimonial-centered {
  padding: 3.8rem 3rem;
  background-color: #f6f7f9;
  text-align: center;
}
.testimonial-centered .testimonial-info {
  display: block;
  margin: 0;
  padding: 0;
}
.testimonial-centered .testimonial-author-thumbnail {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  width: 7rem;
  height: 7rem;
}
.testimonial-centered blockquote {
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
}
.testimonial-centered blockquote::after {
  content: none;
}
.testimonial-centered .testimonial-title {
  margin-bottom: 2.6rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 1.2;
}
.testimonial-centered blockquote {
  padding: 0;
}
.testimonial-centered cite span {
  margin-top: 0.8rem;
}
.testimonial-centered.testimonial-border {
  background-color: transparent;
  box-shadow: 0 0 0 2px #edeef0 inset;
}
.testimonial-bg {
  padding: 2rem 2rem 0.8rem;
  background-color: transparent;
}
.testimonial-bg .testimonial-author-thumbnail {
  margin-bottom: 2.3rem;
}
.testimonial-bg blockquote {
  max-width: 86rem;
  color: rgba(255, 255, 255, 0.8);
}
.testimonial-bg cite {
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
}
.testimonial-bg cite span {
  color: rgba(255, 255, 255, 0.8);
}
.tooltiptext {
  position: absolute;
  padding: 1rem 0.7rem;
  bottom: 150%;
  left: 48%;
  background-color: #333;
  color: #fff;
  font: 400 1.3rem "Open Sans", sans-serif;
  letter-spacing: 0.01em;
  text-align: center;
  visibility: hidden;
  opacity: 1;
  transform: translateX(-50%);
  z-index: 1;
  transition: opacity 0.3s;
}
.tooltip-top::after {
  content: "";
  position: absolute;
  top: 96%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}
.title {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  text-align: center;
  margin-bottom: 2.6rem;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: -0.01em;
  color: #222;
}
.title::before,
.title::after {
  flex: 1;
  content: "";
  height: 2px;
  background-color: #edeef0;
}
.title::before {
  margin-right: 3rem;
}
.title::after {
  margin-left: 3rem;
}
@media (max-width: 479px) {
  .title::before {
    margin-right: 1.5rem;
  }
  .title::after {
    margin-left: 1.5rem;
  }
}
.title-wrapper .title {
  margin-bottom: 0.5rem;
}
.title-wrapper p {
  margin-bottom: 2.3rem;
  font-size: 1.4rem;
  line-height: 1.71;
  color: #999;
}
.title-simple {
  display: block;
}
.title-simple::before,
.title-simple::after {
  content: none;
}
.title-line:before {
  content: none;
}
.title-underline {
  display: block;
  position: relative;
  text-align: left;
}
.title-underline:after {
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.title-underline:before {
  content: none;
}
.title-underline span {
  display: inline-block;
  position: relative;
  z-index: 2;
  line-height: 2.28;
}
.title-underline span::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  background-color: #00a567;
}
.title-icon {
  flex-direction: column;
}
.title-icon > i {
  margin-top: 1rem;
  font-size: 1.4rem;
  color: #00a567;
}
.title-icon::before,
.title-icon::after {
  content: none;
}
.title-white {
  color: #fff;
}
.title-white::before,
.title-white::after {
  background-color: #fff;
  opacity: 1.1;
}
.title-white > .title {
  color: #fff;
}
.title-white > .title::before,
.title-white > .title::after {
  background-color: #fff;
  opacity: 1.1;
}
.title-white > p {
  color: #fff;
  opacity: 1.5;
}
.title-link {
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #e1e1e1;
}
.title-link::before,
.title-link::after {
  content: none;
}
.title-link a {
  color: #444;
  font-size: 1.2rem;
}
.title-link a:hover {
  color: #00a567;
}
.title-link i {
  font-size: 1rem;
}
.with-link {
  display: flex;
  justify-content: space-between;
  line-height: 1.2;
}
.with-link::after {
  background-color: #edeef0;
}
.with-link a {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  font-size: 1.2rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: -0.025em;
  color: #444;
  transition: color 0.3s;
}
.with-link a:hover {
  color: #00a567;
}
.with-link i {
  margin-left: 0.5rem;
  font-size: 1.1rem;
  line-height: 0;
}
.widget-title {
  margin-bottom: 2rem;
  padding: 1.1rem 0;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
  border-bottom: 1px solid #e1e1e1;
  letter-spacing: -0.025em;
}
.widget-products .widget-title {
  padding: 1.6rem 0 1.1rem;
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.header {
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.025em;
  background: #fff;
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.28);
}
.header .container,
.header .container-fluid,
.header .inner-wrap {
  display: flex;
  align-items: center;
}
.header .inner-wrap {
  width: 100%;
}
.header-transparent {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 20;
  background: transparent;
}
.header-transparent .fixed {
  background: #222;
}
.header-top,
.header-middle,
.header-bottom {
  display: flex;
}
.header-border {
  border-bottom: 1px solid #eee;
}
.has-center .header-left,
.has-center .header-right {
  flex: 1;
}
.has-center .header-right {
  justify-content: flex-end;
}
.has-center .header-center {
  margin-left: 2rem;
  margin-right: 2rem;
}
.header-left,
.header-right,
.header-center {
  display: flex;
  align-items: center;
}
.header-right {
  margin-left: auto;
}
.header-top {
  background: #f2f3f5;
  font-size: 1.1rem;
  letter-spacing: -0.014em;
  text-transform: uppercase;
}
.header-top .header-right > * {
  margin-left: 2rem;
}
.header-top .header-right > *:first-child {
  margin-left: 0;
}
.currency-dropdown a,
.language-dropdown a {
  white-space: nowrap;
}
.header-middle {
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: #222;
  background: #fff;
  font-size: 1.3rem;
  font-weight: 700;
}
.header-middle .logo {
  margin-right: 4.1rem;
}
.header-middle .main-nav {
  margin-right: 1.5rem;
}
.header-middle .header-right .cart-dropdown {
  margin-right: 0;
}

.logo {
  max-width: 200px;
}
.logo img {
  display: block;
}
.header-bottom {
  background: #fff;
  font-size: 1.3rem;
}
.welcome-msg {
  margin: 0;
  padding: 7px 0;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  line-height: 1.5;
  white-space: nowrap;
}
.header-search {
  position: relative;
}
.header-search .input-wrapper {
  display: flex;
  position: relative;
}
.header-search input.form-control {
  flex: 1;
  min-width: 40px;
  padding: 1.1rem 1.5rem;
  border: 0;
  background-color: #eee;
  font-size: 1.2rem;
  letter-spacing: inherit;
}
.header-search .btn-search {
  padding: 0;
  min-width: 40px;
  background-color: #00a567;
  border: 0;
  color: #fff;
  font-size: 1.4rem;
}
.header-search .btn-search i {
  margin: 0;
}
.header-search .select-box {
  background-color: #f4f4f4;
}
.header-search .select-box select {
  position: relative;
  max-width: 116px;
  padding-left: 20px;
  padding-right: 15px;
  border: 0;
  font-weight: 600;
  z-index: 1;
}
.header-search.hs-toggle .input-wrapper {
  position: absolute;
  right: -1.5rem;
  top: 100%;
  min-width: 30rem;
  padding: 2rem;
  height: 8rem;
  background-color: #fff;
  box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 1;
  transform: translate3d(0, -10px, 0);
  z-index: 100;
  transition: transform 0.4s 0.2s, visibility 0.4s 0.2s, opacity 0.4s 0.2s;
}
.header-search.hs-toggle::after {
  content: "";
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: calc(100% - 19px);
  transform: translate3d(-50%, -5px, 0);
  border: 11px solid transparent;
  border-bottom: 11px solid #fff;
  visibility: hidden;
  opacity: 1;
  transition: transform 0.4s 0.2s, visibility 0.4s 0.2s, opacity 0.2s 0.2s;
}
.header-search.hs-toggle:hover .input-wrapper,
.header-search.hs-toggle.show .input-wrapper {
  transform: translate3d(0, 0, 0);
  visibility: visible;
  transition: transform 0.4s;
  opacity: 1;
}
.header-search.hs-toggle:hover::after,
.header-search.hs-toggle.show::after {
  visibility: visible;
  transform: translate3d(-50%, 0, 0);
  opacity: 1;
  transition: transform 0.4s;
}
.header-search.hs-toggle:hover {
  color: #00a567;
}
.header-search.hs-expanded {
  max-width: 53rem;
  width: 100%;
}
.header-search.hs-expanded .input-wrapper {
  width: 100%;
}
.header-search.hs-expanded .select-box {
  width: 13.2rem;
  margin-right: 2px;
  border-radius: 2rem 0 0 2rem;
  color: #444;
}
.header-search.hs-expanded .select-box,
.header-search.hs-expanded .select-box select {
  cursor: pointer;
}
.header-search.hs-expanded .select-box::before {
  right: 15px;
}
.header-search.hs-expanded input.form-control {
  flex: 1;
  color: #ababab;
  background-color: #f4f4f4;
  margin-right: 2px;
}
.header-search.hs-expanded .btn-search {
  padding-right: 5px;
  border-radius: 0 1.9rem 1.9rem 0;
  background-color: #f4f4f4;
  color: #444;
  font-size: 1.6rem;
}
.header-search.hs-expanded .btn-search:hover {
  color: #00a567;
}
.header-search.search-left .input-wrapper {
  left: 0;
  right: auto;
}
.header-search.hs-simple {
  max-width: 26rem;
  width: 100%;
}
.header-search.hs-simple input.form-control {
  padding: 0 4rem 0 2rem;
  border-radius: 25px;
  border: 1px solid #e1e1e1;
  font-family: inherit;
  background-color: #fff;
  color: #999;
}
.header-search.hs-simple .btn-search {
  position: absolute;
  background: transparent;
  color: #333;
  min-width: 43px;
  height: 100%;
  border-radius: 25px;
  right: 0;
}
.header-search.hs-simple .btn-search:hover {
  color: #00a567;
}
.mobile-search {
  display: none;
}
.mobile-search .search-toggle i {
  font-size: 2rem;
  vertical-align: middle;
}
.hs-toggle.dir-up::after {
  top: auto;
  bottom: 100%;
  border-bottom-color: transparent;
  border-top-color: #fff;
}
.hs-toggle.dir-up .input-wrapper {
  top: auto;
  bottom: 100%;
  transform: translate3d(0, 10px, 0);
}
.search-toggle {
  display: inline-block;
  vertical-align: middle;
  padding: 1.1rem 0;
  transition: color 0.3s;
}
.search-toggle span {
  cursor: pointer;
}
.search-toggle i {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 0.9;
}
.search-toggle.with-label {
  display: inline-flex;
  align-items: center;
}
.search-toggle.with-label i {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
  font-size: 1.6rem;
}
.search-toggle.with-label span {
  margin-left: 0.5rem;
  cursor: pointer;
  font-weight: inherit;
  letter-spacing: inherit;
  text-transform: uppercase;
}
.header .login,
.header .call {
  margin-right: 1.5rem;
}
.login {
  display: flex;
  align-items: center;
}
.login span {
  cursor: pointer;
  font-weight: inherit;
  text-transform: uppercase;
}
.login i {
  margin-top: -2px;
  font-size: 1.7rem;
  line-height: 1;
}
.login i + span {
  margin-left: 0.9rem;
}
.login:hover {
  color: #00a567;
}
.call {
  display: flex;
  align-items: center;
}
.call span {
  font-weight: inherit;
  text-transform: uppercase;
  cursor: pointer;
}
.call i + span {
  margin-left: 0.9rem;
}
.call i {
  margin: 0 0.2rem 0.2rem 0;
  font-size: 1.8rem;
  cursor: pointer;
}
.call:hover {
  color: #00a567;
}
.wishlist {
  display: inline-flex;
  align-items: center;
  transition: color 0.3s;
}
.wishlist span {
  font-weight: inherit;
  cursor: pointer;
}
.wishlist i {
  cursor: pointer;
}
.wishlist:hover {
  color: #00a567;
}
@keyframes fixedTop {
  from {
    transform: translateY(-100%);
    transform-origin: center top 0px;
  }
  to {
    transform: translateY(0);
  }
}
@keyframes fixedBottom {
  from {
    transform: translateY(100%);
    transform-origin: center top 0px;
  }
  to {
    transform: translateY(0);
  }
}
.sticky-header.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1100;
  animation: fixedTop 0.4s;
}
@media (max-width: 991px) {
  .sticky-wrapper {
    height: auto;
  }
}
.mobile-menu-toggle {
  display: none;
  font-size: 2.5rem;
  color: #222;
}
.mobile-menu-toggle.menu-bar::before,
.mobile-menu-toggle.menu-bar::after {
  content: "";
  display: block;
  width: 100%;
}
.mobile-menu-toggle.menu-bar::before {
  height: 11px;
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.mobile-menu-toggle.menu-bar::after {
  height: 9px;
  border-bottom: 2px solid;
}
@media (max-width: 1199px) {
  .main-nav .menu > li {
    margin-right: 1.5rem;
  }
  .header-middle .logo {
    margin-right: 2rem;
  }
  .header-middle .divider {
    display: none;
  }
}
@media (max-width: 1023px) {
  .header .login span,
  .header .wishlist span {
    display: none;
  }
}
@media (max-width: 991px) {
  .header .main-nav,
  .header .divider,
  .header .login {
    display: none;
  }
  .header-top .header-left {
    margin-right: 2rem;
  }
  .header-middle .header-left,
  .header-middle .header-right {
    flex: 1;
  }
  .header-middle .header-right {
    justify-content: flex-end;
  }
  .header-middle .header-center {
    margin-left: auto;
    margin-right: auto;
  }
  .header-middle .logo {
    margin-right: 0;
  }
  .header-middle .header-search {
    display: none;
  }
  .mobile-menu-toggle {
    display: block;
  }
}
@media (max-width: 767px) {
  .header .cart-dropdown {
    display: none;
  }
  .header-middle .mobile-search {
    display: block;
  }
}
@media (max-width: 480px) {
  .header-top .header-left {
    position: relative;
    overflow: hidden;
  }
  .welcome-msg {
    transform: translateX(0);
    animation: 6s linear 2s 1 show_msg_first, 12s linear 8s infinite show_msg;
  }
}
@keyframes show_msg_first {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes show_msg {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.label-down {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.label-down i {
  display: inline-block;
}
.label-down span {
  display: block;
}
.label-down i + span {
  margin-top: 0.7rem;
}
.mobile-link {
  display: block;
  margin-right: 0;
  margin-bottom: -3px;
}
.mobile-link i {
  font-size: 2.4rem;
}
.mobile-link + .mobile-link {
  margin-left: 2rem;
}
.mobile-link:hover {
  color: #00a567;
}
@media (min-width: 768px) {
  .mobile-link {
    display: none;
  }
  .mobile-menu-toggle {
    color: #00a567;
  }
}
.dropdown {
  position: relative;
}
.dropdown:hover .dropdown-box,
.dropdown.show .dropdown-box {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.dropdown:hover::after,
.dropdown.show::after {
  visibility: visible;
  opacity: 1;
  top: calc(100% - 20px);
  transform: translate3d(-50%, 0, 0);
}
.dropdown:hover .dropdown-box,
.dropdown.show .dropdown-box {
  transform: translate3d(0, 0, 0);
}
.dropdown:hover > a,
.dropdown.show > a {
  color: #00a567;
}
.dropdown::after {
  content: "";
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: -9999px;
  transform: translate3d(-50%, -8px, 0);
  border: 11px solid transparent;
  border-bottom: 11px solid #fff;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  visibility: hidden;
  opacity: 1;
  cursor: pointer;
}
.dropdown a {
  display: flex;
  align-items: center;
}
.dropdown a .dropdown-image {
  max-width: 1.4rem;
  margin-right: 0.7rem;
  height: auto;
}
.dropdown > a {
  line-height: 1.1;
  padding: 9px 0;
}
.dropdown > a::after {
  display: inline-block;
  margin-left: 6px;
  font-weight: 600;
  font-size: 8px;
  line-height: 1;
  content: "\f078";
}
.dropdown li.active > a,
.dropdown li:hover > a {
  color: #00a567;
}
.dropdown.dir-up::after {
  border-bottom-color: transparent;
  border-top: 11px solid #fff;
  transform: translate3d(-50%, 8px, 0);
}
.dropdown.dir-up:hover .dropdown-box,
.dropdown.dir-up.show .dropdown-box {
  top: auto;
  bottom: 100%;
}
.dropdown.dir-up:hover::after,
.dropdown.dir-up.show::after {
  top: auto;
  bottom: calc(100% - 20px);
  transform: translate3d(-50%, 0, 0);
}
.dropdown-box {
  position: absolute;
  right: 0;
  top: -9999px;
  margin: 0;
  padding: 0.5rem 0;
  color: #666;
  background-color: #fff;
  box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  visibility: hidden;
  opacity: 1;
  transition: transform 0.2s ease-out, opacity 0.2s, visibility 0.2s;
  transform: translate3d(0, -10px, 0);
}
.dropdown-box a {
  padding: 0.6rem 1rem;
}
.dropdown-box li {
  font-size: inherit;
  line-height: 1.1;
}
.dir-up .dropdown-box {
  transform: translate3d(0, 10px, 0);
}
@media (min-width: 992px) {
  .dropdown-expanded::after {
    content: none;
  }
  .dropdown-expanded > a {
    display: none;
  }
  .dropdown-expanded .dropdown-box {
    position: static;
    display: flex;
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    box-shadow: none;
    border: 0;
    padding: 9px 0;
    transform: none;
    color: inherit;
  }
  .dropdown-expanded .dropdown-box a {
    padding: 0;
  }
  .dropdown-expanded .dropdown-box > li {
    margin-right: 1.95rem;
  }
  .dropdown-expanded .dropdown-box > li:last-child {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .dropdown.dropdown-expanded li:hover > a {
    color: #00a567;
  }
}
.cart-dropdown .cart-toggle {
  padding: 0.6rem 0;
}
.cart-dropdown .cart-toggle::after {
  content: none;
}
.cart-dropdown .cart-label {
  display: block;
  cursor: pointer;
  margin: 0 0.7rem 0 0;
  font-weight: inherit;
  text-transform: uppercase;
  letter-spacing: inherit;
}
.cart-dropdown .cart-name {
  margin-right: 5px;
}
.cart-dropdown .cart-name:after {
  content: "/";
  margin-left: 6px;
}
.cart-dropdown .cart-price {
  color: #00a567;
}
.cart-dropdown .minicart-icon {
  display: inline-block;
  font-size: 1.2rem;
  color: #00a567;
}
.cart-dropdown .minicart-icon2 {
  display: inline-block;
  font-size: 1.2rem;
  color: #00a567;
}
.cart-dropdown .cart-count {
  display: inline-block;
  transition: color 0.4s;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 25px;
  color: #00a567;
}
.cart-dropdown .cart-total {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-top: 1px solid #edeef0;
  border-bottom: 1px solid #edeef0;
  font-size: 1.4rem;
  line-height: 3;
  letter-spacing: normal;
}
.cart-dropdown .cart-total label {
  margin-right: 0.5rem;
  line-height: inherit;
  color: #999;
  font-weight: 400;
  font-family: Poppins, sans-serif;
}
.cart-dropdown .cart-total .price {
  font-weight: 700;
  color: #222;
}
.cart-dropdown .cart-action {
  column-gap: 10px;
}
.cart-dropdown .cart-action .btn {
  display: flex;
  justify-content: center;
  border-radius: 3px;
  padding: 0.53em 2em;
  line-height: 1.5;
  letter-spacing: 0.01em;
}
.cart-dropdown:hover .minicart-icon {
  background: #00a567;
}
.cart-dropdown:hover .minicart-icon::before {
  transform: rotateY(180deg);
}
.cart-dropdown:hover .cart-count {
  color: #fff;
}
.cart-dropdown .dropdown-box {
  padding: 3rem;
  min-width: 33.5rem;
}
.cart-dropdown .products {
  max-height: 360px;
  overflow-x: hidden;
  margin-right: -5px;
  padding-right: 5px;
}
.cart-dropdown.cart-dropdown-white .cart-label,
.cart-dropdown.cart-dropdown-white .cart-price,
.cart-dropdown.cart-dropdown-white .cart-count {
  color: #fff;
}
.cart-dropdown.cart-dropdown-white .minicart-icon {
  border-color: #fff;
}
.cart-dropdown.cart-dropdown-white:hover .minicart-icon {
  background-color: #fff;
}
.cart-dropdown.cart-dropdown-white:hover .cart-count {
  color: #00a567;
}
.cart-dropdown.type2 .cart-toggle {
  flex-direction: column;
  align-items: center;
}
.cart-dropdown.type2 .cart-label {
  margin: 0;
}
.cart-dropdown.type2:hover {
  color: #00a567;
}
.cart-dropdown.type2 .cart-count {
  position: absolute;
  right: 0;
  top: 0px;
  width: 1.9rem;
  height: 1.9rem;
  text-align: center;
  line-height: 1.8rem;
  font-size: 1.1rem;
  border-radius: 50%;
  background-color: #00a567;
  color: #fff;
  z-index: 1;
}
.product.product-cart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.3rem;
  border-bottom: 1px solid #edeef0;
}
.product.product-cart-header span,
.product.product-cart-header a {
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: -0.025em;
  line-height: 1;
  color: #222529;
}
.product.product-cart-header a {
  padding: 0;
  padding-bottom: 0;
  color: #222529;
  line-height: 1;
}
.product.product-cart-header a:hover {
  text-decoration: underline;
}
.product.product-cart {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  font-size: 1.3rem;
}
.product.product-cart .product-media {
  position: relative;
  width: 9rem;
  height: 9rem;
  margin-right: 1rem;
}
.product.product-cart .product-media a {
  padding: 0;
}
.product.product-cart .product-detail {
  flex: 1;
  margin: 0 1rem 0rem 0;
}
.product.product-cart .product-name {
  white-space: normal;
  padding: 0;
  margin-bottom: 0.8rem;
  font-size: 1.3rem;
  line-height: 1.54;
  letter-spacing: -0.35px;
  font-family: Poppins, sans-serif;
  color: #666;
}
.product.product-cart .product-name:hover {
  color: #00a567;
}
.product.product-cart .price-box {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
}
.product.product-cart .product-price {
  font-size: 1.4rem;
  letter-spacing: -0.35px;
}
.product.product-cart .product-quantity {
  align-items: center;
  display: flex;
  margin-right: 0.8rem;
  color: #999;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Poppins, sans-serif;
}
.product.product-cart .product-quantity::after {
  margin-left: 0.8rem;
  font-size: 1.1rem;
  content: "X";
  text-transform: none;
  line-height: 0;
  font-weight: 600;
  margin-bottom: 1px;
}
.product.product-cart .product-price {
  margin: 0;
  color: #d26e4b;
  font-family: Poppins, sans-serif;
}
.product.product-cart .btn-close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 2.2rem;
  height: 2.2rem;
  top: -0.9rem;
  right: -1rem;
  border-radius: 50%;
  background-color: #fff;
  color: #222;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  font-size: 1.2rem;
}
.product.product-cart .btn-close i {
  margin-right: 4px;
  margin-top: 0px;
}
.product.product-cart:last-child {
  margin-bottom: 0;
}
.product.product-cart img {
  width: 9rem;
  height: 9rem;
}
@media (max-width: 991px) {
  .cart-dropdown .cart-label {
    display: none;
  }
}
@media (max-width: 575px) {
  .sticky-footer .dropdown-box {
    right: 1.5rem;
  }
  .cart-dropdown .product .product-media {
    margin-right: 1rem;
  }
  .cart-dropdown .dropdown-box {
    padding: 3rem;
    min-width: 29.4rem;
  }
  .cart-dropdown .cart-total {
    font-size: 1.3rem;
  }
  .cart-dropdown .cart-action .btn {
    padding: 0.53em 0;
    display: flex;
    justify-content: center;
  }
}
.category-dropdown > a {
  padding: 1.7rem 1.7rem;
  background: #00a567;
}
.category-dropdown > a::after {
  content: none;
}
.category-dropdown > a i {
  font-size: 1.8rem;
}
.category-dropdown > a span {
  margin-left: 1rem;
}

span {
  font-family: proxima;
}
.category-dropdown .dropdown-box {
  padding: 0;
  left: 0;
  min-width: 28rem;
  box-shadow: none;
  background-color: #f4f4f4;
  transition: opacity 0.2s, z-index 0s, transform 0.2s ease-out;
  visibility: hidden;
  top: 100%;
}
.category-dropdown::before,
.category-dropdown::after {
  left: 25px;
}
.category-dropdown::after {
  border-bottom-color: #f4f4f4;
  visibility: hidden;
  top: calc(100% - 20px);
}
.category-dropdown.menu-fixed .dropdown-box,
.category-dropdown.menu-fixed::after {
  visibility: hidden;
}
.category-dropdown.dropdown.show .dropdown-box {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform: translate3d(0, 0, 0);
  transition: opacity 0.5s, z-index 0s, transform 0s;
}
.category-dropdown.dropdown.show .dropdown-box,
.category-dropdown.dropdown.show::after {
  visibility: visible;
}
.category-dropdown.dropdown.show::after {
  transform: translate3d(-50%, 0, 0);
}
.category-dropdown.has-border::after {
  border-bottom-color: #fff;
}
.category-dropdown.has-border::before {
  content: "";
  position: absolute;
  z-index: 1000;
  top: -9999px;
  transform: translateX(-50%);
  border: 11px solid transparent;
  border-bottom: 11px solid #e1e1e1;
  transition: opacity 0.4s ease;
  visibility: hidden;
  opacity: 1;
  cursor: pointer;
}
.category-dropdown.has-border .dropdown-box {
  background-color: #fff;
  border: 1px solid #e1e1e1;
}
.category-dropdown.has-border.menu-fixed::before {
  visibility: visible;
  opacity: 1;
}
.sticky-header:not(.fixed) .category-dropdown.menu-fixed::after {
  top: 100%;
  transform: translate3d(-50%, 0, 0);
}
.sticky-header:not(.fixed) .category-dropdown.menu-fixed .dropdown-box {
  top: calc(100% + 20px);
  transform: none;
}
.sticky-header:not(.fixed) .category-dropdown.menu-fixed .dropdown-box,
.sticky-header:not(.fixed) .category-dropdown.menu-fixed::after {
  visibility: visible;
  opacity: 1;
}
.sticky-header:not(.fixed) .category-dropdown.menu-fixed.has-border::before {
  top: calc(100% - 1px);
  visibility: visible;
  opacity: 1;
}
@media (-webkit-min-device-pixel-ratio: 1.5),
  (min--moz-device-pixel-ratio: 1.5),
  (min-device-pixel-ratio: 1.5) {
  .sticky-header:not(.fixed) .category-dropdown.menu-fixed.has-border::before {
    top: calc(100% - 2px);
  }
}
.menu {
  display: flex;
  align-items: center;
}
.menu a {
  display: inline-block;
}
.menu .menu-title {
  margin-bottom: 1rem;
  padding: 0 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
}
.menu ul {
  padding: 2rem 0;
  background: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0;
  color: #666;
}
.menu li {
  position: relative;
  line-height: 1.5;
}
.menu li a {
  padding: 0.7rem 0 0.7rem 1rem;
}
.menu li > ul,
.menu li .megamenu {
  position: absolute;
  top: -9999px;
  left: 100%;
  margin: 0;
  box-shadow: 0 2px 35px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  visibility: hidden;
  opacity: 1;
  transition: transform 0.2s ease-out;
  transform: translate3d(0, -10px, 0);
}
.menu li > ul {
  min-width: 22.6rem;
  background: #fff;
}
.menu li > ul li {
  padding: 0 2rem;
}
.menu li:hover > a:not(.menu-title),
.menu .active > a:not(.menu-title) {
  color: #00a567;
}
.menu li:hover > ul,
.menu li:hover .megamenu,
.menu .show > ul,
.menu .show .megamenu {
  visibility: visible;
  opacity: 1;
  top: -2rem;
  transform: translate3d(0, 0, 0);
}
.menu > li {
  margin-right: 2.3rem;
}
.menu > li:last-child {
  margin-right: 0;
}
.menu > li > a {
  position: relative;
  padding: 1.4rem 0;
  font-weight: 700;
  letter-spacing: inherit;
  line-height: 1;
  text-transform: uppercase;
}
.menu > li > a::after {
  margin-left: 0.6rem;
}
.menu > li > ul,
.menu > li .megamenu {
  left: -1.9rem;
}
.menu > li:hover > ul,
.menu > li:hover .megamenu,
.menu > li.show > ul,
.menu > li.show .megamenu {
  top: 100%;
}
.menu > li:hover::after,
.menu > li.show::after {
  visibility: visible;
  opacity: 1;
  top: calc(100% - 20px);
  transform: translate3d(-50%, 0, 0);
}
.menu .submenu > a {
  position: relative;
  display: block;
}
.menu > .submenu > a {
  z-index: 1001;
}
.menu > .submenu > a::after {
  position: static;
  content: "\f078";
  margin-top: 0;
}
.menu > .submenu::after {
  content: "";
  position: absolute;
  z-index: 1000;
  left: 50%;
  top: -9999px;
  transform: translate3d(-50%, -8px, 0);
  border: 11px solid transparent;
  border-bottom: 11px solid #fff;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  visibility: hidden;
  opacity: 1;
  cursor: pointer;
}
.menu.no-arrow > li > a::after {
  content: none;
}
.submenu > a::after {
  display: inline-block;
  position: absolute;
  margin-top: 2px;
  right: 0.5rem;
  top: 50%;
  line-height: 0;
  vertical-align: middle;
  font-weight: 700;
  font-size: 0.8rem;
  color: inherit;
  content: "\f054";
}
.vertical-menu {
  display: block;
  min-width: 12rem;
}
.vertical-menu > li {
  margin-right: 0;
  padding: 0 2rem;
}
.vertical-menu > li > ul,
.vertical-menu > li .megamenu {
  transform: translate3d(-15px, 0, 0);
}
.vertical-menu > li > a {
  display: inline-block;
}
.vertical-menu > li > a::after {
  content: none;
}
.vertical-menu > li:hover > ul,
.vertical-menu > li:hover .megamenu,
.vertical-menu > li.show > ul,
.vertical-menu > li.show .megamenu {
  left: 100%;
  top: -1rem;
}
.vertical-menu > .submenu::after {
  border: 11px solid transparent;
  border-right: 11px solid #fff;
}
.vertical-menu > .submenu:hover::after,
.vertical-menu > .submenu.show::after {
  top: 50%;
  left: calc(100% - 20px);
  transform: translate3d(0, -50%, 0);
}
.vertical-menu > .submenu > a::after {
  position: absolute;
  line-height: 0;
  content: "\f054";
}
.vertical-menu.no-arrow > li {
  display: flex;
}
.vertical-menu.no-arrow > li > a::after {
  content: none;
}
.toggle-menu {
  display: block;
}
.toggle-menu > li {
  margin-right: 0;
}
.toggle-menu .submenu::after,
.toggle-menu .submenu > a::after {
  content: none;
}
.toggle-menu .submenu li {
  padding: 0 0.5rem;
}
.toggle-menu li > ul {
  display: none;
  position: static;
  visibility: visible;
  opacity: 1;
  transform: none;
  background: transparent;
  box-shadow: none;
}
.toggle-btn {
  display: block;
  position: absolute;
  padding: 6px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1.6;
}
.toggle-btn::before {
  content: "\f054";
  display: block;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  transition: transform 0.5s;
}
.show > a .toggle-btn::before {
  transform: rotate(630deg);
}
.menu-active-underline > li > a::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  height: 2px;
  border: 1px solid;
  transform-origin: right center;
  transform: scale(0, 1);
  transition: transform 0.3s;
}
.menu-active-underline > li.active > a,
.menu-active-underline > li:hover > a {
  color: inherit;
}
.menu-active-underline > li.active > a::before,
.menu-active-underline > li:hover > a::before {
  transform-origin: left center;
  transform: scale(1, 1);
}
.megamenu {
  display: flex;
  padding: 1rem;
  min-width: 61.8rem;
  background: #fff;
}
.megamenu ul {
  padding: 0;
}
.megamenu .row {
  flex: 1;
  padding: 0 1rem;
}
.megamenu .row > * {
  padding: 1.8rem 1rem 0.8rem;
}
.megamenu .menu-banner {
  padding: 0;
  overflow: hidden;
}
.megamenu .menu-banner figure {
  height: 100%;
}
.megamenu .menu-banner img {
  height: 100%;
  object-fit: cover;
}
.menu-banner1 .banner-content {
  left: 9%;
}
.menu-banner1 .banner-title,
.menu-banner1 .banner-subtitle {
  font-size: 3.6rem;
}
.menu-banner1 .banner-subtitle {
  margin-bottom: 0.4rem;
}
.menu-banner1 .banner-title {
  margin-bottom: 1.8rem;
  padding-left: 1.2rem;
  position: relative;
}
.menu-banner1 .banner-title span {
  display: inline-block;
  position: absolute;
  left: -0.9rem;
  top: 50%;
  font-size: 1.2rem;
  line-height: 1;
  transform: rotateZ(-90deg) translateX(0.6rem);
  letter-spacing: -0.1em;
}
.menu-banner2 .banner-content {
  bottom: 10%;
}
.menu-banner2 .banner-title {
  margin-bottom: 0.6rem;
  font-size: 2.6rem;
}
.menu-banner2 .banner-subtitle {
  font-size: 1.6rem;
}
.tip {
  display: inline-block;
  position: relative;
  top: -1px;
  left: 7px;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  border-radius: 2px;
}
.tip.tip-hot {
  background-color: #d26e4b;
}
.tip.tip-new {
  background-color: #00a567;
}
.mobile-menu-wrapper {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  font-family: "Open Sans", sans-serif;
  transition: visibility 0.4s;
}
.mobile-menu-container {
  max-width: 296px;
  padding: 2rem 1.5rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #222529;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(-296px);
  transition: transform 0.4s;
}
.mobile-menu-container .input-wrapper {
  display: flex;
  margin-bottom: 0.6rem;
  height: 4rem;
}
.mobile-menu-container .input-wrapper .form-control {
  color: #7a8088;
  border: 1px solid;
  border-color: #2e3237;
  border-right: 0;
  background-color: transparent;
}
.mobile-menu-container .input-wrapper .btn-search {
  padding: 0;
  width: 4rem;
  background-color: #00a567;
  border-color: #00a567;
  color: #fff;
  font-size: 1.4rem;
}
.mobile-menu-container .input-wrapper .btn-search i {
  margin: 0;
}
.mobile-menu-container .mobile-menu {
  margin-bottom: 0.5rem;
  background: #222529;
}
.mobile-menu-container .tab-content {
  background-color: transparent;
}
.mobile-menu-container .nav {
  border-width: 2px;
}
.mobile-menu-container .nav-link {
  margin-bottom: -2px;
  color: #fff;
  font-size: 14px;
  padding: 10px;
}
.mobile-menu-container .tab-pane {
  padding-top: 10px;
}
.mobile-menu-container .nav-item:hover .nav-link {
  color: #fff;
  border-color: transparent;
}
.mobile-menu-container .nav-item.show .nav-link,
.mobile-menu-container .nav-item .nav-link.active {
  color: #00a567;
  border-color: #00a567;
}
.mobile-menu-container .nav-item + .nav-item {
  margin-left: 1px;
}
.mobile-menu-container.scrollable::-webkit-scrollbar-thumb,
.mobile-menu-container .sidebar-content::-webkit-scrollbar-thumb {
  margin-right: 2px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 7px;
  cursor: pointer;
}
.mobile-menu {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.025em;
  color: #e1e1e1;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.025em;
}
.mobile-menu ul {
  display: none;
  width: 100%;
}
.mobile-menu > li:first-child {
  padding-top: 0.5rem;
}
.mobile-menu > li:last-child {
  padding-bottom: 0.5rem;
}
.mobile-menu li a {
  display: block;
  position: relative;
  padding: 1.3rem 0.6rem 1.3rem 1rem;
}
.bg-primary {
  background-color: #00a567 !important;
}

.mobile-menu li i {
  display: inline-block;
  margin-bottom: 1px;
  font-size: 2rem;
  margin-right: 0.5rem;
  line-height: 0;
  vertical-align: middle;
}
.mobile-menu li li a {
  padding-left: 2.4rem;
}
.mobile-menu li li li a {
  padding-left: 3.6rem;
}
.mobile-menu li:not(:last-child) {
  border-bottom: 1px solid #2e3237;
}
.mobile-menu .expanded,
.mobile-menu .active > a {
  background: #2e3237;
}
.mobile-menu-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
}
.mobile-menu-overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #0000008f;
  opacity: 1;
  transition: opacity 0.4s;
}
.mobile-menu-close {
  position: fixed;
  left: calc(100vw - 50px);
  top: 25px;
  transition: opacity 0.3s;
  opacity: 1;
}
.mobile-menu-close i {
  font-size: 2.8rem;
  color: #e1e1e1;
}
.mmenu-anim {
  transform: translateY(30%);
}
.mmenu-anim,
.mmenu-anim > li {
  transition: transform 0.4s, opacity 0.3s;
  transition-timing-function: cubic-bezier(0.5, 0, 0.3, 1);
}
.mmenu-anim > li:nth-child(0) {
  opacity: 1;
  transform: translateY(0px);
}
.mmenu-anim > li:nth-child(1) {
  opacity: 1;
  transform: translateY(50px);
}
.mmenu-anim > li:nth-child(2) {
  opacity: 1;
  transform: translateY(100px);
}
.mmenu-anim > li:nth-child(3) {
  opacity: 1;
  transform: translateY(150px);
}
.mmenu-anim > li:nth-child(4) {
  opacity: 1;
  transform: translateY(200px);
}
.mmenu-anim > li:nth-child(5) {
  opacity: 1;
  transform: translateY(250px);
}
.mmenu-anim > li:nth-child(6) {
  opacity: 1;
  transform: translateY(300px);
}
.mmenu-anim > li:nth-child(7) {
  opacity: 1;
  transform: translateY(350px);
}
.mmenu-anim > li:nth-child(8) {
  opacity: 1;
  transform: translateY(400px);
}
.mmenu-anim > li:nth-child(9) {
  opacity: 1;
  transform: translateY(450px);
}
.mmenu-anim > li:nth-child(10) {
  opacity: 1;
  transform: translateY(500px);
}
.mmenu-anim > li:nth-child(11) {
  opacity: 1;
  transform: translateY(550px);
}
.mmenu-anim > li:nth-child(12) {
  opacity: 1;
  transform: translateY(600px);
}
.mmenu-active {
  overflow: hidden;
}
.mmenu-active .mmenu-anim > li,
.mmenu-active .mmenu-anim {
  opacity: 1;
  transform: translateY(0);
}
.mmenu-active .page-wrapper {
  margin-left: 296px;
  margin-right: -296px;
}
.mmenu-active .mobile-menu-wrapper {
  visibility: visible;
}
.mmenu-active .mobile-menu-container {
  transform: translateX(0);
}
.mmenu-active .mobile-menu-overlay {
  opacity: 1.8;
}
.mmenu-active .mobile-menu-close {
  opacity: 1;
}
@media (max-width: 400px) {
  .mobile-menu-close {
    left: calc(100vw - 40px);
    top: 10px;
  }
}
.category-menu {
  background: #feecd4;
}
.category-menu .menu-title {
  padding: 0.7rem 0;
  margin: 0;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  line-height: 2.58;
  letter-spacing: -0.01em;
  color: #4d463c;
}
.category-menu .menu-body:last-child li:last-child {
  border: 0;
}
.category-menu i {
  vertical-align: middle;
  line-height: 0;
  margin-right: 0.9rem;
  font-size: 1.8rem;
  color: #666;
}
.category-menu > li > a {
  display: block;
  padding: 1.6rem 0;
  text-transform: capitalize;
  font-size: 1.4rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 1.4;
  color: #7e6e59;
}
.category-menu > li:not(:last-child) > a {
  border-bottom: 1px solid #f2dfc7;
}
.category-menu li:hover > a:not(.menu-title) {
  color: #00a567;
}
.category-menu li:hover > a:not(.menu-title) i {
  color: inherit;
}
.category-menu .submenu > a::after {
  font-size: 1rem;
}
.category-menu ul {
  padding: 2rem 0;
  background: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0;
  color: #666;
}
.footer {
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
  background: #343a40;
}
.footer p {
  font-size: inherit;
}
.footer a:not(.social-link):hover {
  color: #fff;
}
.footer .social-link {
  letter-spacing: 0.005em;
  color: #999;
  border: 2px solid #999;
}
.footer .social-link:hover {
  color: #fff;
}
.footer .widget-title {
  border-bottom: none;
}
.logo-footer,
.logo-footer img {
  display: block;
}
.footer-top {
  padding: 3.5rem 0;
  border-bottom: 1px solid #333;
}
.footer-middle {
  padding: 3rem 0 0;
  border-bottom: 1px solid #333;
}
.footer-middle .widget {
  margin-bottom: 3rem;
}
.footer-middle .widget-title {
  padding: 0.6rem 0;
  margin-bottom: 0.9rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0;
  color: #ccc;
}
.footer-middle .widget-body {
  padding: 8px 0 0;
  color: #999;
}
.footer-middle .widget-body li {
  line-height: 1.2;
  margin-bottom: 13px;
}
.footer-middle .widget-body li:last-child {
  margin-bottom: 0;
}
.footer-middle .widget-instagram .widget-body {
  padding-top: 0;
  margin: -5px -4px;
}
.footer-middle .widget-instagram .col-3 {
  padding: 5px 4px;
}
.footer-middle .widget-instagram img {
  display: block;
  width: 100%;
  height: auto;
}
.footer-middle label {
  font-weight: 600;
  text-transform: uppercase;
  color: #ccc;
}
.footer-bottom {
  padding: 2.8rem 0;
}
.footer-bottom,
.footer-bottom .container,
.footer-bottom .container-fluid {
  display: flex;
  align-items: center;
}
.footer-bottom .footer-left,
.footer-bottom .footer-right {
  flex: 1;
}
.footer-bottom .footer-left {
  display: flex;
}
.footer-bottom .footer-right {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 991px) {
  .footer-top .logo-footer {
    margin-bottom: 2.7rem;
  }
  .footer-top .logo-footer img {
    margin-left: auto;
    margin-right: auto;
  }
  .newsletter-info {
    margin-bottom: 2rem;
  }
  .footer-middle {
    padding: 3rem 0 2rem;
  }
  .footer-middle .widget {
    margin-bottom: 2rem;
  }
  .footer-middle .widget-body {
    padding: 0;
  }
  .footer-bottom,
  .footer-bottom > .container {
    display: block;
  }
  .footer-bottom .footer-left,
  .footer-bottom .footer-right {
    justify-content: center;
  }
  .footer-bottom .footer-left,
  .footer-bottom .footer-center {
    margin-bottom: 2.7rem;
  }
  .footer-center {
    text-align: center;
  }
}
.footer .copyright {
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.5;
}
.widget-newsletter .newsletter-info {
  max-width: 35rem;
  width: 100%;
}
.widget-newsletter .input-wrapper {
  max-width: 48rem;
}
.widget-newsletter input {
  border: 0;
  background: #212529;
  border-radius: 20px;
}
.widget-newsletter .widget-title {
  padding: 0;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1;
  color: #ccc;
}
.widget-newsletter p {
  margin-bottom: 0;
  font-family: inherit;
  line-height: 1.23;
  color: #999;
}
.footer .widget-about .logo-footer {
  display: block;
  margin-bottom: 1.6rem;
}
.footer .widget-about p {
  margin-bottom: 3rem;
  color: #999;
  line-height: 1.85;
  letter-spacing: -0.025em;
}
.footer .widget-about .widget-body {
  padding: 0;
}
.payment img {
  display: block;
}
.sticky-footer {
  display: flex;
}
.sticky-footer > * {
  flex: 1;
}
.sticky-footer .cart-dropdown:hover .cart-toggle {
  color: #222;
}
.sticky-footer .cart-toggle,
.sticky-footer .search-toggle {
  padding: 0;
  color: inherit;
}
.sticky-footer .cart-dropdown.dir-up::after {
  bottom: 100%;
}
.sticky-footer .dropdown-box,
.sticky-footer .input-wrapper {
  margin-bottom: 2rem;
}
.sticky-footer .dropdown-box::after,
.sticky-footer .input-wrapper::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -2rem;
  width: 100%;
  height: 2rem;
}
.sticky-footer .header-search.show,
.sticky-footer .header-search:hover {
  color: #222;
}
.sticky-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 0 1.3rem;
}
.sticky-link i {
  font-size: 2.3rem;
  line-height: 1;
  transform: translateY(0);
  transition: transform 0.35s ease;
}
.sticky-link span {
  margin-top: 0.8rem;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}
.sticky-link.active {
  color: #222;
}
.sticky-link:hover {
  color: #222;
}
.sticky-link:hover i {
  transform: translateY(-5px);
  transition: transform 0.35s ease;
}
@media (min-width: 576px) {
  .sticky-footer {
    padding: 0 4rem;
  }
}
@media (min-width: 768px) {
  .sticky-footer {
    display: none;
  }
}
@media (min-width: 1200px) {
  .pl-xl-6 {
    padding-left: 3rem;
  }
  .pr-xl-8 {
    padding-right: 4rem;
  }
}
.reviews-section .testimonial-title {
  font-family: Poppins, sans-serif;
  font-size: 1.6rem;
}
.reviews-section .testimonial {
  box-shadow: 5px 5px 2rem rgba(0, 0, 0, 0.05);
}
.account {
  line-height: 2.15;
}
.account p {
  font-family: Poppins, sans-serif;
  line-height: 2.15;
}
.account label {
  display: block;
  padding-left: 0.2rem;
  margin-bottom: 1rem;
  font-size: 1.3rem;
  line-height: 1;
}
.account .form-control {
  font-size: 1.3rem;
  font-family: Poppins, sans-serif;
  transition: background-color 0.3s, border-color 0.3s;
  color: #999;
}
.account .form-control:not(:focus) {
  background-color: #f6f7f9;
}
.account .form-control:focus {
  border-color: #00a567;
}
.account .nav-tabs {
  width: 23.7%;
  border-right: none;
}
.account .nav-link {
  transition: color 0.3s, padding 0.3s;
}
.account .nav-link::before {
  content: "\e97e";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  visibility: hidden;
  font-family: "Donald";
  font-size: 1.4rem;
  transition: opacity 0.3s, visibility 0.3s;
}
.account .nav-item {
  border-bottom: 1px solid #eee;
  color: #222;
}
.account .nav-item a {
  display: block;
  padding: 1.4rem 2px;
  margin: 0;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1;
  text-transform: none;
}
.account .nav-item:hover .nav-link {
  color: #00a567;
}
.account .nav-item.show .nav-link,
.account .nav-item .nav-link.active {
  padding-left: 2.2rem;
  color: #00a567;
}
.account .nav-item.show .nav-link::before,
.account .nav-item .nav-link.active::before {
  visibility: visible;
  opacity: 1;
}
.account .tab-content {
  width: auto;
  flex: 1;
  padding-left: 3rem;
}
.account .tab-pane {
  padding-top: 0.3rem;
}
.bugger {
  transform: translateX(0);
  visibility: visible;
}
@media (max-width: 767px) {
  .account .nav-tabs {
    width: 100%;
  }
  .account .tab-content {
    padding-left: 0;
  }
}
.card-address {
  background: #f6f7f9;
  border: 1px solid #e1e1e1;
}
.card-address p {
  margin-bottom: 1rem;
}
.card-address .card-body {
  padding: 2.5rem;
}
.section-buttons {
  padding: 6.4rem 0 0.5rem;
}
.section-buttons .row {
  justify-content: center;
  text-align: center;
}
.section-buttons.grey-section .title-wrapper p {
  color: #666;
}
.section-buttons.background-section {
  padding: 6.6rem 0 5rem;
}
.section-buttons .btn {
  margin-bottom: 2rem;
}
.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.btn-wrapper .btn {
  margin-left: 1rem;
  margin-right: 1rem;
}
.btn-wrapper .btn-block {
  max-width: 18rem;
}
@media (min-width: 992px) {
  .btn-wrapper .btn {
    margin: 0 0 2rem;
  }
  .btn-wrapper .btn:not(:last-child) {
    margin-right: 2rem;
  }
}
.categories-section.ellipse-section .title {
  margin-bottom: 4rem;
}
.masonry-section .height-x1 {
  height: 21.5rem;
}
.masonry-section .height-x2 {
  height: 43rem;
}
.masonry-section .category-banner {
  height: 100%;
}
.masonry-section .category-banner .category-media,
.masonry-section .category-banner > a {
  display: block;
  height: 100%;
}
.masonry-section .category-banner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.badge-section .height-x1 {
  height: 30rem;
}
.badge-section .height-x2 {
  height: 60rem;
}
.badge-section .category-badge {
  overflow: hidden;
  height: 100%;
}
.badge-section .category-badge .category-media,
.badge-section .category-badge > a {
  display: block;
  height: 100%;
}
.badge-section .category-badge img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  section.masonry-section .height-x1,
  section.badge-section .height-x1 {
    height: 15rem;
  }
  section.masonry-section .height-x2,
  section.badge-section .height-x2 {
    height: 30rem;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .badge-section .category-badge img {
    height: 100%;
    width: auto;
  }
  .masonry-section .category-banner img {
    height: 100%;
    width: auto;
  }
}
@media screen and (-ms-high-contrast: active) and (max-width: 767px),
  (-ms-high-contrast: none) and (max-width: 767px) {
  .badge-section .category-badge img {
    width: 100%;
    height: auto;
  }
  .masonry-section .category-banner img {
    width: 100%;
    height: auto;
  }
}
.countdown-coming {
  text-align: left;
}
.countdown-coming .countdown-row {
  display: flex;
  justify-content: center;
  line-height: 1;
}
.countdown-coming .countdown-amount {
  display: block;
  padding: 0 4.2rem;
  font-size: 3rem;
  color: #222;
  letter-spacing: -0.025em;
}
.countdown-coming .countdown-period {
  display: block;
  font-weight: 300;
  font-size: 1.6rem;
  text-align: center;
  text-transform: lowercase;
}
.countdown-coming .countdown-section:not(:first-child)::before {
  content: ":";
  position: absolute;
  color: #ccc;
  font-size: 3rem;
  line-height: 0.9;
  transform: translate(-3px);
}
@media (max-width: 479px), (min-width: 768px) and (max-width: 991px) {
  .countdown-coming .countdown-amount {
    padding: 0 2.4rem;
  }
}
.coming-section .row {
  min-height: 100vh;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .coming-section .row {
    height: 1px;
  }
}
.coming-section h1 {
  font-size: 5rem;
  font-weight: 900;
}
.coming-section p {
  font-weight: 300;
  line-height: 2.15;
  letter-spacing: -0.01em;
}
.coming-section form {
  position: relative;
}
.coming-section .form-control {
  font-weight: 300;
  border-color: #dadada;
  padding: 1.25rem 13rem 1.25rem 1.5rem;
}
.coming-section .btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 1.6rem 1.5rem 1.6rem 2.5rem;
}
.coming-section .btn:hover {
  background: #00a567;
  color: #fff;
}
.coming-section .social-link {
  border-width: 1px;
  line-height: 28px;
  margin-right: 5px;
}
@media (max-width: 479px) {
  .coming-section {
    padding-bottom: 1px;
  }
}
.contact-section h2 {
  font-size: 2.4rem;
}
.contact-section h3 {
  font-size: 1.8rem;
  font-weight: 600;
}
.contact-section h4 {
  font-size: inherit;
}
.contact-section form p {
  font-size: 1.3rem;
  letter-spacing: 0.01em;
}
.contact-section .form-control {
  background: #fff;
  border-color: transparent;
  transition: border-color 0.3s;
}
.contact-section .form-control::placeholder {
  color: #999;
}
.contact-section .form-control:focus {
  border-color: #e1e1e1;
}
.contact-section textarea {
  min-height: 10rem;
}
.error-section {
  background: #f7f7f7;
  height: 727px;
}
.error-section h1 {
  font-size: 6rem;
}
.error-section .btn {
  padding: 1em 3.6em;
}
.product-wrapper.row [class*="col-"] {
  margin-bottom: 2rem;
}
.cta-simple {
  position: relative;
  overflow: hidden;
  background-color: #edeef0;
}
.cta-simple::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  padding-top: 50%;
}
.cta-simple .banner-content {
  position: relative;
  margin: 1.5rem;
  padding: 3.4rem 0;
  z-index: 3;
}
.cta-simple .banner-header {
  position: relative;
  max-width: 29.1%;
  flex: 0 0 29.1%;
  text-align: right;
}
.cta-simple .banner-header::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 0.5rem;
  height: 9rem;
  background-color: #edeef0;
}
.cta-simple .banner-text {
  flex-grow: 1;
}
.cta-simple .banner-title {
  margin-bottom: 0.2rem;
  font-size: 3em;
}
.cta-simple .banner-subtitle {
  margin-bottom: 0;
  font-size: 2em;
}
.cta-simple .banner-text h4 {
  font-size: 2em;
  line-height: 1.3;
}
.cta-simple .btn {
  position: relative;
  margin-right: 5.2rem;
  padding: 1.38em 2em;
  min-width: 18rem;
  overflow: hidden;
  background: transparent;
  transition: opacity 0.3s;
  border: 0;
}
.cta-simple .btn::before {
  content: "";
  display: block;
  position: absolute;
  left: -100%;
  width: 200%;
  top: 0;
  bottom: 0;
  z-index: -1;

  transition: left 0.3s;
}
.cta-simple .btn:hover::before {
  left: 0;
}
.banner-1 img,
.banner-2 img {
  min-height: 30rem;
  object-fit: cover;
}
.banner-1 {
  background-color: #4f4f4f;
}
.banner-1 .banner-title {
  margin-bottom: 1.1rem;
  font-size: 4em;
}
.banner-1 .banner-subtitle {
  margin-bottom: 1rem;
  white-space: nowrap;
  font-size: 3em;
}
.banner-1 p {
  margin-bottom: 2.4rem;
  font-size: 1.6em;
  line-height: 1.25;
}
.banner-1 .btn {
  padding: 0.77em 1.12em;
}
.banner-2 {
  background-color: #2b579a;
}
.banner-2 .banner-content {
  width: 69%;
}
.banner-2 .banner-title {
  font-size: 3em;
  line-height: 1.06;
}
.banner-2 p {
  margin-bottom: 1.6rem;
  font-size: 1.6em;
  line-height: 1.2;
}
.banner-2 input.form-control {
  border: none;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 2rem;
}
.banner-2 .btn {
  padding: 0.77em 1.35em;
}
.banner-2 .btn:hover,
.banner-2 .btn:active,
.banner-2 .btn:focus {
  color: #00a567;
  border-color: #fff;
}
.banner-group .banner-title {
  margin-bottom: 0.1rem;
  font-size: 2.2em;
}
.banner-group .banner-subtitle {
  margin-bottom: 1.8rem;
  font-size: 1.3em;
}
.banner-group .banner-subtitle::after {
  margin-top: 1.8rem;
  content: "";
  display: block;
  width: 3.5rem;
  height: 0.4rem;
  background-color: #222;
}
.banner-3 {
  background-color: #ccc;
}
.banner-3 .banner-content {
  left: 7.8%;
}
.banner-4 {
  background-color: #ccc;
}
.banner-4 figure {
  border: 1.5em solid #00a567;
}
.banner-4 figure img {
  width: 100%;
  max-height: 25rem;
}
.banner-4 .banner-title {
  margin-bottom: 0.2rem;
  font-size: 3em;
  white-space: nowrap;
}
.banner-4 .banner-subtitle {
  font-size: 2em;
}
.banner-4 .banner-subtitle::after {
  content: none;
}
.banner-4 .btn {
  padding: 0.76em 1.36em;
}
.banner-5 {
  background-color: #ccc;
}
.banner-5 .banner-content {
  right: 3.9%;
}
.banner-newsletter {
  border: 2px solid #00a567;
}
.banner-newsletter .banner-content {
  padding: 1.8rem 0;
}
.banner-newsletter .icon-box {
  justify-content: flex-start;
}
.banner-newsletter .icon-box p {
  line-height: 1.43;
  letter-spacing: 0.01em;
}
.banner-newsletter .icon-box-icon {
  margin: 0 2.4rem 0 0.2rem;
  font-size: 4.5rem;
}
.banner-newsletter .icon-box-title {
  font-size: 1.8rem;
  line-height: 0.9;
}
.banner-newsletter .input-wrapper {
  height: 4.8rem;
}
.banner-newsletter .input-wrapper .form-control {
  border: 1px solid #e4e4e4;
  border-right: 0;
}
.banner-newsletter .input-wrapper .btn {
  padding: 1em 2.6em;
}
.banner-background {
  padding: 10.7rem 0;
  background-color: #6b6b6b;
}
.banner-background .banner-title {
  margin-bottom: 0.9rem;
  font-size: 3em;
}
.banner-background .banner-subtitle {
  font-size: 1.8em;
  line-height: 1.34;
}
.banner-background .input-wrapper {
  max-width: 60rem;
  width: 100%;
  height: 4.8rem;
}
.banner-background .input-wrapper .form-control {
  position: relative;
  flex: 1;
  padding-left: 2.4rem;
  border: 1px solid #e7e7e7;
  border-right: 0;
  font-size: 1.2rem;
}
.banner-background .input-wrapper .btn {
  min-width: 12.3rem;
}
@media (max-width: 991px) {
  .cta-simple .banner-content {
    text-align: center;
  }
  .cta-simple .banner-header {
    max-width: 100%;
    text-align: center;
  }
  .cta-simple .banner-header::after {
    height: 0.5rem;
    width: 80%;
    top: 100%;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  .cta-simple .banner-text {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .cta-simple .btn {
    margin: 0;
  }
  .banner-2 .banner-content {
    width: 100%;
    padding: 0 3rem;
  }
  .banner-newsletter .icon-box {
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .banner {
    font-size: 0.9rem;
  }
  .banner-group .banner {
    font-size: 1.2rem;
  }
  .banner-newsletter .icon-box {
    display: block;
    text-align: center;
  }
  .banner-newsletter .icon-box-icon {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .banner-newsletter .icon-box-content {
    text-align: center;
  }
}
@media (max-width: 479px) {
  .banner-group .banner {
    font-size: 1rem;
  }
}
.instagram-masonry .height-x25 {
  height: 407px;
}
.instagram-masonry .height-x2 {
  height: 326px;
}
.instagram-masonry .height-x1 {
  height: 163px;
}
.instagram-masonry .height-x15 {
  height: 244px;
}
.instagram-masonry .grid-item {
  overflow: hidden;
  padding: 1rem;
}
.instagram-masonry img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.instagram-masonry .instagram {
  height: 100%;
}
@media (max-width: 767px) {
  .instagram-masonry .height-x25 {
    height: 305px;
  }
  .instagram-masonry .height-x2 {
    height: 244px;
  }
  .instagram-masonry .height-x1 {
    height: 122px;
  }
  .instagram-masonry .height-x15 {
    height: 183px;
  }
}
.posts {
  transition: height 0.4s;
}
.posts .post-details {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.posts .post-meta,
.posts .post-cats {
  font-size: 1.3rem;
  letter-spacing: 0.01em;
  color: #999;
}
.posts .post-meta {
  margin-bottom: 0.6rem;
}
.posts .post-meta a {
  margin-right: 0.5rem;
}
.posts .post-meta a:not(:first-child) {
  margin-left: 0.5rem;
}
.posts .post-cats {
  margin-bottom: 1.6rem;
}
.posts .post-title {
  margin-bottom: 0.7rem;
  white-space: normal;
  font-size: 1.8rem;
  letter-spacing: -0.025em;
  text-transform: none;
  line-height: 1.5;
}
.posts .post-content {
  margin-bottom: 1.6rem;
  line-height: 1.85;
  -webkit-line-clamp: 3;
}
.posts .btn::after {
  margin-top: 4px;
}
.posts .video-play {
  text-align: center;
  width: 6.8rem;
  height: 6.8rem;
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 6.4rem;
  transition: background 0.3s, color 0.3s, box-shadow 0.3s, opacity 0.3s;
}
.posts .video-play:hover {
  box-shadow: 0px 0px 0px 15px rgba(255, 255, 255, 0.2);
  background: #fff;
  color: #00a567;
}
.posts .post.paused .video-play::before,
.posts .post .video-play::before {
  margin-left: 4px;
  content: "\f04b";
}
.posts .post.playing .video-play::before {
  margin-left: 0;
  content: "\f04c";
}
.posts .post-lg .post-title {
  margin-bottom: 0.4rem;
  font-size: 2.4rem;
}
.post-list .post-details {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 1200px) {
  .posts .post-list .post-media {
    margin-right: 2rem;
    width: calc(47% - 2rem);
  }
  .posts .post-list .post-details {
    width: 53%;
  }
}
@media (max-width: 767px) {
  .posts .post-list {
    display: block;
  }
  .posts .post-list .post-media,
  .posts .post-list .post-details {
    margin: 0;
    width: 100%;
  }
}
.post-mask .post-meta,
.post-mask .post-cats {
  color: #ebebeb;
}
.shop-banner-default {
  background-position-x: 40%;
  padding: 7.8rem 1.5rem 7.8rem 9.1%;
}
.shop-banner-default .banner-subtitle {
  font-size: 2em;
}
.shop-banner-default .banner-title {
  margin-bottom: 1.8rem;
  font-size: 4em;
  letter-spacing: 0.05em;
}
.shop-banner-default p {
  font-size: 1.6rem;
}
.shop-boxed-banner {
  background-position-x: 40%;
  padding: 7.8rem 1.5rem 7.8rem 6.7%;
}
.shop-boxed-banner .banner-subtitle {
  font-size: 2em;
}
.shop-boxed-banner .banner-title {
  font-size: 4em;
}
.shop-boxed-banner p {
  font-size: 1.6rem;
  line-height: 1.375;
}
.filter-actions,
.toolbox,
.toolbox-left,
.toolbox-right,
.toolbox-item,
.toolbox-item-full {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.filter-actions,
.toolbox {
  justify-content: space-between;
}
.toolbox {
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 3rem 0 1rem;
  transition: padding 0.4s;
  background-color: #fff;
}
.toolbox > *:not(:last-child),
.toolbox-left > *:not(:last-child),
.toolbox-right > *:not(:last-child) {
  margin-right: 2rem;
}
.toolbox label {
  margin-right: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}
.toolbox select {
  cursor: pointer;
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
}
.toolbox .select-box .form-control {
  color: #222;
}
.toolbox .select-box::before {
  font-size: 1rem;
  right: 1rem;
}
.toolbox .toolbox-sort .form-control {
  max-width: 15.2rem;
  padding-left: 1.4rem;
}
.toolbox .toolbox-sort::before {
  right: 1.4rem;
}
.toolbox .left-sidebar-toggle {
  padding: 0.8em 1.04em;
}
.toolbox-item {
  flex-wrap: nowrap;
  margin-bottom: 1rem;
}
.toolbox-item-full {
  flex-basis: 100%;
}
.btn-layout {
  color: #dadada;
  font-size: 19px;
  margin: 0 0 1px 2px;
  padding: 0 2px;
}
.btn-layout:last-child {
  padding-right: 0;
}
.btn-layout:hover,
.btn-layout.active {
  color: #06603e;
}
.btn-layout + .btn-layout {
  margin-left: 5px;
}
.select-menu {
  color: #222;
}
.select-menu::before {
  right: 1.25em;
  font-size: 1.2rem;
  margin-top: 1px;
}
.select-menu ul,
.select-menu ul::before {
  content: "";
  position: absolute;
  border: 1px solid #eee;
  width: 11px;
}
.select-menu ul {
  top: 100%;
  z-index: 100;
  width: 18rem;
  margin-top: 1rem;
  padding: 1rem 1.4rem 1rem;
  background: #fff;
  opacity: 1;
  visibility: hidden;
}
.select-menu ul a {
  padding-left: 30px;
}
.select-menu ul::before {
  top: -6px;
  left: 22px;
  height: 11px;
  border-width: 1px 0 0 1px;
  background: #fff;
  transform: rotate(45deg);
}
.select-menu.opened > ul {
  opacity: 1;
  visibility: visible;
}
.select-menu select,
.select-menu-toggle {
  position: relative;
  border: 1px solid #ccc;
  padding: 0.65em 3.05em 0.58em 1.07em;
  font-size: 1.3rem;
  line-height: 1.5;
}
.select-menu-toggle {
  border-radius: 2px;
}
.select-menu select {
  max-width: 17.2rem;
  padding-right: 2.63em;
}
.select-item {
  padding: 5px 0.7rem 5px 1.2rem;
  color: #222;
  background: #eee;
  letter-spacing: -0.01em;
}
.select-item i {
  padding: 6px;
  margin-left: 5px;
  font-size: 9px;
  vertical-align: middle;
}
.select-items {
  display: none;
}
.toolbox + .select-items {
  font-size: 1.2rem;
  margin: 2px 0 1.8rem;
}
.select-items > * {
  display: inline-block;
  margin: 0 0.8rem 0.5rem 0;
}
.select-items .filter-clean {
  margin-left: 1.2rem;
}
.toolbox-pagination {
  border-top: 1px solid #f4f4f4;
  padding: 3rem 0 2rem;
}
.toolbox-horizontal .show-info {
  color: #999;
  font-size: inherit;
}
.toolbox-wrap {
  display: flex;
  flex-direction: column-reverse;
  line-height: 1.3;
}
.toolbox-wrap .toolbox > * {
  line-height: 37px;
}
.toolbox-wrap .left-sidebar-toggle {
  padding: 0 1em;
  margin-right: 2rem;
  font-weight: 600;
  line-height: 33px;
}
.toolbox-wrap .show-info {
  color: #999;
}
.toolbox-wrap .show-info span {
  margin: 0 0.5rem;
}
.toolbox-wrap .toolbox-layout {
  display: flex;
}
.toolbox-wrap option {
  text-transform: none;
}
.toolbox-wrap .toolbox-layout {
  display: flex;
}
.toolbox-wrap .widget-title {
  padding-bottom: 1.3rem;
  border-top: 0;
}
.toolbox-wrap .sidebar-fixed .sidebar-content {
  padding-bottom: 0;
}
.sticky-toolbox.fixed {
  position: fixed;
  background-color: #fff;
  padding: 1rem 2rem 0;
}
.sticky-toolbox.sticky-content {
  transition: none;
}
@media (max-width: 479px) {
  .sticky-toolbox.fixed {
    padding: 1rem 1.5rem 0;
  }
}
@media (max-width: 575px) {
  .toolbox-item label,
  .toolbox-item > span,
  .toolbox-item.show-info {
    display: none;
  }
  .toolbox-item.select-box {
    margin-right: 0;
  }
  .toolbox-item.select-box ~ .toolbox-item.right-sidebar-toggle {
    margin-left: 0.5rem;
  }
  .toolbox-item.toolbox-layout {
    display: none;
  }
  .toolbox .toolbox-left,
  .toolbox .left-sidebar-toggle {
    margin-right: 0.5rem;
  }
  .toolbox .toolbox-sort .form-control {
    max-width: 13rem;
  }
  .toolbox-horizontal {
    justify-content: flex-start;
    flex-direction: row;
  }
  .toolbox-horizontal .toolbox-left {
    margin-right: auto;
  }
  .toolbox-horizontal .toolbox-right {
    margin-left: auto;
  }
  .toolbox-horizontal .toolbox-item.toolbox-show {
    margin-right: 0;
  }
  .toolbox-pagination {
    flex-direction: column;
  }
  .toolbox-pagination > .show-info {
    margin-right: 0;
  }
  .toolbox-pagination > *:not(:last-child) {
    margin-bottom: 1rem;
  }
}
@media (min-width: 576px) {
  .toolbox-horizontal .toolbox-left {
    margin-right: auto;
  }
  .mr-sm-auto {
    margin-right: auto;
  }
}
@media (max-width: 991px) {
  .toolbox .sidebar-fixed {
    margin-right: 0;
  }
  .toolbox .sidebar-content {
    display: block;
  }
  .sidebar-content .select-menu {
    display: block;
    margin: 0;
    font-size: 1.4rem;
  }
  .sidebar-content .select-menu ul {
    position: static;
    width: 100%;
    padding: 0;
    border: 0;
    color: #666;
    margin: 0 0 1.1rem;
    opacity: 1;
    visibility: visible;
  }
  .sidebar-content .select-menu ul a {
    padding-left: 26px;
  }
  .sidebar-content .select-menu ul a::before {
    width: 18px;
    height: 18px;
    margin-top: 0;
  }
  .sidebar-content .select-menu::before,
  .sidebar-content .select-menu ul::before,
  .sidebar-content .select-menu > a::before {
    content: none;
  }
  .sidebar-content .select-menu-toggle {
    display: block;
    border-width: 3px 0 0;
    padding: 2rem 2px 1.6rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .toolbox-wrap .widget-title {
    border-top: 3px solid #eee;
  }
}
@media (min-width: 992px) {
  .toolbox .sidebar-content {
    max-width: none;
  }
  .toolbox-horizontal .sidebar-fixed .sidebar-content {
    padding-bottom: 0;
  }
  .toolbox-wrap .sidebar-content {
    border: solid #ebebeb;
    border-width: 3px 0;
    margin-bottom: 2rem;
    transition: border-color 0.3s;
  }
  .toolbox-wrap .filter-actions:first-child {
    padding: 0;
  }
  .toolbox-wrap .sidebar-toggle-btn {
    display: none;
  }
  .toolbox-wrap > .closed {
    margin: 0;
  }
  .toolbox-wrap > .closed .sidebar-content {
    display: none;
    border-color: transparent;
    background: transparent;
  }
  .toolbox-wrap .filter-clean {
    display: none;
    position: absolute;
    right: 0;
    top: -40px;
  }
}
@media (min-width: 1200px) {
  .select-menu:not(:last-child) {
    margin-right: 2rem;
  }
  .toolbox .shop-sidebar {
    margin-right: 2rem;
  }
}
.cart-added-alert {
  display: none;
  margin-bottom: 1rem;
}
.container > .cart-added-alert:first-child {
  margin-top: -1rem;
}
.cart-added-alert span {
  color: #222;
}
.product-gallery {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  position: sticky;
}
.product-gallery.product-gallery-sticky {
  top: 2rem;
  padding-bottom: 3rem;
  transition: top 0.3s;
}
.sticky-header-active .product-gallery.product-gallery-sticky {
  top: 8rem;
}
.product-gallery.row > * {
  display: flex;
  align-items: center;
}
.product-gallery.row .product-image-full {
  right: 2rem;
}
.product-image-full {
  display: block;
  position: absolute;
  padding: 1rem;
  right: 1rem;
  bottom: 1rem;
  color: #999;
  font-size: 2rem;
  line-height: 1;
  opacity: 1;
  transition: opacity 0.3s;
  z-index: 1;
}
:hover > .product-image-full {
  opacity: 1;
}
.product-navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 2px;
}
.product-navigation .breadcrumb {
  font-size: inherit;
}
.product-navigation li {
  padding: 3px 0 1.1rem;
}
.product-nav {
  display: flex;
  position: relative;
  color: #999;
}
.product-nav i {
  font-size: 1.2rem;
}
.product-nav li + li {
  margin-left: 1.5rem;
}
.product-nav .product-name {
  color: #666;
  font-size: 1.2rem;
  font-weight: 400;
  white-space: normal;
}
.product-nav li:hover .product-nav-popup {
  opacity: 1;
  visibility: visible;
  transform: none;
}
.product-nav-popup {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 30;
  width: 120px;
  padding: 0 5px 5px;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 1;
  transform: scale(0.9);
  transform-origin: top;
  transition: opacity 0.3s, transform 0.3s;
}
.product-nav-popup::before {
  content: "";
  position: absolute;
  top: -8px;
  right: 18px;
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  background-color: #fff;
  box-shadow: inherit;
}
.product-nav-popup img {
  position: relative;
  padding-top: 5px;
  background-color: #fff;
}
.product-nav-prev .product-nav-popup::before {
  right: 6.6rem;
}
.product-tabs .nav-link {
  color: #999;
}
.product-tabs .nav-item.show .nav-link,
.product-tabs .nav-item:hover .nav-link,
.product-tabs .nav-item .nav-link.active {
  border-bottom-color: #222;
}
.product-tabs .nav-link {
  padding: 1rem 2px;
  line-height: 1.2;
}
.product-tabs .nav-item:not(:last-child) {
  margin-right: 2.3rem;
}
.product-tabs .tab-pane {
  padding-left: 2px;
  line-height: 1.86;
}
.product-tabs p,
.product-tabs > div ul {
  margin-bottom: 1.5rem;
}
.product-footer + .product-tabs {
  margin-top: 2.5rem;
}
.product-tabs .tab-pane .list-type li,
.product-status .list-type li {
  padding-left: 3rem;
}
.product-status {
  line-height: 2;
}
#product-tab-additional label {
  display: inline-block;
  min-width: 20rem;
  color: #222;
}
#product-tab-additional p {
  display: inline-block;
  margin-bottom: 0;
}
#product-tab-shipping-returns h6 {
  font-size: 1.4rem;
}
#product-tab-reviews h4 {
  font-size: 1.4rem;
}
#product-tab-reviews .comments {
  padding-top: 2rem;
}
#product-tab-reviews .comments > ul {
  padding-right: 3rem;
}
#product-tab-reviews .comments ul {
  border-bottom: 1px solid #e1e1e1;
}
#product-tab-reviews .comments li {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
#product-tab-reviews .comments li:last-child {
  margin-bottom: 0;
}
#product-tab-reviews .comments li {
  margin-left: 3rem;
}
#product-tab-reviews .comment p {
  margin-bottom: 0;
}
#product-tab-reviews .comment-rating {
  position: absolute;
  right: 0;
  top: 8px;
}
#product-tab-reviews .average-rating {
  font-size: 1.6rem;
}
#product-tab-reviews .ratings-full {
  margin-right: 0;
}
#product-tab-reviews form .row {
  max-width: 77.6rem;
}
#product-tab-reviews .reply {
  margin-bottom: 0;
}
#product-tab-reviews .ratings::before {
  color: #d26e4b;
}
@media (max-width: 479px) {
  #product-tab-reviews .comment-rating {
    top: -10px;
  }
}
.rating-form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2.2rem;
}
.rating-form label {
  margin-right: 1rem;
}
.rating-stars {
  display: flex;
  position: relative;
  height: 14px;
  font-size: 1.4rem;
}
.rating-stars a {
  color: rgba(0, 0, 0, 0.16);
  text-indent: -9999px;
  letter-spacing: 1px;
  width: 16px;
  letter-spacing: 0.2em;
}
.rating-stars a:before {
  content: "";
  position: absolute;
  left: 0;
  height: 14px;
  line-height: 1;
  font-family: "Donald";
  text-indent: 0;
  overflow: hidden;
  white-space: nowrap;
}
.rating-stars a.active:before,
.rating-stars a:hover:before {
  content: "\e94b\e94b\e94b\e94b\e94b";
  color: #d26e4b;
}
.rating-stars .star-1 {
  z-index: 10;
}
.rating-stars .star-2 {
  z-index: 9;
}
.rating-stars .star-3 {
  z-index: 8;
}
.rating-stars .star-4 {
  z-index: 7;
}
.rating-stars .start-5 {
  z-index: 6;
}
.rating-stars .star-1:before {
  width: 20%;
}
.rating-stars .star-2:before {
  width: 40%;
}
.rating-stars .star-3:before {
  width: 60%;
}
.rating-stars .star-4:before {
  width: 80%;
}
.rating-stars .star-5:before {
  content: "\e94b\e94b\e94b\e94b\e94b";
}
.product-sticky-content:not(.fixed) .sticky-product-details {
  display: none;
}
.product-sticky-content:not(.fixed) .container {
  padding: 0;
}
.product-sticky-content.fixed {
  padding: 1rem 0;
}
.product-sticky-content.fixed .container {
  display: flex;
  align-items: center;
}
.product-sticky-content.fixed .product-form {
  flex: 1;
}
.product-sticky-content.fixed .product-form-group {
  justify-content: flex-end;
}
.product-sticky-content.fixed .product-form,
.product-sticky-content.fixed .product-form-group > * {
  margin-bottom: 0;
}
.product-sticky-content.fixed .product-form > label {
  display: none;
}
.product-sticky-content.fixed .input-group {
  margin-right: 0.8rem;
}
.sticky-product-details {
  display: flex;
  align-items: center;
}
.sticky-product-details img {
  display: block;
}
.sticky-product-details .product-image {
  margin-right: 1rem;
  max-width: 9rem;
}
.sticky-product-details .product-title {
  margin-bottom: 0.5rem;
  font-weight: 700;
}
.sticky-product-details .product-price {
  font-weight: 600;
}
.sticky-product-details .product-title,
.sticky-product-details .product-price {
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
}
.sticky-product-details .product-price,
.sticky-product-details .ratings-container {
  margin-bottom: 0;
}
.sticky-product-details .product-info {
  display: flex;
}
.sticky-product-details .product-price {
  margin-right: 2rem;
}
aside .service-list {
  padding: 0 2rem;
  border: 1px solid #eee;
}
aside .service-list > * {
  justify-content: flex-start;
  padding: 2.3rem 0;
}
aside .service-list > :not(:last-child) {
  border-bottom: 1px solid #eee;
}
aside .service-list i {
  margin-left: 5px;
  font-size: 3.2rem;
}
aside .service-list .icon-box-title {
  margin-bottom: 0.3rem;
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.2;
}
aside .service-list p {
  color: #777;
  letter-spacing: -0.025em;
  line-height: 1.2;
}
aside .service-list .icon-box1 i {
  margin-left: 0;
  font-size: 3.7rem;
}
@media (min-width: 768px) {
  .product-gallery.sticky-sidebar {
    padding-bottom: 7rem;
  }
}
@media (min-width: 992px) {
  .product-form select {
    width: 20rem;
  }
  .product-single .product-details {
    padding-left: 1rem;
  }
  .product-details.row {
    padding-left: 0;
  }
  .product-details.row > :last-child {
    padding-left: 2rem;
  }
}
@media (min-width: 1600px) {
  .container-fluid .product-thumbs.owl-carousel {
    width: calc(100% + 20px);
    margin: 0 -10px;
  }
  .container-fluid .product-thumb {
    margin: 0 10px;
  }
  .container-fluid .product-thumbs-wrap {
    margin-top: 20px;
  }
  .container-fluid .product-thumbs .owl-prev {
    left: 10px;
  }
  .container-fluid .product-thumbs .owl-next {
    right: 10px;
  }
  .container-fluid .pg-vertical .product-thumb {
    margin: 0 0 20px;
  }
  .container-fluid .pg-vertical .product-thumbs-wrap {
    margin: 0 20px 0 0;
  }
  .container-fluid .pg-vertical .product-single-carousel {
    max-width: calc(100% - 129px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  aside .service-list {
    padding: 0 1rem;
  }
}
.product-sticky-both .btn-cart {
  margin-bottom: 1rem;
}
.product-sticky-both .product-action {
  display: flex;
}
.product-sticky-both .btn-wishlist {
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .product-sticky-both .btn-cart {
    max-width: 100%;
  }
  .product-sticky-both .product-form .select-box,
  .product-sticky-both .product-form .p-relative {
    margin-right: 0;
    width: 100%;
  }
  .product-sticky-both .product-form select {
    flex: 1;
    width: 100%;
  }
}
.post-single .post-details {
  padding: 2.7rem 0 0;
}
.post-single .post-meta,
.post-single .post-cats {
  font-size: 1.3rem;
  letter-spacing: 0.01em;
  color: #ccc;
}
.post-single .post-meta a,
.post-single .post-cats a {
  color: #666;
}
.post-single .post-meta a:hover,
.post-single .post-cats a:hover {
  color: #00a567;
}
.post-single .post-meta {
  margin-bottom: 0.6rem;
}
.post-single .post-meta a {
  white-space: nowrap;
  margin-right: 0.5rem;
}
.post-single .post-meta a:not(:first-child) {
  margin-left: 0.5rem;
}
.post-single .post-cats {
  margin-bottom: 1.9rem;
}
.post-single .post-title {
  margin-bottom: 0.7rem;
  white-space: normal;
  font-size: 2.4rem;
  letter-spacing: -0.025em;
  text-transform: none;
  line-height: 1.5;
}
.post-single .post-body a {
  text-decoration: underline;
  color: #222;
}
.post-single .post-body a:hover {
  color: #00a567;
}
.post-single .post-body h4 {
  margin-bottom: 1.7rem;
  line-height: 1;
}
.post-single .post-body p,
.post-single .post-body li {
  text-align: justify;
}
.post-single .with-img {
  display: flex;
}
.post-single .with-img > div {
  margin-left: 5rem;
  flex: 1;
}
.post-single .with-img .list {
  margin-left: 0;
}
.post-single .with-img li {
  padding-left: 2rem;
}
.post-single .with-img li:not(:last-child) {
  margin-bottom: 2.3rem;
}
.post-single blockquote {
  position: relative;
  padding: 4rem 3rem 4rem 11.7rem;
  border-left: 2px solid #00a567;
  background-color: #f2f3f5;
}
.post-single blockquote p {
  margin: 0;
  font-size: 1.8rem;
  line-height: 1.67;
}
.post-single blockquote:before {
  content: "\f10e";
  display: inline-block;
  position: absolute;
  left: 3.8rem;
  top: 50%;
  font-weight: 900;
  font-size: 3rem;
  line-height: 0;
  color: #999;
}
.post-single .post-footer {
  flex-wrap: wrap;
}
.post-single .social-links .social-link {
  margin: 0 0 0 2rem;
  font-size: 1.3rem;
}
.post-single .tag {
  margin: 0.5rem 0.2rem 0.5rem 0;
  font-size: 1.3rem;
  letter-spacing: 0.01em;
  border-color: #ebebeb;
  background: #f2f3f5;
  color: #999;
  line-height: 2.8rem;
}
.post-single .post-author-detail {
  display: flex;
  align-items: flex-start;
  padding: 4rem 2rem 4.3rem;
  background-color: #f2f3f5;
}
.post-single .author-media {
  max-width: 8rem;
  flex: 0 0 8rem;
  margin-right: 2.4rem;
  border-radius: 50%;
  overflow: hidden;
}
.post-single .author-media img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.post-single .author-header {
  flex-wrap: wrap;
  margin: 0.8rem 0 1.7rem;
}
.post-single .author-header *:not(i) {
  line-height: 1;
}
.post-single .author-header .author-name {
  margin-bottom: 0.4rem;
  font-size: 1.4rem;
  letter-spacing: 0.01em;
}
.post-single .author-header span {
  font-size: 1.3rem;
  letter-spacing: 0.01em;
}
.post-single .author-header .author-link {
  display: inline-block;
  margin: 1rem 0;
}
.post-single .author-header .author-link:hover {
  color: #00a567;
}
.post-single .author-body {
  flex-grow: 1;
}
@media (max-width: 1199px) {
  .post-single .with-img > div {
    margin-left: 2rem;
  }
}
@media (max-width: 767px) {
  .post-single .with-img {
    display: block;
  }
  .post-single .with-img img {
    width: 100%;
  }
  .post-single .with-img > div {
    margin-left: 0;
  }
}
@media (max-width: 575px) {
  .post-single blockquote::before {
    left: 2rem;
  }
  .post-single blockquote {
    padding: 3rem 2rem 3rem 7rem;
  }
  .post-single blockquote p {
    font-size: 1.6rem;
  }
}
.page-nav {
  padding-bottom: 3.7rem;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 4.2rem 0 3.7rem;
}
.pager-link {
  position: relative;
  color: #00a567;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 50%;
  max-width: 50%;
  font-weight: 400;
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.5;
  transition: all 0.35s ease;
}
.pager-link + .pager-link {
  border-left: 0.1rem solid #ebebeb;
}
.pager-link:after {
  color: #333;
  display: block;
  font-family: "Donald";
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1;
  position: absolute;
  top: 50%;
  transition: all 0.35s ease 0.05s;
  margin-top: -0.8rem;
}
.pager-link.pager-link-prev {
  padding-left: 10rem;
  padding-right: 1rem;
}
.pager-link.pager-link-prev:after {
  content: "\e97d";
  left: 3rem;
}
.pager-link.pager-link-next {
  padding-left: 1rem;
  padding-right: 10rem;
  align-items: flex-end;
  text-align: right;
}
.pager-link.pager-link-next:after {
  content: "\e97e";
  right: 3rem;
}
.pager-link:hover,
.pager-link:focus {
  color: #00a567;
}
.pager-link:hover:after,
.pager-link:focus:after {
  color: #00a567;
}
.pager-link:hover.pager-link-prev,
.pager-link:focus.pager-link-prev {
  padding-left: 7rem;
}
.pager-link:hover.pager-link-prev:after,
.pager-link:focus.pager-link-prev:after {
  left: 0;
}
.pager-link:hover.pager-link-next,
.pager-link:focus.pager-link-next {
  padding-right: 7rem;
}
.pager-link:hover.pager-link-next:after,
.pager-link:focus.pager-link-next:after {
  right: 0;
}
.pager-link:hover .pager-link-title,
.pager-link:focus .pager-link-title {
  box-shadow: 0 0.2rem 0 #333;
}
.pager-link-title {
  display: inline-block;
  margin-top: 0.6rem;
  flex: 0 0 auto;
  color: #222;
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: none;
  transition: all 0.35s ease;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 575px) {
  .pager-link-title {
    display: none;
  }
  .pager-link.pager-link-prev {
    padding-left: 2.5rem;
  }
  .pager-link.pager-link-prev::after {
    left: 0;
  }
  .pager-link.pager-link-next {
    padding-right: 2.5rem;
    border: 0;
  }
  .pager-link.pager-link-next::after {
    right: 0;
  }
  .pager-link:hover.pager-link-prev,
  .pager-link:focus.pager-link-prev {
    padding-left: 2.5rem;
  }
  .pager-link:hover.pager-link-next,
  .pager-link:focus.pager-link-next {
    padding-right: 2.5rem;
  }
}
.related-posts .post-details {
  padding-top: 1.7rem;
}
.related-posts .post-meta,
.related-posts .post-cats {
  font-size: 1.3rem;
  letter-spacing: 0.01em;
  color: #999;
}
.related-posts .post-meta {
  margin-bottom: 0.6rem;
}
.related-posts .post-meta a {
  margin-right: 0.5rem;
}
.related-posts .post-meta a:not(:first-child) {
  margin-left: 0.5rem;
}
.related-posts .post-cats {
  margin-bottom: 1.9rem;
}
.related-posts .post-title {
  margin-bottom: 0.7rem;
  white-space: normal;
  font-size: 1.6rem;
  letter-spacing: 0;
  text-transform: none;
  line-height: 1.5;
}
.related-posts .owl-dots .owl-dot.active span {
  background-color: #ccc;
  border-color: #ccc;
}

.shop-table tbody {
  border-bottom: 1px solid #e1e1e1;
}
.shop-table td {
  padding: 1.5rem 1rem 1.5rem 0;
  border-top: 1px solid #e1e1e1;
  font-size: 1.4rem;
}
.shop-table th {
  padding: 0.3rem 0 0.3rem;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.875;
  text-transform: uppercase;
  color: #222;
}
/* .shop-table img {
  display: block;
  max-width: 100px;
} */
.shop-table .product-thumbnail a {
  display: block;
  width: 100px;
}
.shop-table .remove {
  display: block;
  margin-right: -1rem;
  width: 3rem;
  height: 3rem;
  font-size: 1.7rem;
  text-align: center;
  color: #999;
}
.shop-table .remove i {
  line-height: 3rem;
}
.shop-table .remove:hover {
  color: #00a567;
}
.shop-table .product-name {
  white-space: normal;
}
.shop-table td.product-remove {
  padding-right: 0;
  width: 20px;
}
.shop-table .product-price {
  display: table-cell;
}
@media (min-width: 768px) {
  .wishlist-table td.product-price {
    width: 130px;
  }
  .wishlist-table td.product-stock-status {
    width: 130px;
  }
}
@media (min-width: 992px) {
  .wishlist-table td.product-price {
    width: 180px;
  }
  .wishlist-table td.product-stock-status {
    width: 200px;
  }
  .wishlist-table td.product-add-to-cart {
    width: 180px;
  }
}
@media (max-width: 767px) {
  .shop-table {
    text-align: center;
  }
  .shop-table thead {
    display: none;
  }
  .shop-table tbody,
  .shop-table tr,
  .shop-table td {
    display: block;
  }
  .shop-table tbody {
    border: 1px solid #e1e1e1;
  }
  .shop-table tr {
    position: relative;
    padding: 4.2rem 0 4rem;
  }
  .shop-table tr + tr {
    border-top: 1px solid #e1e1e1;
  }
  .shop-table td {
    padding: 0;
    border: none;
  }
  .shop-table .product-thumbnail {
    margin-bottom: 1rem;
  }
  .shop-table .product-thumbnail a,
  .shop-table .btn-product,
  .shop-table td {
    margin-left: auto;
    margin-right: auto;
  }
  .shop-table .product-stock-status {
    margin-bottom: 1rem;
  }
  .shop-table .btn-product {
    max-width: 220px;
  }
  .shop-table .product-remove {
    position: absolute;
    right: 2rem;
    top: 0.5rem;
    padding: 0.5rem;
    width: auto;
  }
  .shop-table .product-quantity {
    margin-bottom: 1rem;
  }
  .wishlist-table td.product-price {
    display: block;
    width: 100%;
  }
}
.wishlist-table th.product-name {
  padding-left: 2px;
  width: 120px;
}
.wishlist-table .wishlist-out-stock {
  color: #d26e4b;
}
.wishlist-table .wishlist-in-stock {
  color: #00a567;
}
.wishlist-table .btn-product {
  padding: 1.4rem;
  background-color: #f2f3f5;
  color: #222;
  white-space: nowrap;
  font-size: 1.2rem;
  transition: color 0.3s, background-color 0.3s;
}
.wishlist-table .btn-product:hover {
  color: #fff;
  background-color: #00a567;
}
.wishlist-table .btn-product.btn-disabled {
  border-color: #f2f3f5;
  background-color: #f2f3f5;
}
.cart.main {
  border-top: 1px solid #e1e1e1;
}
.cart .accordion {
  border-bottom: 1px solid #e1e1e1;
}
.cart .card-header {
  font-size: 1.4rem;
}
.cart .card-header > a {
  padding-left: 2px;
}
.cart .card-body {
  margin-bottom: 2.4rem;
  /* padding: 0; */
}
.cart .form-control {
  font-family: Poppins, sans-serif;
}
.cart .input-coupon {
  max-width: 100%;
}
.cart .input-coupon > * {
  border: 1px solid #e3e3e3;
  background-color: #f6f7f9;
}
.cart .input-coupon .btn {
  width: 5.2rem;
  border-left: none;
}
.cart .input-coupon .form-control {
  font-size: 1.3rem;
  border-right: none;
}
.cart .remove {
  margin-right: 0;
  width: 2rem;
  text-align: center;
}
.cart .summary {
  line-height: 2.15;
}
.cart .summary .title {
  margin-bottom: 1rem;
  padding-bottom: 1.8rem;
  border-bottom: 1px solid #e1e1e1;
}
.cart .summary label {
  display: block;
}
.cart .shipping {
  margin-bottom: 2.3rem;
  width: 100%;
  letter-spacing: -0.01em;
}
.cart select {
  max-width: 100%;
  width: 100%;
}
.cart .select-box::before {
  right: 1.5rem;
  font-family: "Donald";
  font-size: 1.2rem;
  content: "\e968";
}
.cart .btn-checkout {
  display: block;
}
.cart .product-thumbnail figure {
  position: relative;
  display: inline-block;
}
.cart .product-thumbnail .product-remove {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  width: 2rem;
  height: 2rem;
  top: -0.8rem;
  right: -0.8rem;
  border-radius: 50%;
  background-color: #fff;
  color: #222;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
}
.cart .shop-table th {
  padding-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
}
.cart .shop-table th:last-child {
  text-align: right;
}
.cart .shop-table td {
  padding: 2rem 2rem 2rem 2rem;
}
.cart .shop-table td:last-child {
  text-align: right;
}
.cart .shop-table .quantity.form-control {
  font-weight: 700;
}
.cart .product-price .amount {
  font-size: 1.6rem;
}
.step-by {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.step-by .title.title-step {
  font-size: 2rem;
  text-transform: initial;
  font-weight: 700;
  margin-left: 4.8rem;
  color: #999;
  margin-bottom: 0;
  letter-spacing: -0.5px;
  padding: 0.5rem 0;
}
.step-by .title.title-step::before {
  content: "\f054";
  display: inline-block;
  position: absolute;
  left: -2.8rem;
  top: 52%;
  line-height: 0;
  vertical-align: middle;
  font-weight: 700;
  font-size: 1.7rem;
  background-color: #fff;
}
.step-by .title.title-step.visited {
  color: #222;
}
.step-by .title.title-step.visited ~ .active::before {
  color: #222;
}
.step-by .title.title-step.active {
  color: #00a567;
}
.step-by .title.title-step.active::before {
  color: #999;
}
.step-by .title.title-step:first-child {
  margin-left: 0;
}
.step-by .title.title-step:first-child::before {
  display: none;
}
.summary {
  padding: 2.8rem 3rem 2rem;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
}
.summary td:last-child,
.summary th:last-child {
  text-align: right;
  min-width: 12rem;
}
.summary th {
  text-align: left;
}
.summary .form-control {
  width: 100%;
  max-width: 100%;
}
.summary-title {
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: #222;
  text-transform: uppercase;
  padding-bottom: 2rem;
  margin-bottom: 0;
  border-bottom: 1px solid #e1e1e1;
}
.summary-subtotal {
  border-bottom: 1px solid #e1e1e1;
}
.summary-subtitle {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: #222;
  margin-bottom: 0;
  padding: 2rem 0;
}
.summary-subtotal-price {
  font-size: 1.4rem;
  font-weight: 400;
  color: #666;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
}
.summary-total-price {
  font-size: 2rem;
  letter-spacing: -0.025em;
  font-weight: 700;
  color: #222;
  text-align: right;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
}
.coupon {
  display: flex;
}
.coupon > * {
  margin-right: 1rem;
}
@media (max-width: 767px) {
  .summary {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .cart .shop-table td {
    padding: 0;
  }
  .cart .shop-table td.product-price {
    display: block;
    width: 100%;
    text-align: center;
  }
  .cart .shop-table tr {
    padding: 4rem 0 3.5rem;
  }
  .cart .shop-table .product-subtotal,
  .cart .shop-table .product-thumbnail,
  .cart .shop-table .product-quantity {
    margin-bottom: 0.5rem;
  }
  .cart-actions {
    display: block;
  }
  .cart-actions .coupon {
    padding-bottom: 2rem;
  }
}
@media (max-width: 479px) {
  .summary {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.shipping-address {
  border-bottom: 1px solid #e1e1e1;
}
.shipping-address input.form-control,
.shipping-address .select-box {
  margin-bottom: 2rem;
  background: #fff;
}
.shipping-address .form-control {
  padding: 0.95rem 1.4rem;
  font-size: 1.3rem;
  border-color: #e3e3e3;
  color: #999;
}
.cart-total {
  text-align: right;
}
.cart-total .btn-calc {
  margin-bottom: 1.8rem;
}
.cart-total td,
.cart-total th {
  border-top: 1px solid #e1e1e1;
}
.cart-subtotal th {
  font-weight: 400;
}
.cart-subtotal td,
.cart-subtotal th {
  padding: 1.5rem 0;
}
.order-total {
  font-size: 1.6rem;
  font-weight: 600;
  color: #00a567;
}
.order-total td,
.order-total th {
  padding-top: 1.9rem;
}
.cart-table .product-price {
  width: 100px;
}
.cart-table .product-quantity {
  width: 135px;
}
.cart-table .product-subtotal {
  width: 85px;
}
.cart-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 2px;
  flex-direction: row-reverse;
}
.cart-actions button i {
  font-size: 1.4rem;
}
@media (min-width: 992px) {
  .cart.main .product-thumbnail {
    width: 12rem;
  }
  .cart.main .product-price {
    width: 9rem;
  }
  .cart.main .product-quantity {
    width: 15.3rem;
  }
  .cart.main .product-subtotal {
    width: 13.2rem;
  }
}
.checkout {
  line-height: 1.54;
}
.checkout.main {
  border-top: 1px solid #e1e1e1;
}
.checkout label {
  display: block;
  padding-left: 0.2rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  line-height: 1;
}
.checkout .form-control {
  font-size: 1.3rem;
  font-family: Poppins, sans-serif;
  transition: background-color 0.3s, border-color 0.3s;
  color: #999;
}
.checkout .form-control:not(:focus) {
  background-color: #fff;
}
.checkout .form-control:focus {
  border-color: #00a567;
}
.checkout textarea.form-control {
  padding-top: 1.5rem;
}
.checkout .form-control-label {
  padding-left: 2.4rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  line-height: 2.58;
}
.checkout .form-control-label::before {
  border-radius: 0;
}
.checkout .product-name {
  font-size: 1.4rem;
  letter-spacing: 0;
  white-space: normal;
}
.checkout .product-total {
  font-size: 1.4rem;
  color: #999;
}
.checkout .summary {
  padding-top: 1.8rem;
}
.checkout .btn-order {
  width: 100%;
}
.checkout .card {
  background: transparent;
}
.checkout .card-header {
  font-size: 1.4rem;
  line-height: 3rem;
  text-transform: capitalize;
  font-weight: 300;
}
.checkout .card-header a {
  padding: 0 0 0 2.5rem;
}
.checkout .card-header a::before,
.checkout .card-header a::after {
  position: absolute;
  content: "";
  display: inline-block;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.checkout .card-header a::before {
  left: 0;
  width: 1.8rem;
  height: 1.8rem;
  background: #fff;
  border: 1px solid #cdcdcd;
}
.checkout .card-header a.collapse::after {
  width: 0.8rem;
  height: 0.8rem;
  left: 0.5rem;
  background: #666;
}
.checkout .card-body {
  padding: 0.6rem 0 1.8rem 2.5rem;
  font-size: 1.3rem;
  line-height: 2;
}
.checkout .payment.accordion {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 2rem;
}
.checkout .checkout-info {
  padding: 1.5rem 0;
  margin: 0;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.01em;
  font-family: Poppins, sans-serif;
}
.checkout .product-quantity {
  font-weight: 500;
}
.checkout .title.title-simple {
  font-weight: 700;
}
.order-table {
  margin-bottom: 2rem;
  color: #222;
}
.order-table thead {
  border-bottom: 1px solid #e1e1e1;
  line-height: 2.5;
}
.order-table tbody {
  vertical-align: top;
  padding-top: 2rem;
}
.order-table th {
  padding: 0 0 0.7rem 1px;
  font-size: 1.6rem;
  font-weight: 600;
}
.order-table td {
  line-height: 3.5;
}
.order-table .order-total td {
  border-bottom: none;
  padding: 1.2rem 0 0;
}
.order-table .shipping-row label {
  margin-bottom: 1.5rem;
}
.order-table .shipping-row-last {
  border-bottom: 1px solid #e1e1e1;
}
.order-table .shipping-row-last label {
  margin-bottom: 2rem;
}
.order-table .summary-subtotal-price {
  padding-top: 1rem;
}
.order-table .summary-total-price {
  padding-top: 1.5rem;
}
.sumnary-shipping ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
.sumnary-shipping .summary-subtitle {
  text-align: left;
}
@media (max-width: 479px) {
  .order-table tbody .product-name,
  .order-table tbody .product-total {
    line-height: 2;
  }
}
.order.main {
  border-top: 1px solid #e1e1e1;
}
.order .title {
  font-size: 2rem;
  font-weight: 700;
}
.order .order-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  font-weight: 700;
  color: #222;
  padding: 3rem;
  border: 2px solid #e1e1e1;
  border-radius: 3px;
}
.order .order-message i {
  font-size: 2.8rem;
  color: #a8c26e;
  margin-top: -0.1rem;
  margin-right: 1.5rem;
}
.order .order-results {
  display: flex;
}
.order .overview-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.8rem;
  flex: 1;
}
.order .overview-item strong {
  color: #222;
}
.order .address-info {
  border-bottom: 1px solid #e1e1e1;
}
.order .address-info p {
  color: #666;
  font-family: Poppins, sans-serif;
  line-height: 2.15;
}
.order .btn.btn-back {
  font-size: 1.3rem;
}
.order .summary-subtotal:last-child {
  border-bottom: none;
}
.order-details {
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  padding: 0 3rem;
}
.order-details-table .product-name {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 3.5;
}
.order-details-table .product-name i {
  font-size: 1rem;
}
.order-details-table .product-name span {
  font-weight: 700;
}
.order-details-table th > .summary-subtitle {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: left;
}
.order-details-table .product-price {
  display: block;
}
.order-details-table .summary-subtotal-price,
.order-details-table .product-price {
  text-align: right;
}
.order-details-table .summary-subtotal-price {
  font-weight: 600;
}
.order-details-table .product-price {
  font-size: 1.4rem;
  color: #666;
  font-weight: 400;
  padding-top: 1.5rem;
}
.order-details-table thead {
  border-bottom: 1px solid #e1e1e1;
}
.order-details-table tbody tr:first-child td {
  padding-top: 2rem;
}
.order-details-table tbody tr:first-child td.product-price {
  padding-top: 3.3rem;
}
@media (max-width: 991px) {
  .order .order-results {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .order .overview-item {
    min-width: 33.3%;
    margin-bottom: 3rem;
  }
}
@media (max-width: 767px) {
  .order .order-message {
    font-size: 2rem;
  }
  .order .order-results {
    display: flex;
    flex-direction: column;
  }
  .order .overview-item {
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
  }
  .order .overview-item span {
    width: 50%;
    text-align: left;
  }
}
@media (max-width: 575px) {
  .order .order-details {
    padding: 0 2rem;
  }
  .order .order-message {
    font-size: 1.8rem;
  }
  .order .overview-item {
    font-size: 1.6rem;
  }
}
@media (max-width: 479px) {
  .order .order-details {
    padding: 0 1.5rem;
  }
  .order .order-message {
    font-size: 1.6rem;
  }
  .order .overview-item {
    font-size: 1.4rem;
  }
  .step-by .title.title-step {
    font-size: 1.6rem;
  }
  .step-by .title.title-step::before {
    font-size: 1.4rem;
    top: 50%;
  }
  .order-details-table tbody .product-name {
    white-space: pre-wrap;
    line-height: 2;
  }
}
.sample-icons .tab-pane {
  overflow: hidden;
}
.sample-icon-circle {
  margin-bottom: 2rem;
}
.sample-icon-circle i {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  font-size: 30px;
  background: #00a567;
  color: #fff;
}
.sample-icon {
  display: flex;
  align-items: center;
  line-height: 41px;
}
.sample-icon span {
  font-size: 12px;
  white-space: nowrap;
}
.sample-icon i {
  display: inline-block;
  margin-right: 10px;
  width: 32px;
  font-size: 20px;
  text-align: center;
}
.sample-icon:hover {
  color: #00a567;
  background: #f2f3f5;
  border-radius: 4px;
}
.sample-icon:hover i {
  font-size: 30px;
}
.sample-icon-list i {
  font-size: 22px;
}
@media (min-width: 1200px) {
  .cols-xl-12 > * {
    flex: 0 0 8.3333%;
    max-width: 8.3333%;
  }
}
/*# sourceMappingURL=style.min.css.map */
