.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav [class*="owl-"],
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.no-js .owl-carousel {
  display: block;
}
.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  transition: height 0.5s ease-in-out;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}
.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.owl-theme .owl-nav {
  margin-top: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  top: 50%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0;
  margin-top: -15px;
  color: #2b579a;
  font-size: 16px;
  line-height: 1;
  padding: 0;
  border: 0.1rem solid #ebebeb;
  background: 0 0;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s ease;
}
.owl-theme .owl-nav [class*="owl-"]:not(.disabled):hover {
  color: #fff;
  border-color: #2b579a;
  background: #2b579a;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.8;
  cursor: default;
}
.owl-theme .owl-nav .owl-prev {
  left: -50px;
}
.owl-theme .owl-nav .owl-next {
  right: -50px;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  line-height: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 5px 6px;
  border: 0.1rem solid #d7d7d7;
  background: 0 0;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all 0.3s ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot:hover span {
  border-color: #ccc;
  background: #ccc;
}
.owl-theme .owl-dots .owl-dot.active span {
  width: 18px;
  border-color: #ccc;
  background: #ccc;
}
.owl-theme.owl-light .owl-nav [class*="owl-"] {
  border-color: #b7b7b7;
}
.owl-theme.owl-light .owl-nav [class*="owl-"]:not(.disabled):hover {
  border-color: #2b579a;
}
.owl-theme.owl-light .owl-dots .owl-dot:hover span {
  border-color: #2b579a;
  background: #2b579a;
}
.owl-theme.owl-light .owl-dots .owl-dot.active span {
  border-color: #2b579a;
  background: #2b579a;
}
.owl-theme.owl-nav-inside .owl-nav .owl-prev {
  left: 30px;
}
.owl-theme.owl-nav-inside .owl-nav .owl-next {
  right: 30px;
}
.owl-theme.owl-nav-inside .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
}
.owl-simple .owl-nav {
  margin-top: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-simple .owl-nav [class*="owl-"] {
  position: absolute;
  top: 50%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  margin: 0;
  margin-top: -21px;
  color: #ccc;
  font-size: 30px;
  line-height: 1;
  padding: 0;
  border: none;
  background: 0 0;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s ease;
}
.owl-simple .owl-nav [class*="owl-"] i {
  margin-top: 0.1rem;
}
.owl-simple .owl-nav [class*="owl-"]:not(.disabled):hover {
  color: #2b579a;
  background: 0 0;
  text-decoration: none;
}
.owl-simple .owl-nav .disabled {
  opacity: 0.7;
  cursor: default;
}
.owl-simple .owl-nav .owl-prev {
  left: -50px;
}
.owl-simple .owl-nav .owl-next {
  right: -50px;
}
.owl-simple .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-simple .owl-dots {
  line-height: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-simple .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-simple .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 5px 6px;
  border: 0.1rem solid #d7d7d7;
  background: 0 0;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all 0.3s ease;
  border-radius: 30px;
}
.owl-simple .owl-dots .owl-dot:hover span {
  border-color: #ccc;
  background: #ccc;
}
.owl-simple .owl-dots .owl-dot.active span {
  width: 18px;
  border-color: #ccc;
  background: #ccc;
}
.owl-simple.owl-light .owl-nav [class*="owl-"] {
  color: #fff;
}
.owl-simple.owl-light .owl-dots .owl-dot:hover span {
  border-color: #2b579a;
  background: #2b579a;
}
.owl-simple.owl-light .owl-dots .owl-dot.active span {
  border-color: #2b579a;
  background: #2b579a;
}
.owl-simple.owl-light.owl-nav-inside .owl-dots .owl-dot.active span {
  border-color: #f8f8f8;
  background: #f8f8f8;
}
.owl-simple.owl-dark .owl-nav [class*="owl-"] {
  color: #666;
}
.owl-simple.owl-dark .owl-dots .owl-dot span {
  border-color: #666;
}
.owl-simple.owl-dark .owl-dots .owl-dot.active span,
.owl-simple.owl-dark .owl-dots .owl-dot:hover span {
  border-color: #666;
  background: #666;
}
.owl-simple.owl-nav-inside .owl-nav .owl-prev {
  left: 18px;
}
.owl-simple.owl-nav-inside .owl-nav .owl-next {
  right: 18px;
}
.owl-simple.owl-nav-inside .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
}
.owl-full .owl-nav {
  margin-top: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-full .owl-nav [class*="owl-"] {
  position: absolute;
  top: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
  margin: 0;
  color: #2b579a;
  font-size: 20px;
  line-height: 1;
  padding: 0;
  text-align: center;
  border: none;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  border-radius: 0;
  transition: all 0.3s ease;
}
.owl-full .owl-nav [class*="owl-"] i {
  margin-top: 0.1rem;
}
.owl-full .owl-nav [class*="owl-"]:focus,
.owl-full .owl-nav [class*="owl-"]:hover {
  color: #2b579a;
  background: #fff;
  text-decoration: none;
  outline: 0 !important;
}
.owl-full .owl-nav [class*="owl-"]:before {
  content: "";
  display: block;
  width: 10px;
  height: 100%;
  position: absolute;
  left: 100%;
  top: 0;
  border-radius: 20px 0 0 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.owl-full .owl-nav .disabled {
  opacity: 0;
  pointer-events: none;
  cursor: default;
}
.owl-full .owl-nav .owl-prev {
  left: 0;
}
.owl-full .owl-nav .owl-next {
  right: 0;
}
.owl-full .owl-nav .owl-next:before {
  left: auto;
  right: 100%;
}
.owl-full.carousel-with-shadow .owl-stage-outer {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
.owl-full.carousel-with-shadow .owl-nav [class*="owl-"] {
  height: calc(100% - 20px);
}
.owl-full .owl-nav.disabled + .owl-dots {
  margin-top: 0;
}
.owl-full .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4rem;
  line-height: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-full .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-full .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 5px 6px;
  border: 0.1rem solid #2b579a;
  background: 0 0;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all 0.3s ease;
  border-radius: 30px;
}
.owl-full .owl-dots .owl-dot:hover span {
  border-color: #2b579a;
  background: #2b579a;
}
.owl-full .owl-dots .owl-dot.active span {
  width: 18px;
  border-color: #2b579a;
  background: #2b579a;
}
.owl-full.owl-nav-dark .owl-nav [class*="owl-"] {
  color: #333;
}
.owl-full.owl-nav-dark .owl-nav [class*="owl-"]:focus,
.owl-full.owl-nav-dark .owl-nav [class*="owl-"]:hover {
  color: #2b579a;
  background: #fff;
}
.bg-light .owl-full .owl-nav [class*="owl-"]:focus,
.bg-light .owl-full .owl-nav [class*="owl-"]:hover {
  background: #f8f8f8;
}
