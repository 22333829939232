body {
  margin: 0;
  color: #6a6f8c;
  font: 600 16px/18px "Open Sans", sans-serif;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*,
:after,
:before {
  box-sizing: border-box;
}
.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
  display: block;
}
a {
  color: inherit;
  text-decoration: none;
}

.carton {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
}
.hidden {
  visibility: hidden;
}
input[type="radio"] ~ label::before {
  opacity: 0 !important;
  content: none !important;
}
input[type="radio"] ~ label::after {
  opacity: 0 !important;
  content: none !important;
}
.space {
  margin-top: 40px;
}
.error {
  color: #ec0d0d;
  display: flex;
  justify-content: center;
  align-content: center;
}
.error-text {
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.loader-case {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 10000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-carton {
  width: 100px;
  height: 100px;
}
.loader {
  height: 100%;
  width: 100%;
}
.name-case {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.group-margin {
  margin-left: 10px;
}

.login-wrap {
  width: 100%;
  margin: auto;
  max-width: 525px;
  min-height: 610px;
  position: relative;
  margin-top: 30px;
  border-radius: 30px;
  background: url(https://images.unsplash.com/photo-1595948215980-31b7019131b9?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NTF8fHNob3AlMjBiYWd8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&w=1000&q=80)
    no-repeat center;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.login-html {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 90px 70px 50px 70px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 25px;
}
.login-html .sign-in-htm,
.login-html .sign-up-htm,
.login-html .forgot-htm {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  transition: all 0.4s linear;
}
.login-html .sign-in,
.login-html .sign-up,
.login-html .forgot,
.login-form .group .check {
  display: none;
}
.login-html .tab,
.login-form .group .label,
.login-form .group .button {
  text-transform: uppercase;
}
.login-html .tab {
  font-size: 20px;
  margin-right: 15px;
  padding-bottom: 5px;
  margin: 0 15px 10px 0;
  display: inline-block;
  border-bottom: 2px solid transparent;
}
.login-html .sign-in:checked + .tab,
.login-html .sign-up:checked + .tab,
.login-html .forgot:checked + .tab {
  color: #fff;
  border-color: #00a567;
}
.login-form {
  min-height: 345px;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
}
.login-form .group {
  margin-bottom: 15px;
  margin-top: 15px;
}
.login-form .group .label,
.login-form .group .input,
.login-form .group .button {
  width: 100%;
  color: #fff;
  display: block;
}
.login-form .group .input,
.login-form .group .button {
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  background: #3c393b9c;
}
.login-form .group input[data-type="password"] {
  /* text-security: circle; */
  -webkit-text-security: circle;
}
.login-form .group .label {
  color: #aaa;
  font-size: 12px;
}
.login-form .group .button {
  background: #00a567;
}

.button-dark {
  background: #686868 !important;
  display: flex !important;
  justify-content: center;
}

.login-form .group label .icon {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  position: relative;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
}
.login-form .group label .icon:before,
.login-form .group label .icon:after {
  content: "";
  width: 10px;
  height: 2px;
  background: #fff;
  position: absolute;
  transition: all 0.2s ease-in-out 0s;
}
.login-form .group label .icon:before {
  left: 3px;
  width: 5px;
  bottom: 6px;
  transform: scale(0) rotate(0);
}
.login-form .group label .icon:after {
  top: 6px;
  right: 0;
  transform: scale(0) rotate(0);
}
.login-form .group .check:checked + label {
  color: #fff;
}
.login-form .group .check:checked + label .icon {
  background: #00a567;
}
.login-form .group .check:checked + label .icon:before {
  transform: scale(1) rotate(45deg);
}
.login-form .group .check:checked + label .icon:after {
  transform: scale(1) rotate(-45deg);
}
.login-html
  .sign-in:checked
  + .tab
  + .sign-up
  + .tab
  + .forgot
  + .tab
  + .login-form
  .sign-in-htm {
  transform: rotate(0);
}

.login-html
  .sign-in
  + .tab
  + .sign-up:checked
  + .tab
  + .forgot
  + .tab
  + .login-form
  .sign-up-htm {
  transform: rotate(0);
}

/* .login-html .sign-up:checked + .tab + .login-form .sign-up-htm {
  transform: rotate(0);
} */

.login-html .forgot:checked + .tab + .login-form .forgot-htm {
  transform: rotate(0);
}

.hr {
  height: 2px;
  margin: 60px 0 50px 0;
  background: rgba(255, 255, 255, 0.2);
}
.foot-lnk {
  text-align: center;
}
