div::-webkit-scrollbar{
  display:none;
}
.owl-carousel::-webkit-scrollbar{
  display:none;
  background-color: black;
}
section{
  padding-bottom: 2em;
}
html {
  scroll-behavior: smooth;
}
.mt-10{
  background-color: #f6f7f9;
}
button{
  cursor:pointer;
  outline:none;
}
.nav-btns button{
  position:absolute;
  padding: 0px 5px;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  bottom:45%;
  z-index: 9999999;
  font-size: 30px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #302d2d96;
}
.nav-btns .prev{
  left: 2%;
  transform: translate(-50,0%);  
  transform: rotate(180deg);
}
.nav-btns .next{
  right:2%;
  transform: translate(-50,0%);  
}
.layby{
  width: 50%;
  height: 50vh;
  margin: auto;
  border: 1px solid #D8D8D8;
  padding: 2em;
}
.layby input{
  width: 90%
}
@media (max-width: 800px) {
  .nav-btns button{
    display: none;
  }
  .layby{
    width: 85%;
    height: 60vh;
  }
  .layby b {
    display: none;
  }
  .layby input{
    width: 100%
  }
}